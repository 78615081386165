Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.formDataApiContentType = "multipart/form-data";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.getInterestsApiEndPoint = 'bx_block_interests/interests';
exports.postApiEndPoint = "account_groups/groups"
exports.getApiEndPoint = "account_groups/groups"
exports.searchGroupEndPoint = "groups/search_grp"
exports.hashtagsApiEndPoint = "hashtags";
exports.likesApiEndPoint = "like/likes";
exports.commentApiEndPoint = "comments";
exports.topTrendingApiEndPoint = "group_posts/2/group_trending_post";
exports.getGroupMembersApiEndPoint = "account_groups/2/group_members"
exports.searchApiEndPoint = "groups/2/search_grp_member"
exports.removeMemberApiEndPoint = "account_groups/2/remove_member/"
exports.joinGroupApiEndPoint = "account_groups/join_public_group/7"
exports.joinPrivateGroupApiEndPoint = "account_groups/send_request/9"
exports.recentGroupsApiEndPoint = "account_groups/recent_groups"
exports.reportApiEndPoint = "account_groups/43/report_on_group_member"
exports.reportPostApiEndPoint = "posts/report_on_post";
exports.userInterestAPiEndPoint = "bx_block_interests/user_interests";
exports.groupPostApiEndPoint = "group_posts";
// Customizable Area End
