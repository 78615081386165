import React from "react";
import {
    Grid,
    Chip,
    Button,
    Paper,
    Typography
} from "@material-ui/core";
import { logoImg } from "../assets";
import { Add, Done } from '@material-ui/icons';
import HandleAddMoreView from "./AddmoreFields";
import {t} from "i18next";

const InterestForm = ({ state, handleOtherFields, handleContinue,handleAddFormFields, handleShowFields,handleSelectInterest,handleRemoveAddMoreFields, handleRegLogoRedirection }: any) => {


    return (
        <>
        <form onSubmit={handleContinue} className="interest-form">
            <Grid container spacing={3} className="interest-box-in">
                <Grid item xs={12}>
                    <div  onClick={handleRegLogoRedirection} className="logo-item-signup">
                        <img src={logoImg} alt="" className="logo-image-signup" />
                        <Typography className="logo-content-01">
                            {t('Connecting Collaborative Changemakers')}
                        </Typography>
                    </div>
                    <div onClick={handleRegLogoRedirection} className="logo-item-signup-responsive">
                        <img src={logoImg} alt="" className="logo-image-signup" />
                        <Typography className="logo-content-01">
                            {t('Connecting Collaborative Changemakers')}
                        </Typography>
                    </div>
                    <div className="interest-heading-box">
                        <Typography className="interest-title">{t('Select your interest')}</Typography>
                        <Typography className="interest-sub-title">{t('You are joining a network of global collaborative changemakers supporting each other to strengthen collaborative practice to achieve more transformational results, please select the areas you are most interested in.')}</Typography>
                    </div>
                </Grid>
                {
                    Object.keys(state.interestList).map((type: string, i: number) =>  (<Grid key={type} item xs={12} lg={6} sm={12} md={6} >
                            <Paper className="interest-block">
                                <Typography className="interest-category">{type == "impact_area" ? t('Impact Area') : t('Skills and Approaches')}</Typography>
                                {
                                    state.interestList[type].map((data: any, indx: number) => (
                                        <Chip
                                            key={indx}
                                            className={state.interestIds.includes(data.id)? "chip-label-selected": "chip-label"}
                                            icon={state.interestIds.includes(data.id) ? <Done className="icon-style-selected"/>:<Add className="icon-style" />}
                                            label={data?.attributes?.name.charAt(0).toUpperCase() + data?.attributes?.name.slice(1)}
                                            onClick={() => handleSelectInterest(data.id)}
                                        />
                                    ))
                                }
                                {
                                    ((type === "impact_area" && state.showOtherImpactFields === true) || (type === "skills_and_approaches" && state.showOtherSkillsFields === true)) ?
                                    <Chip
                                    className={"chip-label-selected"}
                                    icon={<Done className="icon-style-selected"/> }
                                    onClick={() => handleShowFields(type)}
                                    label={t('Other')}
                                    data-test-id={"chipClick1_"+i.toString()}
                                    />:
                                    <Chip
                                    className={"chip-label"}
                                    icon={<Add className="icon-style"/>}
                                    onClick={() => handleShowFields(type)}
                                    label={t('Other')}
                                    data-test-id={"chipClick2_"+i.toString()}
                                />
                                }
                                
                                {
                                    type == "impact_area" && state.showOtherImpactFields === true &&
                                    (
                                        <>
                                        <HandleAddMoreView handleRemoveAddMoreFields={handleRemoveAddMoreFields} list={state.otherInterestImpactFields} handleOtherFields={handleOtherFields} handleAddFormFields={handleAddFormFields} category={type} />
                                        {state.impactInterestUniqueError && <p className="error-msg">{state.impactInterestUniqueError}</p>}
                                        </>
                                    )
                                }
                                {
                                    type == "skills_and_approaches" && state.showOtherSkillsFields === true &&
                                    (
                                        <>
                                        <HandleAddMoreView handleRemoveAddMoreFields={handleRemoveAddMoreFields} list={state.otherInterestSkillsFields} handleOtherFields={handleOtherFields} handleAddFormFields={handleAddFormFields} category={type} />
                                        {state.skillsInterestUniqueError && <p className="error-msg">{state.skillsInterestUniqueError}</p>}
                                        </>
                                    )
                                }
                            </Paper>
                        </Grid>)
                    )
                }
                <Grid item xs={12} className="btn-div">
                    {
                        (state.interestIds.length !== 0 || (state.otherInterestImpactFields.length !== 0 && state.otherInterestImpactFields[0]?.name !== "") || ( state.otherInterestSkillsFields.length !== 0  && state.otherInterestSkillsFields[0]?.name !== "")) ?
                        <Button type="submit" className="continue-btn" variant="contained" color="primary">{t('Create Account')}</Button>:
                        <Button type="button" className="cont-disable-btn" variant="contained" color="primary">{t('Create Account')}</Button>
                    }
                </Grid>
            </Grid>
        </form>
        </>
    );
};

export default InterestForm;
