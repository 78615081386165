import React from "react";
import {
  Typography,
  Avatar,
  Divider,
  Box
} from "@material-ui/core";
import {t} from "i18next";

export const GroupMembersData = ({ groupMembersData, userId, isKabab, membersIndex, handleMoreMember, handleRemoveMemberApi, handleMemberReport, handleMakeMember }: any) => {
  let checkAdmin = groupMembersData.filter((i: any) => i.attributes.is_admin && i.attributes.account_id == userId)
  return (
    <>
      {groupMembersData && groupMembersData.length > 0 ? (
        groupMembersData.map((item: any, index: number) => {
          let isAdmin = item.attributes.account_id && item.attributes.account_id.toString() !== userId.toString();
          return (
            <>
              <div key={item} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", position: "relative" }} >
                <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                  <Avatar
                    alt="Jack Sparrow"
                    src={item.attributes.photo}
                    style={{ width: "56px", height: "56px" }}
                  />
                  <div>
                    <span style={{ display: "flex", gap: "8px" }}>
                      <Typography style={styles.followlistitem}>
                        {item.attributes.full_name}
                      </Typography>
                      <Typography style={styles.followlistitem1}>
                        {`${item.attributes.is_admin ? "(ADMIN)" : ""}`}
                      </Typography>
                    </span>
                    <Typography style={styles.textSecond}>
                      {item.attributes.profession}
                    </Typography>
                  </div>
                </div>
                <div>
                  {isAdmin && (
                    <img test-id="isMoreOpen" src={require('../../assets/ic_baseline-more-horiz.png')} style={{ cursor: 'pointer' }}
                      onClick={() => handleMoreMember(index)}
                    />
                  )}
                  {
                    index === membersIndex && isAdmin && isKabab &&
                    <Box test-id="reportbox01" className='report-modal modal-al-cls'>
                      {checkAdmin.length > 0 &&
                        <>
                          <Typography className='report-text' onClick={() => handleMakeMember(item.attributes.account_id)}>{t('Make admin')}</Typography>
                          <Divider style={{ width: "100%" }} />
                          <Typography className='report-text' onClick={() => handleRemoveMemberApi(item.id)}>{t('Remove')}</Typography>
                          <Divider style={{ width: "100%" }} />
                        </>
                      }
                      {item.attributes.is_reported ?
                        <Typography test-id="report-id" className='report-text'>{t('Reported')}</Typography>
                        :
                        <Typography test-id="report-id" className='report-text' onClick={() => handleMemberReport(item.attributes.account_id)}>{t('Report')}</Typography>
                      }
                    </Box>
                  }
                </div>
              </div>
              <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
            </>
          )
        })
      ) : (
        <Typography style={{ textAlign: 'center' }}>
          {t('No members found')}
        </Typography>
      )}
    </>
  )
}

const styles = {
  followlistitem: {
    fontWeight: 700,
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "24px",
    fontFamily: "Comfortaa-Bold",
    color: "var(--neutrals-cool-gray-600, #475569)",
  },
  followlistitem1: {
    color: "var(--neutrals-cool-gray-600, #475569)",
    fontFamily: "Comfortaa-Regular",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "24px",
    fontStyle: "normal",
  },
  textSecond: {
    fontSize: "14px",
    color: "var(--neutrals-cool-gray-600, #475569)",
    fontFamily: "Nunito-Regular",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "22px",
  }
}