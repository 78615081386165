import React from "react";
import {
    Box,
    Typography,
    Avatar,
  } from "@material-ui/core";
import { t } from "i18next";

  export const ResponsiveRecentGroups = ({recentGroups, handleOpen, handleJoinGroup, getGroupDetails}:any) => {
    const groupStatus = (group:any) => {
      if(group.status !== "accepted" && group.request_type !== "requested" ){
        return t('Join Group')
      } else if( group.status === "pending" && group.request_type === "requested"){
        return t("Requested")
      } else{
        return t("Joined")
      }
    }
      return(
        <Box className="responsiveGroup">
        <div className="titleContainer">
          <Typography className="titleT">{t("Recent groups")}</Typography>
          <div data-test-id="createNewGroup" className="arrowRedirect" onClick={() => handleOpen("CreateNewGroup")} style={{cursor:'pointer'}}>
          <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="16"
                      height="16"
                      viewBox="0 0 16 16"
                     fill="none"
                     >
                         <path
                           d="M12.6668 8.66543H8.66683V12.6654H7.3335V8.66543H3.3335V7.33209H7.3335V3.33209H8.66683V7.33209H12.6668V8.66543Z"
                          fill="#475569" />
                    </svg>
            <Typography className="viewText">
              {t("Create new group")}
            </Typography>
          </div>
        </div>
        <div className="groupCard">
          {recentGroups && recentGroups.slice(0,2).map((group: any, index:number) => (
              <div className="cards" key={group} data-test-id={"recentGroup"+index}>
                <div className="infoContainer">
                  <Avatar
                    alt="Jack Sparrow"
                    src={group.attributes.cover_image} />
                  <div className="userData">
                    <Typography data-test-id="grp-detail" className="titleT" onClick={() => getGroupDetails(group, "recentGroups")} style={{cursor:"pointer"}}>
                      {group.attributes.name}
                    </Typography>
                  </div>
                </div>    
                <div data-test-id={"joinBtn"+index} className="joinButton"   onClick={() => group.status !== "accepted" && group.status !== "pending" &&
                          handleJoinGroup(group, "joinFromRecentGroups")} style={{cursor:"pointer"}}>
                          {group.status !== "accepted" && group.request_type !== "requested" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                            >
                              <path
                                d="M13.1668 8.66536H9.16683V12.6654H7.8335V8.66536H3.8335V7.33203H7.8335V3.33203H9.16683V7.33203H13.1668V8.66536Z"
                                fill="#475569"
                              />
                            </svg>
                                  )}
                            <Typography className="titleTSmall">
                              {groupStatus(group)}
                            </Typography>
                          </div>                
              </div>
          ))}
        </div>
          <div data-test-id="allGrp" className="arrowRedirect" onClick={() => handleOpen("ViewAllGroups")} style={{cursor:"pointer"}}>
            <Typography className="viewText">
              {t("View all groups")}
            </Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8.00033 2.66669L7.06033 3.60669L10.7803 7.33335H2.66699V8.66669H10.7803L7.06033 12.3934L8.00033 13.3334L13.3337 8.00002L8.00033 2.66669Z"
                fill="#64748B"
              />
            </svg>
          </div>
      </Box>
    )
}