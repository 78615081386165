import React from "react";
import {
    Box,
    Grid,
    Typography
} from "@material-ui/core";

const EditPost = ({ postMedia, existedMedia, onFilteredHandler, onExistedMediaHandler }: any) => {
    const mediaWithGrid = (itemData: any, idx: number) => {
        return (
            <>
                {
                    itemData.type.includes("image") &&
                    <Grid test-id="four-grid-img" item xs={6} key={idx} >
                        <Box className='postImageBox close_btn_wrp'>
                            <svg test-id="close-wrp-id" xmlns="http://www.w3.org/2000/svg" width="22" height="22px" viewBox="0 0 24 24" fill="none" onClick={() => onExistedMediaHandler(itemData.id, idx)}>
                                <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z" fill="#292D32" />
                            </svg>
                            <img className='postImage rad-cls' src={itemData.url} alt={`Image ${idx}`} />
                        </Box>
                    </Grid>
                }
                {
                    itemData.type.includes("video") &&
                    <Grid test-id="four-grid-vid" item xs={6} key={idx} >
                        <Box className='postImageBox close_btn_wrp'>
                            <svg test-id="close-wrp-id2" xmlns="http://www.w3.org/2000/svg" width="22" height="22px" viewBox="0 0 24 24" fill="none" onClick={() => onExistedMediaHandler(itemData.id, idx)}>
                                <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z" fill="#292D32" />
                            </svg>
                            <video controls className='postImage videoBox rad-cls'>
                                <source src={itemData} type='video/mp4' />
                            </video>
                        </Box>
                    </Grid>
                }
                {
                    itemData.type.includes("audio") &&
                    <Grid test-id="four-grid-aud" item xs={6} key={idx} >
                        <Box className='postImageBox close_btn_wrp'>
                            <svg test-id="close-wrp-id3" xmlns="http://www.w3.org/2000/svg" width="22" height="22px" viewBox="0 0 24 24" fill="none" onClick={() => onExistedMediaHandler(itemData.id, idx)}>
                                <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z" fill="#292D32" />
                            </svg>
                            <audio controls style={{ width: "100%" }}>
                                <source src={itemData} type="audio/mpeg" />
                            </audio>
                        </Box>
                    </Grid>
                }
                {
                    itemData.type.includes("application") &&
                    <Grid test-id="four-grid-doc" item xs={6} key={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box className='postImageBox' style={{ position: "relative", height: "max-content", cursor: "pointer", borderRadius: "8px" }}>
                            <svg test-id="close-wrp-id4" className="close_btn_wrp_doc_only" xmlns="http://www.w3.org/2000/svg" width="22" height="22px" viewBox="0 0 24 24" fill="none" onClick={() => onExistedMediaHandler(itemData.id, idx)}>
                                <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z" fill="#292D32" />
                            </svg>
                            <div className='doc-box'>
                                <Box className='PostImageLeft'>
                                    <svg
                                        height="39"
                                        width="30"
                                        viewBox="0 0 90.48 122.88"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M35.27.78a3,3,0,0,1,2-.78,1.54,1.54,0,0,1,.47.05h46.2A6.59,6.59,0,0,1,88.56,2a6.52,6.52,0,0,1,1.92,4.64v109.7a6.57,6.57,0,0,1-6.56,6.56H6.67a6.57,6.57,0,0,1-6.56-6.56v-78A3.07,3.07,0,0,1,0,37.56a3.19,3.19,0,0,1,1-2.24L34.9,1a1.5,1.5,0,0,1,.26-.21ZM84.65,6.62a.5.5,0,0,0-.21-.47A.67.67,0,0,0,84,5.94H40.22V31.62a8.89,8.89,0,0,1-8.91,8.91H6.1v75.79a.58.58,0,0,0,.2.47.69.69,0,0,0,.47.21H84a.58.58,0,0,0,.47-.21.73.73,0,0,0,.21-.47V6.62Zm-62,94.73a2.64,2.64,0,0,1,0-5.28h28a2.64,2.64,0,0,1,0,5.28Zm0-17.75a2.64,2.64,0,0,1,0-5.28H65.46a2.64,2.64,0,0,1,0,5.28Zm0-17.76a2.64,2.64,0,0,1,0-5.28H72a2.64,2.64,0,0,1,0,5.28Zm11.7-34.22V10.11L10.11,34.64h21.2a3.16,3.16,0,0,0,2.13-.88,3.06,3.06,0,0,0,.89-2.14Z" />
                                    </svg>
                                </Box>
                                <Box className='PostImageRight'>
                                    <Box>
                                        <Typography className='documentName'>{ }</Typography>
                                    </Box>
                                    <Box className='DownloadBox'>
                                        <svg
                                            height="12"
                                            width="12"
                                            fill="#7b7b7b"
                                            viewBox="0 0 122.88 110.9"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13.09,35.65h30.58V23.2l34.49,0v12.45l31.47,0L61.39,82.58L13.09,35.65L13.09,35.65z M61.44,97.88l47.51-0.14 l4.54-21.51l9.38,0.31v34.36L0,110.9V76.55l9.39-0.31l4.54,21.51L61.44,97.88L61.44,97.88L61.44,97.88z M43.67,0h34.49v4.62H43.67 V0L43.67,0z M43.67,9.32h34.49v9.44H43.67V9.32L43.67,9.32z" />
                                        </svg>
                                    </Box>
                                </Box>
                            </div>
                        </Box>
                    </Grid>
                }
            </>
        )
    }
    const selectedMedia = (i: any, idx: number) => {
        return (
            <>
                {
                    i.includes("image") &&
                    <Grid test-id="four-grid-img" item xs={6} key={idx} >
                        <Box className='postImageBox close_btn_wrp'>
                            <svg test-id="close-wrp-id-1" xmlns="http://www.w3.org/2000/svg" width="22" height="22px" viewBox="0 0 24 24" fill="none" onClick={() => onFilteredHandler(idx)} >
                                <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z" fill="#292D32" />
                            </svg>
                            <img className='postImage rad-cls' src={i} alt={`Image ${idx}`} />
                        </Box>
                    </Grid>
                }
                {
                    i.includes("video") &&
                    <Grid test-id="four-grid-vid" item xs={6} key={idx} >
                        <Box className='postImageBox close_btn_wrp'>
                            <svg test-id="close-wrp-id-2" xmlns="http://www.w3.org/2000/svg" width="22" height="22px" viewBox="0 0 24 24" fill="none" onClick={() => onFilteredHandler(idx)}>
                                <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z" fill="#292D32" />
                            </svg>
                            <video controls className='postImage videoBox rad-cls'>
                                <source src={i} type='video/mp4' />
                            </video>
                        </Box>
                    </Grid>
                }
                {
                    i.includes("audio") &&
                    <Grid test-id="four-grid-aud" item xs={6} key={idx} >
                        <Box className='postImageBox close_btn_wrp'>
                            <svg test-id="close-wrp-id-3" xmlns="http://www.w3.org/2000/svg" width="22" height="22px" viewBox="0 0 24 24" fill="none" onClick={() => onFilteredHandler(idx)}>
                                <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z" fill="#292D32" />
                            </svg>
                            <audio controls style={{ width: "100%" }}>
                                <source src={i} type="audio/mpeg" />
                            </audio>
                        </Box>
                    </Grid>
                }
                {
                    i.includes("application") &&
                    <Grid test-id="four-grid-doc" item xs={6} key={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box className='postImageBox' style={{ position: "relative", height: "max-content", cursor: "pointer", borderRadius: "8px" }}>
                            <svg
                                test-id="close-wrp-id-4"
                                className="close_btn_wrp_doc_only"
                                xmlns="http://www.w3.org/2000/svg"
                                height="22px"
                                width="22"
                                fill="none"
                                viewBox="0 0 24 24"
                                onClick={() => onFilteredHandler(idx)}>
                                <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z" fill="#292D32" />
                            </svg>
                            <div className='doc-box'>
                                <Box className='PostImageLeft'>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="30"
                                        height="39"
                                        viewBox="0 0 90.48 122.88"
                                    >
                                        <path
                                            d="M35.27.78a3,3,0,0,1,2-.78,1.54,1.54,0,0,1,.47.05h46.2A6.59,6.59,0,0,1,88.56,2a6.52,6.52,0,0,1,1.92,4.64v109.7a6.57,6.57,0,0,1-6.56,6.56H6.67a6.57,6.57,0,0,1-6.56-6.56v-78A3.07,3.07,0,0,1,0,37.56a3.19,3.19,0,0,1,1-2.24L34.9,1a1.5,1.5,0,0,1,.26-.21ZM84.65,6.62a.5.5,0,0,0-.21-.47A.67.67,0,0,0,84,5.94H40.22V31.62a8.89,8.89,0,0,1-8.91,8.91H6.1v75.79a.58.58,0,0,0,.2.47.69.69,0,0,0,.47.21H84a.58.58,0,0,0,.47-.21.73.73,0,0,0,.21-.47V6.62Zm-62,94.73a2.64,2.64,0,0,1,0-5.28h28a2.64,2.64,0,0,1,0,5.28Zm0-17.75a2.64,2.64,0,0,1,0-5.28H65.46a2.64,2.64,0,0,1,0,5.28Zm0-17.76a2.64,2.64,0,0,1,0-5.28H72a2.64,2.64,0,0,1,0,5.28Zm11.7-34.22V10.11L10.11,34.64h21.2a3.16,3.16,0,0,0,2.13-.88,3.06,3.06,0,0,0,.89-2.14Z" />
                                    </svg>
                                </Box>
                                <Box className='PostImageRight'>
                                    <Box>
                                        <Typography className='documentName'>{ }</Typography>
                                    </Box>
                                    <Box className='DownloadBox'>
                                        <svg
                                            height="12"
                                            viewBox="0 0 122.88 110.9"
                                            width="12"
                                            fill="#7b7b7b"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13.09,35.65h30.58V23.2l34.49,0v12.45l31.47,0L61.39,82.58L13.09,35.65L13.09,35.65z M61.44,97.88l47.51-0.14 l4.54-21.51l9.38,0.31v34.36L0,110.9V76.55l9.39-0.31l4.54,21.51L61.44,97.88L61.44,97.88L61.44,97.88z M43.67,0h34.49v4.62H43.67 V0L43.67,0z M43.67,9.32h34.49v9.44H43.67V9.32L43.67,9.32z" />
                                        </svg>
                                    </Box>
                                </Box>
                            </div>
                        </Box>
                    </Grid>
                }
            </>
        )
    }
    return (
        <div className={(existedMedia.length || postMedia.length) && 'post_modal_scroll'}>
            {(existedMedia.length > 0 || postMedia.length > 0) &&
                <Grid container spacing={1} style={{ justifyContent: "center", padding: "10px" }}>
                    {existedMedia.map((i: any, idx: number) => mediaWithGrid(i, idx))}
                    {postMedia.map((i: any, idx: number) => selectedMedia(i, idx))}
                </Grid>
            }
        </div>
    );
};

export default EditPost;
