import React from "react";

//Customizable Area Start
import { Typography } from "@material-ui/core";
import ForgotPasswordController , {Props,
} from "./ForgotPasswordController";
import '../assets/index.scss';
import { forgotImg, lowerBoxImg, logoimg,upperBoxImg } from "./assets";
import {t} from "i18next";
import ForgotPasswordEmailForm from "./components/ForgotPasswordEmailForm.web";
import NewPasswordForm from "./components/NewPasswordForm.web"
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {

    return (
            // Customizable Area Start
          <>
            <div className="forgot-div">
                <div className="forgot-inner-div">
                <div className="leftImage">
                    <img src={upperBoxImg}/>
                </div>
                <div className="centerForm">
                    <div onClick={this.handleLogoRedirection} className="logo-item-forgot">
                        <img src={logoimg} alt="" className="logo-image-forgot" />
                        <Typography className="forgot-content-01">
                            {t('Connecting Collaborative Changemakers')}
                        </Typography>
                    </div>
                    <img src={forgotImg} className="forgot-2-search" />
                   { this.state.otpvalid === true ?
                    <NewPasswordForm state={this.state} handlePasswordSubmit={this.handlePasswordSubmit} handlePasswordChange={this.handlePasswordChange} handleShowNewPassword={this.handleShowNewPassword} handleShowConfirmPassword={this.handleShowConfirmPassword} />
                    :<ForgotPasswordEmailForm state={this.state} handleEmailSubmit={this.handleEmailSubmit} handleEmailChange={this.handleEmailChange} handleOtpSubmit={this.handleOtpSubmit} handleOTPChange={this.handleOTPChange}/>
                   }
                </div>
                <div className="rightImage">
                    <img src={lowerBoxImg}/>
                </div>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={this.state.showSnackbar}
                autoHideDuration={5000}
                onClose={this.handleCloseSnackbar}
                >
                    <MuiAlert

                    onClose={this.handleCloseSnackbar}
                    severity={this.state.severity}
                    >
                    {this.state.message}
                </MuiAlert>
            </Snackbar>
        </>
            // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End

