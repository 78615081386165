import React from "react";
import { Typography } from "@material-ui/core";
import {t} from 'i18next';

export const LikeButton = ({ isLike, onClick, commentOnClick, shareHandler }: { isLike: boolean, onClick: any, commentOnClick: any , shareHandler?: any}) => {
    return (
        <div className="likesCommentsButtons">
            <div className="likesContainer">
                <div className="likes" test-id="likeButton" onClick={onClick}>
                    {isLike ?
                        <svg xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 122.88 113.41"
                        >
                            <path
                                d="M4.29,47.64h19.3A4.31,4.31,0,0,1,27.88,52V109.1a4.31,4.31,0,0,1-4.29,4.31H4.29A4.31,4.31,0,0,1,0,109.1V52a4.31,4.31,0,0,1,4.29-4.31ZM59,4.77c2.27-11.48,21.07-.91,22.31,17.6A79.82,79.82,0,0,1,79.68,42h26.87c11.17.44,20.92,8.44,14,21.58,1.57,5.72,1.81,12.44-2.45,15.09.53,9-2,14.64-6.65,19.06-.31,4.52-1.27,8.53-3.45,11.62-3.61,5.09-6.54,3.88-12.22,3.88H50.45c-7.19,0-11.11-2-15.81-7.88V54.81C48.16,51.16,55.35,32.66,59,20.51V4.77Z"
                                fill='#065fd4'
                            />
                        </svg> : <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M7.50016 17.5H15.0002C15.6918 17.5 16.2835 17.0834 16.5335 16.4834L19.0502 10.6084C19.1252 10.4167 19.1668 10.2167 19.1668 10V8.33337C19.1668 7.41671 18.4168 6.66671 17.5002 6.66671H12.2418L13.0335 2.85837L13.0585 2.59171C13.0585 2.25004 12.9168 1.93337 12.6918 1.70837L11.8085 0.833374L6.31683 6.32504C6.01683 6.62504 5.8335 7.04171 5.8335 7.50004V15.8334C5.8335 16.75 6.5835 17.5 7.50016 17.5ZM7.50016 7.50004L11.1168 3.88337L10.0002 8.33337H17.5002V10L15.0002 15.8334H7.50016V7.50004ZM0.833496 7.50004H4.16683V17.5H0.833496V7.50004Z"
                                fill="#64748B" />
                        </svg>
                    }
                    <Typography className="likesText">{t('Like')}</Typography>
                </div>
                <div className="bar"></div>
                <div className="likes" onClick={commentOnClick}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                    >
                        <path
                            d="M3.33317 3.33329H16.6665V13.3333H4.30817L3.33317 14.3083V3.33329ZM3.33317 1.66663C2.4165 1.66663 1.67484 2.41663 1.67484 3.33329L1.6665 18.3333L4.99984 15H16.6665C17.5832 15 18.3332 14.25 18.3332 13.3333V3.33329C18.3332 2.41663 17.5832 1.66663 16.6665 1.66663H3.33317ZM4.99984 9.99996H11.6665V11.6666H4.99984V9.99996ZM4.99984 7.49996H14.9998V9.16662H4.99984V7.49996ZM4.99984 4.99996H14.9998V6.66663H4.99984V4.99996Z"
                            fill="#64748B" />
                    </svg>
                    <Typography className="likesText">
                        {t('Comment')}
                    </Typography>
                </div>
            </div>
            <div onClick={shareHandler} className="likes">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <path
                        d="M15 13.4C14.3667 13.4 13.8 13.65 13.3667 14.0416L7.425 10.5833C7.46667 10.3916 7.5 10.2 7.5 9.99996C7.5 9.79996 7.46667 9.60829 7.425 9.41663L13.3 5.99163C13.75 6.40829 14.3417 6.66663 15 6.66663C16.3833 6.66663 17.5 5.54996 17.5 4.16663C17.5 2.78329 16.3833 1.66663 15 1.66663C13.6167 1.66663 12.5 2.78329 12.5 4.16663C12.5 4.36663 12.5333 4.55829 12.575 4.74996L6.7 8.17496C6.25 7.75829 5.65833 7.49996 5 7.49996C3.61667 7.49996 2.5 8.61663 2.5 9.99996C2.5 11.3833 3.61667 12.5 5 12.5C5.65833 12.5 6.25 12.2416 6.7 11.825L12.6333 15.2916C12.5917 15.4666 12.5667 15.65 12.5667 15.8333C12.5667 17.175 13.6583 18.2666 15 18.2666C16.3417 18.2666 17.4333 17.175 17.4333 15.8333C17.4333 14.4916 16.3417 13.4 15 13.4ZM15 3.33329C15.4583 3.33329 15.8333 3.70829 15.8333 4.16663C15.8333 4.62496 15.4583 4.99996 15 4.99996C14.5417 4.99996 14.1667 4.62496 14.1667 4.16663C14.1667 3.70829 14.5417 3.33329 15 3.33329ZM5 10.8333C4.54167 10.8333 4.16667 10.4583 4.16667 9.99996C4.16667 9.54163 4.54167 9.16663 5 9.16663C5.45833 9.16663 5.83333 9.54163 5.83333 9.99996C5.83333 10.4583 5.45833 10.8333 5 10.8333ZM15 16.6833C14.5417 16.6833 14.1667 16.3083 14.1667 15.85C14.1667 15.3916 14.5417 15.0166 15 15.0166C15.4583 15.0166 15.8333 15.3916 15.8333 15.85C15.8333 16.3083 15.4583 16.6833 15 16.6833Z"
                        fill="#64748B" />
                </svg>
                <Typography className="likesText">{t('Share')}</Typography>
            </div>
        </div>
    )
}