import React from "react";
import {
    Grid,
    FormControl,
    FormLabel,
    TextField,
    Button,
    MenuItem,
    Select,
    Typography,
    Chip,
    Box
} from "@material-ui/core";
import { Add, Done } from '@material-ui/icons';
import ImpactArea from "./ImpactArea.web";
import SkillsArea from "./SkillsArea.web";
import {t} from "i18next";


const ProfileSetting = ({ state, handleChange, handleSubmit, handleSelectInterest, handleShowFields, handleAddFormFields, handleOtherFields, handleRemoveAddMoreFields }: any) => {
    const showError = (errorKey: string) => {
        return state.ProfileErrorField[errorKey] &&
            <p data-test-id={`error-${errorKey}`} className="error-msg">{state.ProfileErrorField[errorKey]}</p>
    }
    return (

        <form className="profile-form">
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6} sm={12} md={6}>
                    <FormControl fullWidth>
                        <FormLabel className="form-labels">
                            {t('Full Name')}
                        </FormLabel>
                        <TextField
                            value={state.ProfileFormField.full_name}
                            onChange={handleChange}
                            type={'text'}
                            placeholder={`John Doe`}
                            className="text-box"
                            variant="outlined"
                            name={'full_name'}
                        />
                        {showError("nameError")}
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6} sm={12} md={6}>
                    <FormControl fullWidth>
                        <FormLabel className="form-labels">
                            {t('Email Address')}
                        </FormLabel>
                        <TextField
                            value={state.ProfileFormField.email}
                            onChange={handleChange}
                            type={'text'}
                            placeholder={`Johndoe@gmail.com`}
                            className="text-box"
                            variant="outlined"
                            name={'email'}
                        />
                        {showError("emailError")}
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6} sm={12} md={6}>
                    <FormControl fullWidth>
                        <FormLabel className="form-labels">
                            {t('Phone Number')}
                        </FormLabel>
                        <TextField
                            value={state.ProfileFormField.full_phone_number}
                            onChange={handleChange}
                            type={'text'}
                            placeholder={``}
                            className="text-box"
                            variant="outlined"
                            name={'full_phone_number'}
                        />
                        {showError("phoneError")}
                    </FormControl>
                </Grid>
                <Grid item xs={6} lg={6} sm={6} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <FormLabel className="form-labels">
                            {t('Gender')}
                        </FormLabel>
                        <Select
                            value={state.ProfileFormField.gender}
                            onChange={handleChange}
                            label="Gender"
                            displayEmpty
                            className="dropdown-box"
                            name="gender"
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            <MenuItem className="dropdown-links" value={""} disabled>{t('Select Gender')}</MenuItem>
                            <MenuItem key={'male'} value={'Male'} className="dropdown-links"><div>{t('Male')}</div></MenuItem>
                            <MenuItem key={'female'} value={'Female'} className="dropdown-links"><div>{t('Female')}</div></MenuItem>

                        </Select>
                        {showError("genderError")}
                    </FormControl>
                </Grid>
                <Grid item xs={6} lg={6} sm={6} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <FormLabel className="form-labels">
                            {t('Country')}
                        </FormLabel>
                        <Select
                            value={state.ProfileFormField.country}
                            onChange={handleChange}
                            label="Country"
                            displayEmpty
                            className="dropdown-box"
                            name="country"
                        >
                            <MenuItem className="dropdown-links" value="" disabled>{t('Select Country')}</MenuItem>
                            {
                                state.countryList && state.countryList.map((country: any) => (
                                    <MenuItem key={country?.attributes?.name} value={country?.attributes?.name} className="dropdown-links">
                                        <div>
                                            {country?.attributes?.name}
                                            <Done className={country?.attributes?.name === state.ProfileFormField.country ? "active-done" : "deactive-done"} />
                                        </div>
                                    </MenuItem>

                                ))
                            }
                        </Select>
                        {showError("countryError")}
                    </FormControl>
                </Grid>
                <Grid item xs={6} lg={6} sm={6} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <FormLabel className="form-labels">
                            {t('State')}
                        </FormLabel>
                        <Select
                            value={state.ProfileFormField.state}
                            onChange={handleChange}
                            label="State"
                            displayEmpty
                            className="dropdown-box"
                            name="state"
                        >
                            <MenuItem className="dropdown-links" value="" disabled>{t('Select State')}</MenuItem>
                            {
                                state.stateList && state.stateList.filter((st: any) => st.name).map((item: any) => (
                                    <MenuItem data-test-id="state-item" key={item?.name} value={item?.name} className="dropdown-links">
                                        <div>
                                            {item?.name}
                                            <Done className={item?.name === state.ProfileFormField.state ? "active-done" : "deactive-done"} />
                                        </div>
                                    </MenuItem>

                                ))
                            }
                        </Select>
                        {showError("stateError")}
                    </FormControl>
                </Grid>
                <Grid item xs={6} lg={6} sm={6} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <FormLabel className="form-labels">
                            {t('City')}
                        </FormLabel>
                        <Select
                            value={state.ProfileFormField.city}
                            onChange={handleChange}
                            label="City"
                            displayEmpty
                            className="dropdown-box"
                            name="city"
                        >
                            <MenuItem className="dropdown-links" value="" disabled>{t('Select City')}</MenuItem>
                            {
                                state.cityList && state.cityList.filter((ct: any) => ct.name).map((item: any) => (
                                    <MenuItem data-test-id="state-item" key={item?.name} value={item?.name} className="dropdown-links">
                                        <div>
                                            {item?.name}
                                            <Done className={item?.name === state.ProfileFormField.city ? "active-done" : "deactive-done"} />
                                        </div>
                                    </MenuItem>

                                ))
                            }
                        </Select>
                        {showError("cityError")}
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6} sm={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <FormLabel className="form-labels">
                            {t('Profession')}
                        </FormLabel>
                        <TextField
                            value={state.ProfileFormField.profession}
                            onChange={handleChange}
                            type={'text'}
                            placeholder={``}
                            className="text-box"
                            variant="outlined"
                            name={'profession'}
                        />
                        {showError("professionError")}
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6} sm={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <FormLabel className="form-labels">
                            {t('Select Preferred Language')}
                        </FormLabel>
                        <Select
                            value={state.ProfileFormField.preferred_language}
                            onChange={handleChange}
                            displayEmpty
                            label="preferred language"
                            name="preferred_language"
                            className="dropdown-box"
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                            }}
                        >
                            <MenuItem className="dropdown-links" value="" disabled>{t('Select One')}</MenuItem>
                            <MenuItem className="dropdown-links" value={"English"}><div>{t('English')}</div></MenuItem>
                            <MenuItem className="dropdown-links" value={"French"}><div>{t('French')}</div></MenuItem>
                            <MenuItem className="dropdown-links" value={"Bengali"}><div>{t('Bengali')}</div></MenuItem>
                        </Select>
                        {showError("languageError")}
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6} sm={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <FormLabel className="form-labels">
                            {t('Interested in')}
                        </FormLabel>
                        <Box className="interest-box">
                        {
                            state?.interestList && Object.keys(state?.interestList).map((type: string, i: number) => (
                                 
                                    <>
                                    <Typography className="interest-category">{type == "impact_area" ? t('Impact Area') : t('Skills and Approaches')}</Typography>
                                    {
                                        state?.interestList[type].map((data: any, indx: number) => (
                                            <Chip
                                                data-test-id={`chip-${indx}`}
                                                key={indx}
                                                className={state.interestIds && state.interestIds.includes(data.id) ? "chip-label-selected":"chip-label"}
                                                icon={state.interestIds && state.interestIds.includes(data.id) ? <Done className="icon-style-selected"/>:<Add className="icon-style" />}
                                                label={data?.attributes?.name.charAt(0).toUpperCase() + data?.attributes?.name.slice(1)}
                                                onClick={() => handleSelectInterest(data.id)}
                                            />
                                        ))
                                    }
                                    {type === "impact_area" && <ImpactArea state={state} type={type} indx={i} handleShowFields={handleShowFields} handleAddFormFields={handleAddFormFields} handleOtherFields={handleOtherFields} handleRemoveAddMoreFields={handleRemoveAddMoreFields}/>}
                                    {type === "skills_and_approaches" && <SkillsArea state={state} type={type} indx={i} handleShowFields={handleShowFields} handleAddFormFields={handleAddFormFields} handleOtherFields={handleOtherFields} handleRemoveAddMoreFields={handleRemoveAddMoreFields}/>}

                                </>
                                )
                            )
                        }
                        </Box>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6} sm={12} md={6}>
                    <FormControl fullWidth>
                        <FormLabel className="form-labels">
                            {t('What kind of partnership practitioner am i?')}
                        </FormLabel>
                        <TextField
                            value={state.ProfileFormField.partnership_practitioner}
                            onChange={handleChange}
                            type={'text'}
                            placeholder={t(`Professor`)}
                            className="text-box"
                            variant="outlined"
                            name={'partnership_practitioner'}
                        />
                        {showError("partnershipError")}
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6} sm={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <FormLabel className="form-labels">
                            {t('Accredited partnership broker')}
                        </FormLabel>
                        <Select
                            value={state.ProfileFormField.accredited_partnership_broker}
                            onChange={handleChange}
                            displayEmpty
                            label="accredited_partnership_broker"
                            className="dropdown-box"
                            name="accredited_partnership_broker"
                            MenuProps={{
                                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                getContentAnchorEl: null,
                            }}>
                            <MenuItem className="dropdown-links" value="" disabled>{t('Select One')}</MenuItem>
                            <MenuItem className="dropdown-links" value={"true"}><div>{t('Yes')}</div></MenuItem>
                            <MenuItem className="dropdown-links" value={"false"}><div>{t('No')} </div></MenuItem>
                        </Select>
                        {showError("brokerError")}
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6} sm={12} md={6}>
                    <FormControl fullWidth>
                        <FormLabel className="form-labels">
                            {t('Organizational affiliations')}
                        </FormLabel>
                        <TextField
                            value={state.ProfileFormField.organizational_affilation}
                            onChange={handleChange}
                            type={'text'}
                            placeholder={t(`Affiliated to echosystem environment`)}
                            className="text-box"
                            variant="outlined"
                            name={'organizational_affilation'}
                        />
                        {showError("affiliationsError")}
                    </FormControl>
                </Grid>
                <Grid item className="btn-box" xs={12} lg={12} sm={12} md={12}>
                    <Button type="button" onClick={handleSubmit} className="setting-submit-btn" variant="contained" color="primary">{t('Save')}</Button>
                </Grid>
            </Grid>
        </form>

    );
};

export default ProfileSetting;
