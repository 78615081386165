import React from "react";
// Customizable Area Start
import {  Container, Typography, Paper } from '@material-ui/core';

function PrivacyPolicy({ state }: any): JSX.Element {

    return (
        <>
            <main>
                <div
                    className="main-div"
                >
                    <Container className="main-container">
                        <Paper className="paper-main" data-testId="dataIdTestId" style={{ maxWidth: '840px', margin: '0 auto' }}>
                            <div className="grid-container">
                                <div className="img-div">
                                    <img
                                        src={state.policyImage}
                                        alt="Left Image"
                                        className="tc-img"
                                    />
                                </div>
                                <Typography className="title-heading">Privacy Policy</Typography>
                                <span dangerouslySetInnerHTML={{ __html: state.policyData }} />
                            </div>
                        </Paper>
                    </Container>
                </div>
            </main >
        </>
    );
};
// Customizable Area End

export default PrivacyPolicy;
