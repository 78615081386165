
import * as React from 'react';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import { GroupMediaData } from "./groupMediaData.web";
import {t} from "i18next";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, px: 2 }}>
          <Typography>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const GroupMediaTabs = ({ groupMediaData, handleEndPoint, handlePostDetails, groupId }: any) => {
  const [value, setValue] = React.useState(0);

  const handleChange: any = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ padding: "24px 0 0",  width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs test-id="val" value={value} onChange={handleChange} className="tabsContent" aria-label="basic tabs example">
          <Tab label={t('Images')} test-id="images" className="tabLabel" {...a11yProps(0)} onClick={() => handleEndPoint('account/user_images')} />
          <Tab label={t('Videos')} test-id="videos" className="tabLabel" {...a11yProps(1)} onClick={() => handleEndPoint('account/user_videos')} />
          <Tab label={t('Audios')} test-id="audios" className="tabLabel" {...a11yProps(2)} onClick={() => handleEndPoint('account/user_audios')} />
          <Tab label={t('Documents')} test-id="documents" className="tabLabel" {...a11yProps(3)} onClick={() => handleEndPoint('account/user_documents')} />
        </Tabs>
      </Box>
      <CustomTabPanel index={0} value={value}>
        <GroupMediaData groupMediaData={groupMediaData} type={"images"} handlePostDetails={handlePostDetails} />
      </CustomTabPanel>

      <CustomTabPanel index={1} value={value}>
        <GroupMediaData groupMediaData={groupMediaData} type={"videos"} handlePostDetails={handlePostDetails} />
      </CustomTabPanel>

      <CustomTabPanel index={2} value={value}>
        <GroupMediaData groupMediaData={groupMediaData} type={"audios"} handlePostDetails={handlePostDetails} />
      </CustomTabPanel>

      <CustomTabPanel index={3} value={value}>
        <GroupMediaData groupMediaData={groupMediaData} type={"documents"} handlePostDetails={handlePostDetails} />
      </CustomTabPanel>
    </Box>
  );
}