
  export const colors = {
    common: {
      black: '#4D5766',
      white: '#fff',
      green: "#06AD17",
      red: "#F51919",
      orange: "#ff9800",
      gray: "#B1B1B1",
      blue: "#5497FF"
    },
    background: {
      default: '#f5f5f5'
    },
    primary: {
      main: '#5497FF',
      dark: '#3D7FE6',
    },
    secondary: {
      main: '#FFFFF',
    },
    grey: {
      100: '#f0f0f0',
      200: '#ebebeb',
      300: '#ababab',
      400: '#969696',
      500: '#6c6c6c',
      600: '#484848',
      700: '#5497FF'
    },
    error: {
      main: '#e53935'
    },
    warning: {
      main: '#ff9a01' // Change expected
    },
    success: {
      main: '#43a047'
    },
    info: {
      main: '#0183eb'
    },
    text: {
      primary: '#0080CA',
      secondary: '#808080'
    },
  };
  