import React from "react";
import {
    Box,
    Avatar,
    Typography,
  } from "@material-ui/core";
import { groupIcons } from '../assets';
import { t } from "i18next";

export const AttachmentCard = ({postModalHandlers, group}:any) => {
  return(
    <>
    <Box data-test-id="postModalHandlers" className="usercards" style={{cursor:"pointer"}} onClick={() => postModalHandlers()}>
    <Box className="usercardlefts">
      <Avatar src={group && group.attributes && group.attributes.icon_image || groupIcons} />
      <div>
          <Typography className="inputField attachmentText">{t("Hey, share what's on your mind...")}</Typography>
      </div>
    </Box>
    <div className="attachments">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M19.4632 5.57598C18.7752 4.82598 17.5342 4.77998 16.7072 5.60698L8.60718 13.707C8.39718 13.917 8.39718 14.183 8.60718 14.393C8.81718 14.603 9.08318 14.603 9.29318 14.393L15.9932 7.69298C16.1818 7.51082 16.4344 7.41003 16.6966 7.41231C16.9588 7.41459 17.2096 7.51976 17.395 7.70516C17.5804 7.89057 17.6856 8.14139 17.6879 8.40358C17.6901 8.66578 17.5893 8.91838 17.4072 9.10698L10.7072 15.807C10.4788 16.0419 10.2057 16.2287 9.90391 16.3563C9.60212 16.4838 9.27782 16.5495 8.95018 16.5495C8.62255 16.5495 8.29824 16.4838 7.99645 16.3563C7.69466 16.2287 7.42152 16.0419 7.19318 15.807C6.95822 15.5786 6.77145 15.3055 6.64391 15.0037C6.51637 14.7019 6.45065 14.3776 6.45065 14.05C6.45065 13.7223 6.51637 13.398 6.64391 13.0963C6.77145 12.7945 6.95822 12.5213 7.19318 12.293L15.2932 4.19298C16.8602 2.62498 19.4082 2.57398 20.9232 4.20798C22.4752 5.77698 22.5202 8.31198 20.8932 9.82098L11.4072 19.307C9.21718 21.497 5.78318 21.497 3.59318 19.307C1.40318 17.117 1.40318 13.683 3.59318 11.493L11.6932 3.39298C11.8818 3.21082 12.1344 3.11003 12.3966 3.11231C12.6588 3.11459 12.9096 3.21976 13.095 3.40516C13.2804 3.59057 13.3856 3.84138 13.3879 4.10358C13.3901 4.36578 13.2893 4.61838 13.1072 4.80698L5.00718 12.907C3.59718 14.317 3.59718 16.483 5.00718 17.893C6.41718 19.303 8.58318 19.303 9.99318 17.893L19.4932 8.39298L19.5242 8.36298C20.2742 7.67598 20.3202 6.43398 19.4932 5.60698C19.483 5.59685 19.473 5.58652 19.4632 5.57598Z"
          fill="#94A3B8" />
      </svg>
      <Typography className='attachmentText'>{t("Attachment")}</Typography>
    </div>
  </Box>
  </>
  )
}  