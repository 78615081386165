import React from "react";
import {
    Grid,
    FormControl,
    FormLabel,
    TextField,
    Button,
    MenuItem,
    FormControlLabel,
    Select,
    Checkbox,
    Popover,
    InputAdornment,
    Input,
} from "@material-ui/core";
import { VisibilityOffOutlined, VisibilityOutlined, Done } from '@material-ui/icons';
import { info } from "../assets";
import {t} from "i18next";

const SignUpForm = ({ state, handleInfoClick, handleInfoClose, handleShowConfirmPassword, handleNext, handleShowPassword, handleChange, handleCloseCountry, handleCloseLanguage, handleOpenCountry, handleOpenLanguage }: any) => {
    const openInfo = Boolean(state.anchorEl);
    return (

        <form onSubmit={handleNext} className="signup-form">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel className="form-labels">
                            {t('Full Name')}
                        </FormLabel>
                        <TextField
                            value={state.SignupUpFormField.full_name}
                            type={'text'}
                            placeholder={`John Doe`}
                            className="text-box"
                            variant="outlined"
                            name={'full_name'}
                            onChange={handleChange}
                        />
                    </FormControl>
                    {state.nameError &&
                        <p className="error-msg">{state.nameError}</p>
                    }
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel className="form-labels">
                            {t('Email Address')}
                        </FormLabel>
                        <TextField
                            value={state.SignupUpFormField.email}
                            type={'text'}
                            placeholder={`Johndoe@gmail.com`}
                            className="text-box"
                            variant="outlined"
                            name={'email'}
                            onChange={handleChange}
                        />
                    </FormControl>
                    {state.emailError &&
                        <p className="error-msg">{state.emailError}</p>
                    }
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel className="form-labels">
                            {t('Set Password')}
                        </FormLabel>
                        <img aria-describedby={'info1'} onClick={handleInfoClick} className="info-img" src={info} alt="" />
                        <Input
                            disableUnderline
                            value={state.SignupUpFormField.password}
                            data-test-id="showpassInput"
                            type={state.showPassword ? 'text' : 'password'}
                            placeholder={`xxxxxxxxxx`}
                            className="text-box"
                            name={'password'}
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment className="btn-show-pass" onClick={handleShowPassword} position="start">
                                    {state.showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {state.passwordError &&
                        <p className="error-msg">{state.passwordError}</p>
                    }
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel className="form-labels">
                            {t('Confirm Password')}
                        </FormLabel>
                        <img aria-describedby={'info1'} onClick={handleInfoClick} className="info-img" src={info} alt="" />
                        <Input
                            disableUnderline
                            value={state.SignupUpFormField.cpassword}
                            type={state.showConfirmPassword ? 'text' : 'password'}
                            placeholder={`xxxxxxxxxx`}
                            className="text-box"
                            name={'cpassword'}
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment className="btn-show-pass" data-test-id="showpass-input" onClick={handleShowConfirmPassword} position="start">
                                    {state.showConfirmPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {state.cPasswordError &&
                        <p className="error-msg">{state.cPasswordError}</p>
                    }
                </Grid>
                <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                    <FormLabel className="form-labels">
                        {t('Country')}
                    </FormLabel>
                   
                    <Select
                        data-test-id="countrySelect"
                        value={state.SignupUpFormField.country}
                        label="Country"
                        displayEmpty
                        className="dropdown-box"
                        name="country"
                        open={state.openCountry}
                        onOpen={handleOpenCountry}
                        onClose={handleCloseCountry}
                        onChange={handleChange}
                    >
                           <MenuItem className="dropdown-links" value="" disabled>{t('Select Country')}</MenuItem>
                        {
                            state.countryList && state.countryList.map((country: any) => (
                                <MenuItem key={country?.attributes?.name} value={country?.attributes?.name} className="dropdown-links">
                                    <div>
                                        {country?.attributes?.name}
                                        <Done className={country?.attributes?.name === state.SignupUpFormField.country ? "active-done" : "deactive-done"} />
                                    </div>
                                </MenuItem>

                            ))
                        }
                    </Select>
                </FormControl>
                {state.countryError &&
                    <p className="error-msg">{state.countryError}</p>
                }
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                    <FormLabel className="form-labels">
                        {t('Preferred Language')}
                    </FormLabel>
                    <Select
                        value={state.SignupUpFormField.preferred_language}
                        displayEmpty
                        label="preferred language"
                        name="preferred_language"
                        open={state.openLanguage}
                        onOpen={handleOpenLanguage}
                        onClose={handleCloseLanguage}
                        onChange={handleChange}
                        className="dropdown-box"
                        MenuProps={{
                            anchorOrigin:{
                                vertical:"bottom",
                                horizontal:"left"
                            },
                            getContentAnchorEl: null,
                        }}
                    >    
                        <MenuItem className="dropdown-links" value="" disabled>{t('Select One')}</MenuItem>
                        <MenuItem className="dropdown-links" value={0}><div>{t('English')}   <Done className={0 === state.SignupUpFormField.preferred_language ? "active-lang-done" : "deactive-lang-done"} /></div></MenuItem>
                        <MenuItem className="dropdown-links" value={1}><div>{t('Bengali')}   <Done className={1 === state.SignupUpFormField.preferred_language ? "active-lang-done" : "deactive-lang-done"} /></div></MenuItem>
                        <MenuItem className="dropdown-links" value={2}><div>{t('French')}   <Done className={2 === state.SignupUpFormField.preferred_language ? "active-lang-done" : "deactive-lang-done"} /></div></MenuItem>
                    </Select> 
                </FormControl>
                {state.languageError &&
                    <p className="error-msg">{state.languageError}</p>
                }
            </Grid>
                <Grid item xs={12}>
                    <Button type="submit" className="reg-submit-btn" variant="contained" color="primary">{t('Continue')}</Button>
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleChange}
                                name="termsOfUse"
                                checked={state.termsOfUse}
                                color="default"

                            />}
                        className="checkbox-input"
                        label={null}
                    />
                </Grid>
                <Grid className="checkbox-label-box" item xs={11}>
                    <span className="checkbox-label">
                        {t('I hereby consent to the')} <a target="_blank" className="link-terms termsprivacy-label" href='/PrivacyPolicyWeb'>{t('Privacy Policy')}
                        </a> {t('and')} <a target="_blank" className="link-terms termsprivacy-label" href='/TermsConditionWeb'>
                            {t('Terms of use')}
                        </a>{" "}
                    </span>
                </Grid>
            </Grid>
            <Popover
                className="info-msg"
                id={'info1'}
                open={openInfo}
                anchorEl={state.anchorEl}
                onClose={handleInfoClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <li>{t('At least one capital letter')}</li>
                <li>{t('At least one lowercase letter')}</li>
                <li>{t('At least one number')}</li>
                <li>{t('Minimum character length is 8 characters.')}</li>
            </Popover>
        </form>

    );
};

export default SignUpForm;
