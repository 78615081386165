Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postAPiMethod = "POST";
exports.deleteAPiMethod = "DELETE";
exports.updateAPiMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CommunityForum";
exports.labelBodyText = "CommunityForum Body";

exports.btnExampleTitle = "CLICK ME";
exports.groupsAPiEndPoint = "/account_groups/groups?[data][attributes][page]=1&[data][attributes][per_page]=5";
exports.hashtagsAPiEndPoint = "hashtags";
exports.likesApiEndPoint = "like/likes";
exports.commentApiEndPoint = "comments"
exports.topTrendingApiEndPoint = "posts/trending_post?[data][attributes][page]=1&[data][attributes][per_page]=5";
exports.createPostApiEndPoint = "posts/posts";
exports.recentGroupsAPiEndPoint = "account_groups/recent_groups";
exports.translationApiEndPoint = "account/translate_text"
exports.reportApiEndPoint = "posts/report_on_post";
exports.userInterestAPiEndPoint = "bx_block_interests/user_interests";
exports.searchAPiEndPoint = "account/community_search";
exports.findUserApiEndPoint = "account/find_user/";
exports.removeAttachmentEndPoint = "posts/remove_attachment";
exports.unreadNotificationCountApiEndPoint = "bx_block_notifications/unread_notifications_count";
// Customizable Area End