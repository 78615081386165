import React from 'react';
import {
    Avatar,
    Box,
    Divider,
    Grid,
    Input,
    Typography,
    Container,
    Button,
    Paper
} from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import ArrowBack from "@material-ui/icons/ArrowBack";
import { LikeButton } from '../../../../components/src/LikeButton';
import { MediaGrid, MediaWithoutGrid, MediaWithGrid } from './Media.web';
import { t } from 'i18next';
import TranslationCore from './TranslationCore.web'
import FilterTranslateDesc from './FilterTranslation.web'

const HashTagRelated = (
    {
        state,
        authorId,
        onLikeHandler,
        onEditHandler,
        onReplyHandler,
        onCommentChange,
        onDeleteHandler,
        onRepliedHandler,
        onCommentHandler,
        handlePostDetails,
        allDownloadHandler,
        onShareInputHandler,
        onClickCommentIndex,
        onClickReplyComment,
        handleConfirmModalOpen,
        onReportModalClickHandler,
        commentBoxVisibilityHandler,
        onCommentDeleteHandler,
        handlePostDetailsBack,
        onMediaModalHandler,
        playVideo,
        playAudio,
        handleTransBackToOriginal,
        handleTranslation,
    }: any) => {
    const ReportMenu = (arr: any, items: any) => {
        return (
            state.isReportModal && items.id === state.reportIndex &&
            <Box className='report-modal'>
                {/* <Divider style={{ width: "100%" }} /> */}
                <Typography className='report-text' test-id="down-click-id" onClick={() => allDownloadHandler(arr)}>{t('Download')}</Typography>
                <Divider style={{ width: "100%" }} />
                {
                    items.attributes.author_id == authorId &&
                    <>
                        <Typography className='report-text' test-id="delete-id" onClick={() => onDeleteHandler(items.attributes.id)}>{t('Delete')}</Typography>
                        <Divider style={{ width: "100%" }} />
                        <Typography className='report-text' test-id="edit-id" onClick={() => onEditHandler(items)}>{t('Edit')}</Typography>
                        <Divider style={{ width: "100%" }} />
                    </>
                }
                <Typography className='report-text' test-id="report-click-id" onClick={() => handleConfirmModalOpen(items.id)}>{t('Report')}</Typography>
            </Box>
        )
    }
    const MediaBox = (mediaArr: any) => {
        return (
            <>
                {mediaArr.length >= 5
                    && mediaArr.slice(0, 4).map((items: any, idx: number) => (
                        <MediaGrid mediaArr={mediaArr} items={items} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
                    ))}
                {mediaArr.length === 1 &&
                    mediaArr.map((items: any, idx: number) => (
                        <MediaWithoutGrid mediaArr={mediaArr} item={items} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
                    ))}
                {
                    mediaArr.length > 1 && mediaArr.length < 5 &&
                    mediaArr.map((items: any, idx: number) => (
                        <MediaWithGrid mediaArr={mediaArr} itemData={items} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
                    ))}
            </>
        )
    }
    const CommentInput = (item: any, items: any, index: number, idx: number) => {
        return (
            <div className='replied-box'>
                {item.attributes.reply_counts && <Typography className="time reply-btn" test-id="repiled-click-id" onClick={() => onRepliedHandler(index, idx)}>{items.attributes.reply_counts} {t('Replied')}</Typography>}
                <Typography className="time reply-btn" test-id="reply-btn" onClick={() => onReplyHandler(index, idx)}>{t('Reply')}</Typography>
                {
                    (item.attributes.account_id == authorId || items.attributes.author_id == authorId) &&
                    <Typography test-id="comment-dlt-btn" className="time reply-btn" onClick={() => onCommentDeleteHandler(item.attributes.id)}>{t('Delete')}</Typography>
                }
            </div>
        )
    }
    const CommentBox = (items: any, index: number) => {
        return (
            <>
                {items.attributes.recent_comments.data.length > 0 &&
                    items.attributes.recent_comments.data.slice(0, 2).map((item: any, idx: number) => (
                        <>
                            <Box className="recentPostBox">
                                <Avatar src={item.attributes.author_image} />
                                <div className="commentDetail">
                                    <div className="userNameComment">
                                        <Typography className="userText">{item.attributes.author_name}</Typography>
                                        <Typography className="tag">
                                            -
                                        </Typography>
                                        <Typography className="time">{item.attributes.created_at}</Typography>
                                    </div>
                                    <Typography className="time break word-break">
                                        {item.attributes.comment}
                                    </Typography>
                                    {CommentInput(item, items, index, idx)}
                                </div>
                            </Box>
                            {
                                !state.isCommentEnable && state.commentIndex === index && state.replyIndex === idx &&
                                <Box className="doComment">
                                    <Input
                                        test-id="comment"
                                        className="inputField"
                                        placeholder={t("Comment here")}
                                        id="input-with-icon-adornment"
                                        value={state.commentActiveIndex === index ? state.commentInput : ""}
                                        onChange={state.commentActiveIndex === index ? onCommentChange : undefined}
                                        onClick={() => onClickReplyComment(index)}
                                    />
                                    {state.commentActiveIndex === index ?
                                        <SendIcon
                                            test-id="send-id"
                                            style={{ margin: "0 10px", cursor: "pointer", fill: state.commentInput?.length > 0 && state.isCommentActive ? '#5497FF' : "#94A3B8" }}
                                            onClick={() => onCommentHandler(items.attributes.id, item.attributes.id)} /> :
                                        <SendIcon
                                            style={{ margin: "0 10px", cursor: "pointer", fill: "#94A3B8" }}
                                        />
                                    }
                                </Box>
                            }
                            {
                                state.commentIndex === index && state.isReplied && state.repiedIndex === idx &&
                                item.attributes.comments.data.length > 0 && item.attributes.comments.data.map((ele: any) => (
                                    <Box className="reply-loop-pd recentPostBox">
                                        <Avatar src={ele.attributes.author_image} />
                                        <div className="commentDetail">
                                            <div className="userNameComment">
                                                <Typography className="userText">{ele.attributes.author_name}</Typography>
                                                <Typography className="tag">
                                                    -
                                                </Typography>
                                                <Typography className="time">{ele.attributes.created_at}</Typography>
                                            </div>
                                            <Typography className="break time word-break">
                                                {ele.attributes.comment}
                                            </Typography>
                                            {
                                                (ele.attributes.account_id == authorId || items.attributes.author_id == authorId) &&
                                                <Typography test-id="comment-id-dlt" className="time reply-btn break word-break" onClick={() => onCommentDeleteHandler(ele.attributes.id)}>
                                                    {t('Delete')}
                                                </Typography>
                                            }
                                        </div>
                                    </Box>
                                ))
                            }
                        </>
                    ))
                }
            </>
        )
    }
    const RepliedComment = (items: any, index: number) => {
        return (
            state.isCommentEnable && index === state.commentIndex &&
            <Box className="doComment">
                <Input
                    test-id="comment-2"
                    className="inputField"
                    id="input-with-icon-adornment"
                    placeholder={t("Comment here")}
                    onClick={() => onClickCommentIndex(index)}
                    onChange={state.commentActiveIndex === index ? onCommentChange : undefined}
                    value={state.commentActiveIndex === index ? state.commentInput : ""}
                />
                {state.commentActiveIndex === index ?
                    <SendIcon
                        test-id="send-id-2"
                        style={{ margin: "0 10px", cursor: "pointer", fill: state.commentInput.length > 0 && state.isCommentActive ? '#5497FF' : "#94A3B8" }}
                        onClick={() => onCommentHandler(items.attributes.id, null)}
                    /> :
                    <SendIcon
                        style={{ margin: "0 10px", cursor: "pointer", fill: "#94A3B8" }}
                    />
                }
            </Box>
        )
    }
    return (
        <Container className="postContainer">
            <Box className="recentPostCard">
                <Button onClick={handlePostDetailsBack} className="backbtn"><ArrowBack className="backbtnicon" />{t('Back')}</Button>
            </Box>
            {state.hashtagFiltered.map((items: any, index: number) => {
                let mediaArr = [...items.attributes.images, ...items.attributes.videos, ...items.attributes.audios, ...items.attributes.documents]
                let arr = mediaArr
                let filter = []
                filter = state.translationArray?.filter((i: any) => i.id == items.id).map((i: any) => i.data)
                return (
                    <Box key={items.id} className="recentPostCard">
                        <div className="recentPostBox">
                            <Avatar src={items.attributes.author_image} />
                            <div className="descriptionBox">
                                <div className="desc">
                                    <Typography className="descTitle" test-id="test-post-id" onClick={(e) => handlePostDetails(items.id)}>
                                        {items.attributes.author_name}
                                    </Typography>
                                    <div className="tag">{items.attributes.created_at}</div>
                                </div>
                                <Typography test-id="test-post-id-two" onClick={(e) => handlePostDetails(items.id)} className="descText">
                                    {
                                        items.attributes.description.length < 350 ?
                                            <FilterTranslateDesc filter={filter} description={items.attributes.description} />
                                            :
                                            <>
                                                {items.attributes.description.substring(0, 350) + "... "}
                                                <a test-id="more-id" onClick={(e) => handlePostDetails(items.id)} className='seeMoreText'>{t('See more')}</a>
                                            </>
                                    }
                                </Typography>
                                {
                                    items.attributes.description?.length > 0 ?
                                        <TranslationCore item={items} filter={filter} handleTranslation={handleTranslation} handleTransBackToOriginal={handleTransBackToOriginal} />
                                        : null
                                }
                                <div className="recentTags">
                                </div>
                            </div>
                            <div className='report-btn' test-id="report-id" onClick={() => onReportModalClickHandler(items.id)}>
                                <svg
                                    height="24"
                                    width="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill="#64748B"
                                        d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                                    />
                                </svg>
                            </div>
                            {ReportMenu(arr, items)}
                        </div>
                        <div className='postImageContainer' test-id="len-id">
                            <Grid container spacing={1} style={{ padding: "0 20px", justifyContent: "center" }}>
                                {MediaBox(mediaArr)}
                            </Grid>
                        </div>

                        <div className="likesCard">
                            <div className="likesCommentsContainer">
                                <Typography className="tag">
                                    {items.attributes.post_likes + " "}{items.attributes.post_likes < 2 ? t("Like") : t("Likes")}
                                </Typography>
                                <Typography className="tag">-</Typography>
                                <Typography className="tag">
                                    {items.attributes.post_comments + " "}{items.attributes.post_comments < 2 ? t("Comment") : t("Comments")}
                                </Typography>
                            </div>
                            <LikeButton
                                commentOnClick={() => commentBoxVisibilityHandler(index)}
                                onClick={() => onLikeHandler(items.id, items.attributes.current_user_liked, items.attributes.current_user_liked_id)}
                                shareHandler={() => onShareInputHandler(items.id)}
                                isLike={items.attributes.current_user_liked}
                            />
                            <Divider className="lineDivider" />
                        </div>
                        {CommentBox(items, index)}
                        {RepliedComment(items, index)}
                    </Box>
                )
            })}
        </Container>
    )
}

export default HashTagRelated