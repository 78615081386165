Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Type here...";
exports.labelTitleText = "BulletinBoard2";
exports.labelBodyText = "BulletinBoard2 Body";
exports.btnLoadingTitle = "Loading...";
exports.btnExampleTitle = "Submit";
exports.btnAddImage = "Add Image";
exports.deleteBtnTitle = "Delete";
exports.closeBtnTitle = "Close";
exports.warningTitle = "Are you sure you want to delete this item?";
exports.confirmDeleteTitle = "Confirm Delete";
exports.titlePinned = "Pinned";
exports.titleOthers = "Others";
exports.titleCancel = "Cancel";
exports.titleEdit = "Edit";
exports.titleNoItemsFound = "No items found";
exports.errorTitle = "Required*";
exports.responseErrorTitle1 = "Sorry, something went wrong";
exports.responseErrorTitle2 = "An unexpected error has occured. Please try after sometime.";
exports.titleOr = "Or";
exports.titleImageFormat = "(Image type must be .png or .jpg)";
exports.bulletinBoardFormDataContentType = "multipart/form-data";
exports.bulletinBoardContentType = "application/json";
exports.getItemsAtDateEndPoint = "/bx_block_bulletinboard2/items_at_date";
exports.apiEndPoint =  "/bx_block_bulletinboard2/items";
exports.postApiMethodType = "POST";
exports.getApiMethodType = "GET";
exports.editApiMethodType = "PUT";
exports.deleteApiMethodType = "DELETE";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getItemsAPiEndPoint = "/bx_block_bulletinboard2/items_at_date/";
exports.mainAPiEndPoint = "bx_block_bulletinboard2/items/";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "BulletinBoard2";
exports.labelBodyText = "BulletinBoard2 Body";
exports.errorLable = "Error!";
exports.GET = "GET";
exports.POST = "POST";
exports.DELETE = "DELETE";
exports.PUT = "PUT";
exports.saveChangesTitle = "Save Changes";
exports.descriptionText = "Description:";
exports.placeholderText = "Add Text Here"
exports.addNewItemTitle = "Add New Item"
exports.noDataText = "No Items Available!";
exports.formDataAPIType = "multipart/form-data";
exports.listOfMonths= [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
// Customizable Area End