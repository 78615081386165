import React from "react";
import {
    Typography,
    Container,
} from "@material-ui/core";
import "../../assets/index.scss";
import {edit, fileUploadIcon, galleryImage} from "../assets";
import {RestCreateNewGroupForm} from "./restCreateNewGroupForm.web"
import CreateNewGroupController, {
  Props,
} from "../CreateNewGroupController.web";
import Header from "../../../../components/src/Header.web";
import { t } from "i18next";

export default class CreateNewGroup extends CreateNewGroupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render(){
  const {coverPhotoPreview, profilePhotoPreview, groupName, description, groupNameError, groupTypeError, value,
  interests, interestIds, showOtherImpactFields, showOtherSkillsFields, otherInterestImpactFields, impactInterestUniqueError,
  skillsInterestUniqueError, otherInterestSkillsFields, userInterestError, uploadCoverPhoto, uploadProfilePhoto, selected_interest,
  coverPhotoKey, iconPhotoKey, removedInterestIds, selectedInterestIds} = this.state  
  return(
    <>
       <Header type={""} onSearch={()=>{}}  navigation={this.props.navigation} profileImage={this.props.profileImage}
       notifyCount={Number(localStorage.getItem("notification_unread_count"))}
       />
      <div className="mainContainerSection">
        <Container className="mainContainer" maxWidth="lg" style={{ maxWidth: '1600px', }}>
          <form>
                <div className="banner-upload-img rectangle">
                {coverPhotoPreview && (
                   <img src={coverPhotoPreview} className="is-image-uploaded"/>
                )}
              <input
                accept="image/*"
                type="file"
                id="select-cover-image"
                style={{ display: "none" }}
                onChange={(event)=>this.handleSetState(event, "uploadCoverPhoto", "file")}
               />
                <label htmlFor="select-cover-image"
                 style={{display:'flex', justifyContent:'right', padding:"21 8 0 0", cursor:'pointer', zIndex: 1, position: 'relative'}}>
                  {coverPhotoPreview ? (
                    <img src={edit} className="edit" />
                  ) : (
                    <>
                       <Typography className="uploadCoverText">{t("Upload Cover")}</Typography>
                       <img src={fileUploadIcon} className="uploadCoverIcon"/>
                       </>
                  )}
                </label>
              <div className={coverPhotoPreview ? "main-profile-sec1": "main-profile-sec"}>
                {!coverPhotoPreview && (
                   <img src={galleryImage}  />
                )}
                 {profilePhotoPreview ? (
                  <>
                    <img src={profilePhotoPreview} className="upload-profile" />
                    <input
                    accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={(event)=>this.handleSetState(event, "uploadProfilePhoto", "file")}
                   />
                     <label htmlFor="select-image">
                      <img src={edit} className="edit" />
                    </label>
                    </>
                  ):(
                <div className="upload-profile">
                     <input
                    accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={(event)=>this.handleSetState(event, "uploadProfilePhoto", "file")}
                   />
                    <label htmlFor="select-image" style={{cursor:'pointer'}}>
                    <img src={fileUploadIcon} />
                    <Typography className="uploadPhotoText">{t("Upload Photo")}</Typography>
                  </label>
                </div>  
                  )}
              </div> 
            </div>  
           <RestCreateNewGroupForm handleOpen={this.props.handleOpen} groupName={groupName} groupNameError={groupNameError} description={description} 
            groupTypeError={groupTypeError} value={value} interests={interests} interestIds={interestIds} showOtherImpactFields={showOtherImpactFields} 
            showOtherSkillsFields={showOtherSkillsFields} otherInterestImpactFields={otherInterestImpactFields} 
            impactInterestUniqueError={impactInterestUniqueError} otherInterestSkillsFields={otherInterestSkillsFields} 
            skillsInterestUniqueError={skillsInterestUniqueError} userInterestError={userInterestError} handleSetState={this.handleSetState} 
            handleSelectInterest={this.handleSelectInterest} handleShowFields={this.handleShowFields} handleFormFields={this.handleFormFields} 
            handleOtherFields={this.handleOtherFields} uploadCoverPhoto={uploadCoverPhoto} uploadProfilePhoto={uploadProfilePhoto}
            selected_interest={selected_interest} handleCloseGroupType={this.handleCloseGroupType} iconPhotoKey={iconPhotoKey}
            handleOpenGroupType={this.handleOpenGroupType} openOtherComponent={this.props.openOtherComponent} coverPhotoKey={coverPhotoKey}
            removedInterestIds={removedInterestIds} selectedInterestIds={selectedInterestIds}
           />
        </form>
        </Container>
      </div>    
    </>
  )
}
}
