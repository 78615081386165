import React from 'react';
import {
    Avatar,
    Box,
    Divider,
    Grid,
    Input,
    Typography,
} from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import { LikeButton } from '../../../../components/src/LikeButton';
import { MediaGrid, MediaWithoutGrid, MediaWithGrid } from './Media.web';
import TranslationCore from './TranslationCore.web'
import FilterTranslateDesc from './FilterTranslation.web'
import { t } from 'i18next';

const AllPostDetails = (
    {
        state,
        authorId,
        onLikeHandler,
        onEditHandler,
        onReplyHandler,
        onCommentChange,
        onDeleteHandler,
        onRepliedHandler,
        onCommentHandler,
        handlePostDetails,
        allDownloadHandler,
        onShareInputHandler,
        onClickCommentIndex,
        onClickReplyComment,
        onCommentDeleteHandler,
        handleConfirmModalOpen,
        onReportModalClickHandler,
        commentBoxVisibilityHandler,
        onMediaModalHandler,
        handleTransBackToOriginal,
        handleTranslation,
        playVideo,
        playAudio
    }: any) => {
    const ReportMenu = (arr: any, item: any, index: number) => {
        return (
            <>
                {state.isReportModal && state.reportIndex === item.id &&
                    <Box className='report-modal'>
                        {/* <Divider style={{ width: "100%" }} /> */}
                        <Typography test-id="down-click-id" className='report-text' onClick={() => allDownloadHandler(arr)}>{t('Download')}</Typography>
                        <Divider style={{ width: "100%" }} />
                        {
                            item.attributes.author_id == authorId &&
                            <>
                                <Typography test-id="delete-id" className='report-text' onClick={() => onDeleteHandler(item.attributes.id)}>{t("Delete")}</Typography>
                                <Divider style={{ width: "100%" }} />
                                <Typography test-id="edit-id" className='report-text' onClick={() => onEditHandler(item, index)}>{t('Edit')}</Typography>
                                <Divider style={{ width: "100%" }} />
                            </>
                        }
                        <Typography test-id="report-click-id" onClick={() => handleConfirmModalOpen(item.id)} className='report-text'>{t('Report')}</Typography>
                    </Box>
                }
            </>
        )
    }
    const MediaBox = (mediaArr: any) => {
        return (
            <>
                {mediaArr.length >= 5
                    && mediaArr.slice(0, 4).map((item: any, idx: number) => (
                        <MediaGrid mediaArr={mediaArr} items={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
                    ))}
                {mediaArr.length === 1 &&
                    mediaArr.map((item: any, idx: number) => (
                        <MediaWithoutGrid mediaArr={mediaArr} item={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
                    ))}
                {
                    mediaArr.length > 1 && mediaArr.length < 5 &&
                    mediaArr.map((item: any, idx: number) => (
                        <MediaWithGrid mediaArr={mediaArr} itemData={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
                    ))}
            </>
        )
    }
    const CommentInput = (items: any, item: any, index: number, idx: number) => {
        return (
            <div className='replied-box'>
                {items.attributes.reply_counts && <Typography test-id="repiled-click-id" className="time reply-btn" onClick={() => onRepliedHandler(index, idx)}>{items.attributes.reply_counts} {t('Replied')}</Typography>}
                <Typography test-id="reply-btn" className="time reply-btn" onClick={() => onReplyHandler(index, idx)}>{t('Reply')}</Typography>
                {
                    (items.attributes.account_id == authorId || item.attributes.author_id == authorId) &&
                    <Typography test-id="comment-dlt-btn" className="time reply-btn" onClick={() => onCommentDeleteHandler(items.attributes.id, item.id, index)}>{t('Delete')}</Typography>
                }
            </div>
        )
    }
    const CommentBox = (item: any, index: number) => {
        let commentData = item.attributes?.recent_comments?.data || item.attributes?.all_comments?.data
        return (
            <>
                {
                    commentData.slice(0, 2).map((items: any, idx: number) => (
                        <>
                            <Box className="recentPostBox">
                                <Avatar src={items.attributes.author_image} />
                                <div className="commentDetail">
                                    <div className="userNameComment">
                                        <Typography className="userText">{items.attributes.author_name}</Typography>
                                        <Typography className="tag">
                                            -
                                        </Typography>
                                        <Typography className="time">{items.attributes.created_at}</Typography>
                                    </div>
                                    <Typography className="time break word-break">
                                        {items.attributes.comment}
                                    </Typography>
                                    {CommentInput(items, item, index, idx)}
                                </div>
                            </Box>
                            {
                                !state.isCommentEnable && state.commentIndex === index && state.replyIndex === idx &&
                                <Box className="doComment">
                                    <Input
                                        className="inputField"
                                        test-id="comment"
                                        id="input-with-icon-adornment"
                                        placeholder={t("Comment here")}
                                        value={state.commentActiveIndex === index ? state.commentInput : ""}
                                        onClick={() => onClickReplyComment(index)}
                                        onChange={state.commentActiveIndex === index ? onCommentChange : undefined}
                                    />
                                    {state.commentActiveIndex === index ?
                                        <SendIcon
                                            test-id="send-id"
                                            style={{ cursor: "pointer", margin: "0 10px", fill: state.commentInput?.length > 0 && state.isCommentActive ? '#5497FF' : "#94A3B8" }}
                                            onClick={() => onCommentHandler(item.attributes.id, items.attributes.id, index)} /> :
                                        <SendIcon
                                            style={{ cursor: "pointer", margin: "0 10px", fill: "#94A3B8" }}
                                        />
                                    }
                                </Box>
                            }
                            {
                                state.commentIndex === index && state.isReplied && state.repiedIndex === idx &&
                                items.attributes.comments.data.length > 0 && items.attributes.comments.data.map((ele: any) => (
                                    <Box className="recentPostBox reply-loop-pd">
                                        <Avatar src={ele.attributes.author_image} />
                                        <div className="commentDetail">
                                            <div className="userNameComment">
                                                <Typography className="userText">{ele.attributes.author_name}</Typography>
                                                <Typography className="tag">
                                                    -
                                                </Typography>
                                                <Typography className="time">{ele.attributes.created_at}</Typography>
                                            </div>
                                            <Typography className="time break word-break">
                                                {ele.attributes.comment}
                                            </Typography>
                                            {
                                                (ele.attributes.account_id == authorId || item.attributes.author_id == authorId) &&
                                                <Typography test-id="comment-id-dlt" className="time reply-btn break word-break" onClick={() => onCommentDeleteHandler(ele.attributes.id, item.id, index)}>
                                                    {t('Delete')}
                                                </Typography>
                                            }
                                        </div>
                                    </Box>
                                ))
                            }
                        </>
                    ))
                }
            </>
        )
    }
    const RepliedComment = (item: any, index: number) => {
        return (
            <>
                {
                    state.isCommentEnable && index === state.commentIndex &&
                    <Box className="doComment">
                        <Input
                            className="inputField"
                            test-id="comment-2"
                            id="input-with-icon-adornment"
                            placeholder={t("Comment here")}
                            value={state.commentActiveIndex === index ? state.commentInput : ""}
                            onClick={() => onClickCommentIndex(index)}
                            onChange={state.commentActiveIndex === index ? onCommentChange : undefined}
                        />
                        {state.commentActiveIndex === index ?
                            <SendIcon
                                test-id="send-id-2"
                                style={{ cursor: "pointer", margin: "0 10px", fill: state.commentInput.length > 0 && state.isCommentActive ? '#5497FF' : "#94A3B8" }}
                                onClick={() => onCommentHandler(item.attributes.id, null, index)}
                            /> :
                            <SendIcon
                                style={{ cursor: "pointer", margin: "0 10px", fill: "#94A3B8" }}
                            />
                        }
                    </Box>
                }
            </>
        )
    }
    return (
        <>
            {state.postsData.map((item: any, index: number) => {
                let mediaArr = [...item.attributes.images, ...item.attributes.videos, ...item.attributes.audios, ...item.attributes.documents]
                let arr = mediaArr
                let filter = []
                filter = state.translationArray?.filter((i: any) => i.id == item.id).map((i: any) => i.data)
                return (
                    <>
                        <Box className="recentPostCard" key={item.id}>
                            <div className="recentPostBox">
                                <Avatar src={item.attributes.author_image} />
                                <div className="descriptionBox">
                                    <div className="desc">
                                        <Typography test-id="test-post-id" onClick={(e) => handlePostDetails(item.id)} className="descTitle">
                                            {item.attributes.author_name}
                                        </Typography>
                                        <div className="tag">{item.attributes.created_at}</div>
                                    </div>
                                    <Typography test-id="test-post-id-two" onClick={(e) => handlePostDetails(item.id)} className="descText">
                                        {
                                            item.attributes.description.length < 350 ?
                                                <FilterTranslateDesc filter={filter}  description={item.attributes.description} />
                                                :
                                                <>
                                                    {item.attributes.description.substring(0, 350) + "... "}
                                                    <a test-id="more-id" onClick={(e) => handlePostDetails(item.id)} className='seeMoreText'>{t('See more')}</a>
                                                </>
                                        }
                                    </Typography>
                                    {
                                        item.attributes.description?.length > 0 ?
                                            <TranslationCore item={item} filter={filter} handleTranslation={handleTranslation} handleTransBackToOriginal={handleTransBackToOriginal} />
                                            : null
                                    }
                                </div>
                                <div test-id="report-id" className='report-btn' onClick={() => onReportModalClickHandler(item.id)}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                                            fill="#64748B" />
                                    </svg>
                                </div>
                                {ReportMenu(arr, item, index)}
                            </div>
                            <div test-id="len-id" className='postImageContainer'>
                                <Grid container spacing={1} style={{ justifyContent: "center", padding: "0 20px" }}>
                                    {MediaBox(mediaArr)}
                                </Grid>
                            </div>

                            <div className="likesCard">
                                <div className="likesCommentsContainer">
                                    <Typography className="tag">
                                        {item.attributes.post_likes + " "}{item.attributes.post_likes < 2 ? t("Like") : t("Likes")}
                                    </Typography>
                                    <Typography className="tag">-</Typography>
                                    <Typography className="tag">
                                        {item.attributes.post_comments + " "}{item.attributes.post_comments < 2 ? t("Comment") : t("Comments")}
                                    </Typography>
                                </div>
                                <LikeButton
                                    isLike={item.attributes.current_user_liked}
                                    commentOnClick={() => commentBoxVisibilityHandler(index)}
                                    shareHandler={() => onShareInputHandler(item.id)}
                                    onClick={() => onLikeHandler(item.id, item.attributes.current_user_liked, item.attributes.current_user_liked_id, index)}
                                />
                                <Divider className="lineDivider" />
                            </div>
                            {CommentBox(item, index)}
                            {RepliedComment(item, index)}
                        </Box >
                    </>
                )
            })}
        </>
    )
}

export default AllPostDetails