import React from "react";
import {
    Grid,
    Box,
    Typography,
  } from "@material-ui/core";

export const MediaGrid = ({mediaArr, item, idx, onMediaModalHandler, playVideo, playAudio}:any) => {
    switch (item && item.type) {
      case 'image':
        return (
          <Grid data-test-id="case-image" item xs={6} key={idx} onClick={() => onMediaModalHandler(mediaArr)}>
            <Box className='postImageBox'>
              <img className='postImage' src={item.url} alt={`Image ${idx}`} />
              {idx === 3 && <Box className='overlay'>+ {mediaArr && mediaArr.length - 4}</Box>}
            </Box>
          </Grid>
        );
      case 'video':
        return (
          <Grid data-test-id="case-video" item xs={6} key={idx} onClick={() => onMediaModalHandler(mediaArr)}>
            <Box className='postImageBox'>
              <video data-test-id="play-video" controls className='postImage videoBox' onPlay={() => playVideo()}>
                <source src={item.url} type='video/mp4' />
              </video>
              {idx === 3 && <Box className='overlay'>+ {mediaArr && mediaArr.length - 4}</Box>}
            </Box>
          </Grid>
        );
      case 'audio':
        return (
          <Grid data-test-id="case-audio" item xs={6} key={idx} onClick={() => onMediaModalHandler(mediaArr)}>
            <Box className='postImageBox'>
              <audio data-test-id="play-audio" controls style={{ width: "100%" }} onPlay={() => playAudio()}>
                <source src={item.url} type="audio/mpeg" />
              </audio>
              {idx === 3 && <Box className='overlay'>+ {mediaArr && mediaArr.length - 4}</Box>}
            </Box>
          </Grid>
        )
      case 'application':
        return (
          <Grid data-test-id="case-application" item xs={6} key={idx} className='grid-layout-box' onClick={() => onMediaModalHandler(mediaArr)}>
            <Box className='postImageBox shadowBox'>
            <div className='doc-box'>
                <Box className='PostImageLeft'>
                  <svg
                    height="39"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 90.48 122.88"
                  >
                    <path
                      d="M35.27.78a3,3,0,0,1,2-.78,1.54,1.54,0,0,1,.47.05h46.2A6.59,6.59,0,0,1,88.56,2a6.52,6.52,0,0,1,1.92,4.64v109.7a6.57,6.57,0,0,1-6.56,6.56H6.67a6.57,6.57,0,0,1-6.56-6.56v-78A3.07,3.07,0,0,1,0,37.56a3.19,3.19,0,0,1,1-2.24L34.9,1a1.5,1.5,0,0,1,.26-.21ZM84.65,6.62a.5.5,0,0,0-.21-.47A.67.67,0,0,0,84,5.94H40.22V31.62a8.89,8.89,0,0,1-8.91,8.91H6.1v75.79a.58.58,0,0,0,.2.47.69.69,0,0,0,.47.21H84a.58.58,0,0,0,.47-.21.73.73,0,0,0,.21-.47V6.62Zm-62,94.73a2.64,2.64,0,0,1,0-5.28h28a2.64,2.64,0,0,1,0,5.28Zm0-17.75a2.64,2.64,0,0,1,0-5.28H65.46a2.64,2.64,0,0,1,0,5.28Zm0-17.76a2.64,2.64,0,0,1,0-5.28H72a2.64,2.64,0,0,1,0,5.28Zm11.7-34.22V10.11L10.11,34.64h21.2a3.16,3.16,0,0,0,2.13-.88,3.06,3.06,0,0,0,.89-2.14Z"
                    />
                  </svg>
                </Box>
                <Box className='PostImageRight'>
                  <Box>
                    <Typography className='documentName'>{item.filename?.substring(0, 20)}</Typography>
                  </Box>
                  <Box className='DownloadBox'>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="12"
                      width="12"
                      viewBox="0 0 122.88 110.9"
                      fill="#7b7b7b"
                    >
                      <path
                        d="M13.09,35.65h30.58V23.2l34.49,0v12.45l31.47,0L61.39,82.58L13.09,35.65L13.09,35.65z M61.44,97.88l47.51-0.14 l4.54-21.51l9.38,0.31v34.36L0,110.9V76.55l9.39-0.31l4.54,21.51L61.44,97.88L61.44,97.88L61.44,97.88z M43.67,0h34.49v4.62H43.67 V0L43.67,0z M43.67,9.32h34.49v9.44H43.67V9.32L43.67,9.32z"
                      />
                    </svg>
                  </Box>
                </Box>
            </div>
              {idx === 3 && <Box className='overlay'>+ {mediaArr && mediaArr.length - 4}</Box>}
            </Box>
          </Grid>
        )
      default:
        return null
    }
}
export const MediaWithoutGrid = ({mediaArr, item, idx, onMediaModalHandler, playVideo, playAudio}:any) => {
    return (
      <Grid data-test-id="grid1"  item key={idx} style={{ display: "flex", justifyContent: "center", width: "100%", height: "max-content", cursor: "pointer" }} 
       onClick={() => onMediaModalHandler(mediaArr)}>
            {item.type === "image" && <img className='postImages fitBox' src={item.url} />}
            {item.type === "video" &&
                <video data-test-id="play-video1" controls className='postImages fitBox' style={{ height: "550px" }} onPlay={() => playVideo()}>
                    <source src={item.url} type='video/mp4' />
                </video>
            }
            {item.type === "audio" &&
                <audio data-test-id="play-audio1" controls style={{ width: '100%' }} onPlay={() => playAudio()}>
                    <source src={item.url} type="audio/mpeg" />
                </audio>
            }
            {item.type === "application" &&
                <Box
                    style={{
                        width: "max-content",
                        cursor: "pointer",
                    }}
                    className='postImageBoxpdf'
                >
                    <div className='doc-box'>
                        <Box className='PostImageLefts'>
                            <svg
                                width="30"
                                xmlns="http://www.w3.org/2000/svg"
                                height="39"
                                viewBox="0 0 90.48 122.88"
                            >
                                <path d="M35.27.78a3,3,0,0,1,2-.78,1.54,1.54,0,0,1,.47.05h46.2A6.59,6.59,0,0,1,88.56,2a6.52,6.52,0,0,1,1.92,4.64v109.7a6.57,6.57,0,0,1-6.56,6.56H6.67a6.57,6.57,0,0,1-6.56-6.56v-78A3.07,3.07,0,0,1,0,37.56a3.19,3.19,0,0,1,1-2.24L34.9,1a1.5,1.5,0,0,1,.26-.21ZM84.65,6.62a.5.5,0,0,0-.21-.47A.67.67,0,0,0,84,5.94H40.22V31.62a8.89,8.89,0,0,1-8.91,8.91H6.1v75.79a.58.58,0,0,0,.2.47.69.69,0,0,0,.47.21H84a.58.58,0,0,0,.47-.21.73.73,0,0,0,.21-.47V6.62Zm-62,94.73a2.64,2.64,0,0,1,0-5.28h28a2.64,2.64,0,0,1,0,5.28Zm0-17.75a2.64,2.64,0,0,1,0-5.28H65.46a2.64,2.64,0,0,1,0,5.28Zm0-17.76a2.64,2.64,0,0,1,0-5.28H72a2.64,2.64,0,0,1,0,5.28Zm11.7-34.22V10.11L10.11,34.64h21.2a3.16,3.16,0,0,0,2.13-.88,3.06,3.06,0,0,0,.89-2.14Z" />
                            </svg>
                        </Box>
                        <Box className='PostImageRights'>
                            <Box>
                                <Typography className='documentNames'>
                                    {item.filename?.substring(0, 20)}
                                </Typography>
                            </Box>
                            <Box className='DownloadBoxs'>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    fill="#7b7b7b"
                                    viewBox="0 0 122.88 110.9"
                                >
                                    <path d="M13.09,35.65h30.58V23.2l34.49,0v12.45l31.47,0L61.39,82.58L13.09,35.65L13.09,35.65z M61.44,97.88l47.51-0.14 l4.54-21.51l9.38,0.31v34.36L0,110.9V76.55l9.39-0.31l4.54,21.51L61.44,97.88L61.44,97.88L61.44,97.88z M43.67,0h34.49v4.62H43.67 V0L43.67,0z M43.67,9.32h34.49v9.44H43.67V9.32L43.67,9.32z" />
                                </svg>
                            </Box>
                        </Box>
                    </div>
                </Box>
            }
        </Grid>
    )
}
export const MediaWithGrid = ({mediaArr, item, idx, onMediaModalHandler, playVideo, playAudio}:any) => {
  switch (item?.type) {
    case 'image':
        return (
            <Grid data-test-id="case-image1" item xs={6} key={idx} onClick={() => onMediaModalHandler(mediaArr)}>
                <Box className='postImageBoxss'>
                    <img className='postImagess' src={item.url} alt={`Image ${idx}`} />
                </Box>
            </Grid>
        );
    case 'video':
        return (
            <Grid data-test-id="case-video1" item xs={6} key={idx} onClick={() => onMediaModalHandler(mediaArr)}>
                <Box className='postImageBoxss'>
                    <video data-test-id="play-video2" controls className='postImagess videoBoxs' onPlay={() => playVideo()}>
                        <source src={item.url} type='video/mp4' />
                    </video>
                </Box>
            </Grid>
        );
    case 'audio':
        return (
            <Grid data-test-id="case-audio1" item xs={6} key={idx} onClick={() => onMediaModalHandler(mediaArr)}>
                <Box className='postImageBoxss'>
                    <audio data-test-id="play-audio2" controls style={{ width: "100%" }} onPlay={() => playAudio()}>
                        <source src={item.url} type="audio/mpeg" />
                    </audio>
                </Box>
            </Grid>
        )
    case 'application':
        return (
            <Grid data-test-id="case-application1" item xs={6} key={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
             className='grid-layout-boxs' onClick={() => onMediaModalHandler(mediaArr)}>
                <Box className='postImageBoxss' style={{ height: "max-content", cursor: "pointer" }}>
                    <div className='doc-box'>
                        <Box className='PostImageLeftss'>
                            <svg
                                height="39"
                                width="30"
                                viewBox="0 0 90.48 122.88"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M35.27.78a3,3,0,0,1,2-.78,1.54,1.54,0,0,1,.47.05h46.2A6.59,6.59,0,0,1,88.56,2a6.52,6.52,0,0,1,1.92,4.64v109.7a6.57,6.57,0,0,1-6.56,6.56H6.67a6.57,6.57,0,0,1-6.56-6.56v-78A3.07,3.07,0,0,1,0,37.56a3.19,3.19,0,0,1,1-2.24L34.9,1a1.5,1.5,0,0,1,.26-.21ZM84.65,6.62a.5.5,0,0,0-.21-.47A.67.67,0,0,0,84,5.94H40.22V31.62a8.89,8.89,0,0,1-8.91,8.91H6.1v75.79a.58.58,0,0,0,.2.47.69.69,0,0,0,.47.21H84a.58.58,0,0,0,.47-.21.73.73,0,0,0,.21-.47V6.62Zm-62,94.73a2.64,2.64,0,0,1,0-5.28h28a2.64,2.64,0,0,1,0,5.28Zm0-17.75a2.64,2.64,0,0,1,0-5.28H65.46a2.64,2.64,0,0,1,0,5.28Zm0-17.76a2.64,2.64,0,0,1,0-5.28H72a2.64,2.64,0,0,1,0,5.28Zm11.7-34.22V10.11L10.11,34.64h21.2a3.16,3.16,0,0,0,2.13-.88,3.06,3.06,0,0,0,.89-2.14Z" />
                            </svg>
                        </Box>
                        <Box className='PostImageRightss'>
                            <Box>
                                <Typography className='documentNamess'>{item.filename?.substring(0, 20)}</Typography>
                            </Box>
                            <Box className='DownloadBoxss'>
                                <svg
                                    height="12"
                                    width="12"
                                    viewBox="0 0 122.88 110.9"
                                    fill="#7b7b7b"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.09,35.65h30.58V23.2l34.49,0v12.45l31.47,0L61.39,82.58L13.09,35.65L13.09,35.65z M61.44,97.88l47.51-0.14 l4.54-21.51l9.38,0.31v34.36L0,110.9V76.55l9.39-0.31l4.54,21.51L61.44,97.88L61.44,97.88L61.44,97.88z M43.67,0h34.49v4.62H43.67 V0L43.67,0z M43.67,9.32h34.49v9.44H43.67V9.32L43.67,9.32z" />
                                </svg>
                            </Box>
                        </Box>
                    </div>
                </Box>
            </Grid>
        )
    default:
        return null
}
}