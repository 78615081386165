import React from "react";
import { Box } from '@material-ui/core';
import { GroupMediaTabs } from './groupMediaTabs.web';

interface UserMediaProps {
  handleEndPoint: any;
  userMedia: any;
}
const UserMedia = (props: UserMediaProps) => {
  return (
    <>
      <Box className="mediaPostss">
        <Box className="recentPostsCardss">
          <GroupMediaTabs groupMediaData={props.userMedia} handleEndPoint={props.handleEndPoint} groupId={1} handlePostDetails={[]} />
        </Box>
      </Box>
    </>
  );
};

export default UserMedia;
