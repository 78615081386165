import React, { Component } from 'react';
// Customizable Area Start
import Pagination from '@material-ui/lab/Pagination';
interface Props {
    page: number,
    totalCount: number,
    handlePagination?: any

 }



class PaginationBlock extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }
  

    render() {
        //const { totalPageCount , handlePageClick, page} = this.props;
        const page = 0;
        
        return (
            <>
                {/* Merge Engine UI Engine Code */}
                <div style={{display: 'flex',justifyContent: 'center',margin: '16px'}}>
                    <Pagination color="primary" size="large" defaultPage={6} boundaryCount={2} count={this.props.totalCount} page={this.props.page} onChange={this.props.handlePagination} />
                </div>
     
                {/* Merge Engine UI Engine Code */}
            </>
        );
    }
}

// Customizable Area End

export default PaginationBlock;

  // Customizable Area Start
  // Customizable Area End