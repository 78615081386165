import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react"
import { Value } from "react-calendar/dist/cjs/shared/types";

export interface Item{
  itemId: number,
  item: string,
  cancelEditItem: string,
  content_type: string,
  created_date: string
  pinned: boolean,
  editing: boolean,
  itemToDelete: null | number
  error:string
}
interface Data{
    id: number,
    type: string,
    attributes: {
        content_type: string,
        item: string,
        created_date: string
    }
}
interface ResponseJSON{
   data: Data 
}
interface ResponseJSONArray{
  data: Data[] 
}
interface ResponseJSONDelete{
  message: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  item: string,
  date: Value,
  isOpen: boolean,
  loading: boolean,
  items: Item[],
  pinnedItems: Item[],
  error:string,
  index:number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BulletinBoard2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createItemApiCallId: string = '';
  getItemsAtDateApiCallId: string = '';
  editItemApiCallId: string = '';
  deleteItemApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage)
      // Customizable Area End
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      item: '',
      date: new Date(),
      isOpen: false,
      loading: false,
      items:[],
      pinnedItems:[],
      error:'',
      index:0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
        const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
       if (apiRequestCallId === this.createItemApiCallId) {
         this.recieveCreateItemResponse(responseJson)
        } else if(apiRequestCallId === this.editItemApiCallId){
          this.recieveEditItemResponse(responseJson)
        } else if(apiRequestCallId === this.deleteItemApiCallId){
          this.recieveDeleteItemResponse(responseJson)
        } else if(apiRequestCallId === this.getItemsAtDateApiCallId){
          this.recieveGetItemsAtDateResponse(responseJson)
        }
}
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount(){
    this.getItemsAtDate()
  }

  handleChange = (value: string) => {
    this.setState({ error: "" });
    this.setState({ item: value });
  };

  handleEditChangeForUnpinned = (value: string, itemId: number) => {
    const { items } = this.state;
    const updatedItems = items.map((item: Item) => {
      if (item.itemId === itemId) {
        return { ...item, item: value, error: "" };
      }
      return item;
    })
    this.setState({
      items: updatedItems
    });
  };

  handleSubmitItem = () => {
    let {item} = this.state
    if (item?.length === 0 || item?.length === 11) {
      return this.setState({ error: "Required*" });
    }
    this.handleSubmitTextOrImage(item)
   };

  handleSubmitTextOrImage = async (item: string | File) => {
    let formData = new FormData();
      if(typeof item === 'string'){
         formData.append('item', item);
         formData.append('content_type', "text");
      } else{
        formData.append('image', item);
        formData.append('content_type', "image");
      }
        const header = { 
        'ngrok-skip-browser-warning':'asf'
        }
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createItemApiCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

getItemsAtDate = () => {
  const date = this.state.date as Date;
  const formattedDate = date?.toISOString()?.split('T')[0];
  const header = {
    'Content-Type': configJSON.bulletinBoardContentType,
    'ngrok-skip-browser-warning':'asf'
};
const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
);
this.getItemsAtDateApiCallId = requestMessage.messageId;
requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getItemsAtDateEndPoint}?date=${formattedDate}`
);
requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
);
requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethodType
);
runEngine.sendMessage(requestMessage.id, requestMessage);
 return true;
}

recieveCreateItemResponse = (responseJson: ResponseJSON) => {
  this.setState({error: ""})
  const {items} = this.state
  if (responseJson && responseJson.data) {
    const {item, content_type, created_date} = responseJson.data.attributes
    this.setState({items: [...items,  {
                itemId: responseJson.data.id,
                item,
                content_type,
                created_date,
                pinned: false,
                editing: false,
                itemToDelete: null,
                cancelEditItem:'',
                error:''
              }]})
    this.setState({ item: '' });
  }else{
    this.setState({error: 'Something went wrong'})
  }
}

recieveGetItemsAtDateResponse = (responseJson: ResponseJSONArray) => {
  this.setState({pinnedItems: []})
  this.setState({error: ""})
  this.setState({loading: true})
  if (responseJson && responseJson.data) {
    const updatedItems = responseJson.data.map((item: Data) => {
   return {
      itemId: item.id,
      item: item.attributes.item,
      content_type: item.attributes.content_type,
      created_date: item.attributes.created_date,
      pinned: false,
      editing: false,
      itemToDelete: null,
      cancelEditItem:'',
      error:''
    }
    })
    this.setState({loading: false})
    this.setState({items: updatedItems})
  } else{
    this.setState({loading: false})
    this.setState({error: "Something went wrong"})
  }
}

  handlePinToggle = (item: Item) => {
    const { pinnedItems, items } = this.state;
    this.setState({ index: items.indexOf(item) });
    this.setState({ items: items.filter((itemData: Item) => itemData.itemId !== item.itemId) });
    this.setState({
      pinnedItems: [{ ...item, pinned: !item.pinned }, ...pinnedItems],
    });
  };

  handleUnpinToggle = (item: Item) => {
    const { pinnedItems, items, index } = this.state;
    this.setState({
      pinnedItems: pinnedItems.filter((pinnedItem: Item) => pinnedItem.itemId !== item.itemId),
    });
    items.splice(index,0,item);
    this.setState({
      items,
    });
  };

  handleEditForUnpinned = (item: Item) => {
    const { items } = this.state;
    if (item.item.length === 0 || item.item.length === 11) {
      return this.setState({
        items: items.map((itemData: Item) => {
          if (itemData.itemId === item.itemId) {
            return { ...itemData, error: "Required*" };
          }
          return itemData;
        }),
      });
    }
    this.editItem(item.item, item.itemId)
  };

  editItem = (itemValue: string, itemId: number) => {
    const formData = new FormData();
       formData.append('item', itemValue);
      formData.append('content_type', 'text');
  
  const header = {
      'ngrok-skip-browser-warning':'asf'
  };
  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.editItemApiCallId = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.apiEndPoint}/${itemId}`
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  recieveEditItemResponse = (responseJson: ResponseJSON) => {
    this.setState({error: ""})
    const {items} = this.state
    if (responseJson) {
      const {id} = responseJson.data
      const {item} = responseJson.data.attributes
      const updatedItems = items.map((itemData: Item) => {
      if (itemData.itemId === id) {
        return { ...itemData, item: item, editing: !itemData.editing };
      }
      return itemData;
    });
    this.setState({ items: updatedItems });
    } else{
      this.setState({error: "Something went wrong"})
    }
  }

  handleEditToggleForUnpinned = (itemId: number) => {
    const { items } = this.state;
    const updatedItems = items.map((item: Item) => {
      if (item.itemId === itemId) {
        return { ...item, item: item.item, cancelEditItem: item.item, editing: !item.editing };
      }
      return item;
    });
    this.setState({ items: updatedItems });
  };

  handleCancelEditForUnpinned = (itemId: number) => {
    const { items } = this.state;
    const updatedItems = items.map((item: Item) => {
      if (item.itemId === itemId) {
        return { ...item, item: item.cancelEditItem, editing: !item.editing, error: '' };
      }
      return item;
    });
    this.setState({ items: updatedItems });
  };

  handleCancelDeleteModalForUnpinned = (itemId: number) => {
    const { items } = this.state;
    const updatedItems = items.map((item: Item) => {
      if (item.itemId === itemId) {
        return { ...item, itemToDelete: null };
      }
      return item;
    });
    this.setState({ items: updatedItems });
  };

  handleConfirmDeleteItemForUnpinned = (itemId: number) => {
    this.deleteItem(itemId);
  };

  deleteItem = (itemId: number) => {
  const header = {
      'Content-Type': configJSON.bulletinBoardContentType,
  };
  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.deleteItemApiCallId = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.apiEndPoint}/${itemId}`
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  recieveDeleteItemResponse = (responseJson: ResponseJSONDelete) => {
    this.setState({error: ""})
    const {items} = this.state
   if (responseJson) {
    const updatedItems = items.filter(
      (item: Item) => item.itemToDelete === null
    );
    this.setState({ items: updatedItems });
    } else{
      this.setState({error:'Something went wrong'})
    }
  }

  handleDeleteItemForUnpinned = (itemId: number) => {
    this.setState({ item: ''});
    const { items } = this.state;
    const updatedItems = items.map((item: Item) => {
      if (item.itemId === itemId) {
        return { ...item, itemToDelete: itemId };
      }
      return item;
    });
    this.setState({ items: updatedItems });
  };

  handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files![0]
    if (file.type === "image/jpg" || file.type === "image/png" || file.type === "image/jpeg") {
      this.handleSubmitTextOrImage(file)
    }
  };

  toggleCalendar = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  };

   onChange = (date: Value) => {
    this.setState({
      date,
      isOpen: false
    }, () => {
      this.getItemsAtDate()
    });
  };
   // Customizable Area End
}
