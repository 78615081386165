import React from 'react';
import {t} from 'i18next';
import { Container, Box, Typography } from "@material-ui/core";

export const NoSearchFound = () => {
    return (
        <>
            <Container maxWidth={"xl"} className='no-found-container'>
                <Box className='no-found-box'>
                    <Box className='upper-img'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="131" height="132" viewBox="0 0 131 132" fill="none">
                            <rect x="100.923" y="100.923" width="100.923" height="100.923" transform="rotate(-180 100.923 100.923)" fill="#5497FF" />
                            <rect x="131" y="131.399" width="100.923" height="100.923" transform="rotate(-180 131 131.399)" fill="white" />
                        </svg>
                    </Box>
                    <Box className='lower-img'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="135" height="133" viewBox="0 0 135 133" fill="none">
                            <rect x="37.3076" y="35.3076" width="97.6923" height="97.6923" fill="#5497FF" />
                            <g filter="url(#filter0_d_351_47533)">
                                <rect x="8" y="6" width="97.6923" height="97.6923" fill="white" />
                            </g>
                            <defs>
                                <filter id="filter0_d_351_47533" x="0" y="0" width="113.692" height="113.692" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="2" />
                                    <feGaussianBlur stdDeviation="4" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_351_47533" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_351_47533" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </Box>
                    <Box className='center-box'>
                        <Box className='center-img'>
                            <img src={require('../../assets/image_Searching.png')} />
                            <img src={require('../../assets/image3.png')} className="img-2-search" />
                        </Box>
                        <div className='no-found-text'>
                            <Box>
                                <Typography className='no-search-text-one'>{t("No Search Result Found")}</Typography>
                            </Box>
                            <Box>
                                <Typography className='likesText'>{t("Sorry we couldn't find what you are looking for. Please refine your search terms and try again. We apologize for any inconvenience caused.")}</Typography>
                            </Box>
                        </div>
                    </Box>
                </Box>
            </Container>
        </>
    )
}