import React from "react";
import {
  Box,
  Typography,
  Divider,
  Container,
  Grid,
  Snackbar
} from "@material-ui/core";
import { arrowBack } from '../assets';
import { GroupsData } from "./groupsData.web"
import MuiAlert from "@material-ui/lab/Alert";

import ViewAllGroupsController, {
  Props,
} from "../ViewAllGroupsController.web";
import Header from "../../../../components/src/Header.web";
import { RecentGroups } from "./recentGroups.web";
import RightBar from "../../../../components/src/RightBar.web";
import { ResponsiveRecentGroups } from "./responsiveRecentGroups.web";
import { t } from "i18next";

export default class ViewAllGroups extends ViewAllGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <>
        <Header type={"Groups"} onSearch={this.onSearchGroupChangeHandler} navigation={this.props.navigation} profileImage={this.state.userInfo.profileImage}
          notifyCount={Number(localStorage.getItem("notification_unread_count"))}
        />
        <div style={{ backgroundColor: '#f5f5ff' }}>
          <Container className="mainContainer" maxWidth="lg" style={{ maxWidth: '1600px', }}>
            <Grid container spacing={2} className='gridContainers-viewAllGroups'>
              <Grid item xs={3} md={3} className="mobileMenuHide">
                <RecentGroups navigation={""} id='2' handleOpen={this.handleOpen} recentGroups={this.state.recentGroups} handleJoinGroup={this.handleJoinGroup}
                  getGroupDetails={this.getGroupDetails} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="mediaPost">
                  <Box className="recentPostsCards">
                    <div style={{ display: 'flex', gap: "8px" }}>
                      <img data-test-id="handleOpen2" style={{ cursor: "pointer" }} src={arrowBack} onClick={this.handleBack}/>
                      <Typography style={styles.groupName} className="">{t("View all Groups")}</Typography>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "space-between", marginTop: "8px" }}>
                    </div>
                    <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
                    <GroupsData groups={this.state.viewAllgroupsData} handleJoinGroup={this.handleJoinGroup} getGroupDetails={this.getGroupDetails} />
                  </Box>
                </Box>
                <div style={{ marginTop: "16px" }}>
                  <ResponsiveRecentGroups navigation={""} id={""} handleOpen={this.handleOpen} recentGroups={this.state.recentGroups}
                    handleJoinGroup={this.handleJoinGroup} getGroupDetails={this.getGroupDetails} />
                </div>
              </Grid>
              <Grid item xs={12} md={3} className="trendingPost"><RightBar handlePostDetails={this.props.handlePostDetails} topTrendingData={this.state.topTrendingData} /></Grid>
            </Grid>
          </Container>
          <Snackbar
            style={{ zIndex: 9999 }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={this.state.showSnackbar}
            autoHideDuration={5000}
            onClose={this.handleCloseSnackbar}
          >
            <MuiAlert
              onClose={this.handleCloseSnackbar}
              severity={this.state.severity}
            >
              {this.state.message}
            </MuiAlert>
          </Snackbar>
        </div>
      </>
    )
  }
}

const styles = {
  groupName: {
    color: "var(--neutrals-cool-gray-600, #475569)",
    fontFamily: "Comfortaa-Bold",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
  },
}