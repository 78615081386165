import React from "react";
// Customizable Area Start
import EditIconOutlined from '@material-ui/icons/EditOutlined';
import { Grid, Container, Typography, Avatar, IconButton, TableContainer, Modal, Button, Paper, Box } from '@material-ui/core';
import { Rectangle5, user4, camera, gallery, download, tickmark } from "./assets"
import "../assets/userprofilebasic.scss";
import UserProfileBasicController, { Props, SideBarScreenNames } from "./UserProfileBasicController";
import SideBar from "../../../components/src/SideBar.web";
import RightBar from "../../../components/src/RightBar.web";
import WebHeader from "../../../components/src/WebHeader.web";
import UserAboutMe from "./components/UserAboutMe.web";
import UserPost from "./components/UserPost.web";
import UserGroups from "./components/UserGroups.web";
import RecentActivities from "./components/RecentActivities.web";
import UserMedia from "./components/Media.web";
import { ImageSelectInputWithIcon } from "./components/ImageSelectBox";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Header from "../../../components/src/Header.web";
import ConfirmationBox from "../../../components/src/ConfirmationBox.web";
import {t} from "i18next";
import MediaSlider from "../../CommunityForum/src/components/MediaSlider.web";
// Customizable Area End

export default class UserProfileBasicBlock extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    onSideBarNavigateScreen = (screen: string) => {
        this.onSideBarNavigate(screen)
    }
    onCoverModalOpen = () => {
        this.showModal(); this.setCoverModal()
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            isKabab,
            myGroups,
            userData,
            userMedia,
            userMyPosts,
            joinedGroups,
            trendingPosts,
            profileSideBar,
            confirmModalOpen,
            userPhotoGallery,
            userRecentActivities,
        } = this.state;
        // Customizable Area End

        return (
            <>
                {this.state.isModalMedia && <MediaSlider item={this.state.modalMediaData} onClose={this.onMediaModalCloseHandler} allDownloadHandler={this.allDownloadHandler} />}

                <div onClick={this.onKababCloseHandler}>
                    <main>
                        <Header type={""}  navigation={this.props.navigation} profileImage={userData?.attributes?.photo} />
                        <div
                            className="user-profile-conatiner"
                        >
                            <Container className="continer-profile-padd" maxWidth="lg" style={{
                                padding: '10px 35px !important',
                                maxWidth: '1630px',
                            }}
                            >
                                <Grid container>
                                    <Grid item xs={12} lg={12} sm={12} md={12}>
                                        <div className="banner-section"
                                        >
                                            <img
                                                src={(userData.attributes && userData.attributes.cover_picture) || Rectangle5}
                                                alt="Banner"
                                                className="banner-image"
                                            />
                                            <div className="myDIV" >
                                                <IconButton className="edit-icon-button" aria-label="Edit Profile"
                                                    onClick={this.onCoverModalOpen}
                                                >
                                                    <EditIconOutlined className="edit-profile" />
                                                </IconButton>
                                            </div>
                                            <div className="hover-edit-cover hide">
                                                <IconButton
                                                    onClick={this.onCoverModalOpen}
                                                    aria-label={'Upload Cover Photo'}
                                                    style={{ marginRight: '0px', width: "16px", height: "16px", padding: "0px" }}
                                                >
                                                    <img className="profile-photo" src={download} />
                                                </IconButton>
                                                <Typography
                                                    className="upload-text-profile edit-button-text " variant="body2"
                                                >
                                                    {t('Upload Cover Photo')}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="profile-details">
                                            <div className="mobile-view">
                                                <Avatar
                                                    src={(!!userData.attributes && userData.attributes.photo) || user4}
                                                    alt="Profile Picture"
                                                    style={{
                                                        marginTop: "20px",
                                                        width: 120,
                                                        height: 120,
                                                    }}
                                                />
                                            </div>
                                            <div className="header-section">
                                                <Avatar
                                                    src={(!!userData.attributes && userData.attributes.photo) || user4}
                                                    alt="Profile Picture"
                                                    style={{
                                                        width: 120,
                                                        height: 120,
                                                    }}
                                                />
                                            </div>
                                            <div className="myDIV">
                                                <IconButton
                                                    aria-label="Edit Profile"
                                                    onClick={this.showModal}
                                                    style={{
                                                        zIndex: 100,
                                                        borderRadius: '20px',
                                                        height: '24px',
                                                        backgroundColor: '#fff',
                                                        width: '24px',
                                                        marginLeft: '90px',
                                                        marginTop: '-35px',
                                                    }}
                                                >
                                                    <EditIconOutlined className="edit-profile" />
                                                </IconButton>
                                            </div>
                                            <div className="hide">
                                                <div className="mobile-view">
                                                    <ImageSelectInputWithIcon showModal={this.showModal} />
                                                </div>
                                                <div className="header-section">
                                                    <ImageSelectInputWithIcon showModal={this.showModal} />
                                                </div>
                                            </div>
                                            <Typography variant="h1" align="center"
                                                style={{

                                                    fontFamily: 'Comfortaa-Bold', fontWeight: 700, marginTop: '20px',
                                                    fontSize: '20px', lineHeight: '28px', color: '#475569',
                                                }}>
                                                {!!userData.attributes && userData.attributes.full_name}
                                            </Typography>
                                            <Typography onClick={() => this.props.navigation.navigate("Settings5")} variant="h6"
                                                style={{
                                                    marginLeft: '45px', alignItems: 'center',
                                                    lineHeight: '28px',
                                                    marginTop: '0px',
                                                    fontFamily: 'Nunito-Regular',
                                                    fontSize: '14px', color: '#475569',
                                                    fontWeight: 700,
                                                    cursor: "pointer"
                                                }}>
                                                {t('Edit Profile')}
                                                <IconButton style={{
                                                    marginTop: '-5px',
                                                    marginLeft: '-5px', marginRight: '17px',
                                                }} data-test-id="editProfileIcon" aria-label="Edit Profile" onClick={() => this.props.navigation.navigate("Settings5")}>
                                                    <EditIconOutlined className="edit-profile-grey" fontSize="small" />
                                                </IconButton>
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} style={{ marginTop: '20px' }}>
                                    <Grid item xs={12} md={3} lg={3} xl={3} sm={12} >
                                        <SideBar navigation={this.props.navigation} onSideBarNavigate={this.onSideBarNavigateScreen} id={profileSideBar} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6} md={6} xl={6}  >
                                        {this.state.profileSideBar === SideBarScreenNames.aboutMe && <TableContainer component={Paper} style={{ borderRadius: '8px' }}>
                                            <Paper elevation={3} className="main_section_about_me" >
                                                <UserAboutMe user={userData.attributes || {}} />
                                            </Paper>
                                        </TableContainer>}
                                        {this.state.profileSideBar === SideBarScreenNames.groups &&
                                            <UserGroups
                                                user={userData}
                                                isKabab={isKabab}
                                                myGroups={myGroups}
                                                userId={this.state.userId}
                                                isMenu={this.state.isMenu}
                                                joinedGroups={joinedGroups}
                                                members={this.state.members}
                                                isMember={this.state.isMember}
                                                groupName={this.state.groupName}
                                                navigation={this.props.navigation}
                                                currentIndex={this.state.currentIndex}
                                                groupMembers={this.state.groupMembers}
                                                membersIndex={this.state.membersIndex}
                                                handleCreateGroupRedirection={this.handleCreateGroupRedirection}
                                                getGroupMembersDetail={this.getGroupMembersDetail}
                                                handleRemoveMemberApi={this.handleRemoveMemberApi}
                                                onMemberViewHandler={this.onMemberViewHandler}
                                                onMoreClickHandler={this.onMoreClickHandler}
                                                handleMemberReport={this.handleMemberReport}
                                                handleMoreMember={this.handleMoreMember}
                                                handleMakeMember={this.handleMakeMember}
                                                onLeaveHandler={this.onLeaveHandler}
                                            />
                                        }
                                        {this.state.profileSideBar === SideBarScreenNames.postAboutMe && <UserPost posts={userMyPosts} commentInput={this.state.usercommentInput}
                                            onCommentChange={this.onCommentChange} onCommentHandler={this.onUserCommentHandler} onLikeHandler={this.onLikeHandler} commentBoxVisibilityHandler={this.commentBoxVisibilityHandler}
                                            isCommentEnable={this.state.isCommentEnable} commentIndex={this.state.commentIndex} replyIndex={this.state.replyIndex} commentActiveIndex={this.state.commentActiveIndex} isReplied={this.state.isReplied} repiedIndex={this.state.repiedIndex}
                                            isCommentActive={this.state.isCommentActive} isReportModal={this.state.isReportModal} reportIndex={this.state.reportIndex}
                                            onRepliedHandler={this.onRepliedHandler} onReplyHandler={this.onReplyHandler} onClickReplyComment={this.onClickReplyComment} onClickCommentIndex={this.onClickCommentIndex}
                                            onReportModalClickHandler={this.onReportModalClickHandler} allDownloadHandler={this.allDownloadHandler} handleConfirmModalOpen={this.handleConfirmModalOpen}
                                            onMediaModalHandler={this.onMediaModalHandler} playVideo={this.playVideo} playAudio={this.playAudio} onShareInputHandler={this.onShareInputHandler} />}
                                        {this.state.profileSideBar === SideBarScreenNames.media && <UserMedia userMedia={userMedia} handleEndPoint={this.handleEndPoint} />}
                                        {this.state.profileSideBar === SideBarScreenNames.recentActivities && <RecentActivities list={userRecentActivities} commentInput={this.state.usercommentInput}
                                            onCommentChange={this.onCommentChange} onCommentHandler={this.onUserCommentHandler} onLikeHandler={this.onLikeHandler} commentBoxVisibilityHandler={this.commentBoxVisibilityHandler}
                                            isCommentEnable={this.state.isCommentEnable} commentIndex={this.state.commentIndex} replyIndex={this.state.replyIndex} commentActiveIndex={this.state.commentActiveIndex} isReplied={this.state.isReplied} repiedIndex={this.state.repiedIndex}
                                            isCommentActive={this.state.isCommentActive} isReportModal={this.state.isReportModal} reportIndex={this.state.reportIndex}
                                            onRepliedHandler={this.onRepliedHandler} onReplyHandler={this.onReplyHandler} onClickReplyComment={this.onClickReplyComment} onClickCommentIndex={this.onClickCommentIndex}
                                            onReportModalClickHandler={this.onReportModalClickHandler} allDownloadHandler={this.allDownloadHandler} handleConfirmModalOpen={this.handleConfirmModalOpen}
                                            onMediaModalHandler={this.onMediaModalHandler} playVideo={this.playVideo} playAudio={this.playAudio} onShareInputHandler={this.onShareInputHandler} />}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <RightBar navigation={this.props.navigation} handlePostDetails={this.onTrendingPostRedirection} id={""} topTrendingData={trendingPosts} />
                                    </Grid>
                                </Grid>
                            </Container>
                        </div>
                    </main>
                </div>
                <Modal open={this.state.isVisibleModal} onClose={this.hideModal}>
                    <div className="modalStyle">
                        <div className="header"><Typography variant="h6">{t('Upload photo')}</Typography> </div>
                        <div className="boxwraper">
                            <div className="uploadABox" onClick={this.showGalleryModal}>
                                <img
                                    alt="Banner"
                                    src={gallery}
                                />
                                <Typography variant="h6">{t('Upload Photo from Gallery')}</Typography></div>
                            <div className="or-box">{t('Or')}</div>
                            <div className="uploadABox">
                                <img
                                    alt="Banner"
                                    src={camera}
                                />
                                <input
                                    className="input-hide-image"
                                    data-test-id="fileInput"
                                    type="file"
                                    accept="image/*"
                                    onChange={this.handleImageSelect}
                                />
                                <Typography variant="h6">{t('Upload Photo from Device')}</Typography></div>
                        </div>
                    </div>
                </Modal>
                <Modal open={this.state.isGalleryModelVisible} onClose={this.hideGalleryModal}>
                    <div className="secondmodalStyle">
                        <div style={{ position: "relative", width: "100%" }}>
                            <div className="header"><Typography variant="h6">{t('Upload photo')}</Typography> </div>
                            <div className="galleryModel">
                                <div className="secondboxwraper">
                                    {userPhotoGallery.map(item => <Box
                                        data-test-id="select-item"
                                        key={item.id}
                                        className={`gallery-image ${this.state.userSelectedImage === item.attributes.id && "selected-image"}`}
                                        onClick={() => this.handleGallerySelect(item.attributes.id)}
                                    >
                                        <img src={tickmark} alt="tickamark" className="tickamark" />
                                        <img
                                            src={item.attributes.photo}
                                            alt="Banner"
                                        />
                                    </Box>
                                    )}
                                </div>
                            </div>
                            <div className="upload-button upload-footer">
                                <Button
                                    data-test-id="submitBtnupload"
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{
                                        textTransform: "none",
                                        height: "44px",
                                        width: "120px",
                                        padding: "10px 32px 10px 32px",
                                        fontFamily: "Inter",
                                        fontWeight: 700,
                                        fontSize: "16px",
                                    }}
                                    onClick={() => this.handleSubmitImage()}>
                                    {t('Upload')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <ConfirmationBox
                    open={confirmModalOpen}
                    handleClose={this.handleConfirmModalClose}
                    handleSubmit={this.handleConfirmModalSubmit}
                />
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    autoHideDuration={5000}
                    onClose={this.handleCloseSnackbar}
                    open={this.state.showSnackbar}
                >
                    <MuiAlert
                        severity={this.state.severity}
                        onClose={this.handleCloseSnackbar}
                    >
                        {this.state.message}
                    </MuiAlert>
                </Snackbar>
            </>
        );
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

}
// Customizable Area Start
// Customizable Area End
