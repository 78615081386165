import React, { Component } from 'react';
// Customizable Area Start
import {
    Grid,
    Container,
    Box,
    AppBar,
    Toolbar,
    Typography,
} from '@material-ui/core';

import './SideBar.scss';
interface Props { }

interface State {
    open: boolean;
}

class WebSubHeader extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            open: false,
        };
    }



    render() {
        const { open } = this.state;
        const currentUrl = window.location.pathname;
        const mobileContent = (

            <Box style={{ display: 'flex', width: '100%', backgroundColor: '#5497FF', justifyContent: 'center' }}>
                <AppBar position="relative">
                    <Toolbar style={{ padding: '25px 0px !important', maxWidth: 'lg' }}>
                        <Box style={{ display: 'flex', width: '100%', padding: '15px 0px' }}>
                            <Container maxWidth="lg" style={{ maxWidth: '1600px' }}>
                                <Grid container alignItems="center" >
                                    <Grid item xs={4} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                        <img src={require('./button.png')} alt="" style={{ maxWidth: '100px', marginRight: '10px' }} />
                                    </Grid>
                                    <Grid item xs={8} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                        <Typography variant="h4" align="center" gutterBottom style={{ color: 'white', fontFamily: 'Comfortaa-bold', fontWeight: 700, fontSize: '14px', lineHeight: '22px' }}>
                                            {currentUrl === "/TermsConditionWeb" ? (
                                               'Terms of use'
                                            ) : currentUrl === "/PrivacyPolicyWeb" ? (
                                               ' Privacy Policy'
                                            ) : null}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
        );

        const browserContent = (
            <Box style={{ display: 'flex', width: '100%', backgroundColor: '#5497FF' }}>
                <AppBar position="relative">
                    <Toolbar style={{ padding: '25px 0px !important', maxWidth: 'lg' }}>
                        <Box style={{ display: 'flex', width: '100%', padding: '15px 0px', }}>
                            <Container maxWidth="lg" style={{ maxWidth: '1600px', }}>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <div className="logo-item">
                                            <img src={require('./image_Teams2.png')} alt="" className="logo-image" />
                                            <Typography
                                                className='logo_Text'>
                                                Connecting Collaborative Changemakers
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
        );

        return (
            <>
                {/* Merge Engine UI Engine Code */}

                <div className="hamburger-icon">
                    {mobileContent}
                </div>
                <div className="header-section">
                    {browserContent}
                </div>
                {/* Merge Engine UI Engine Code */}
            </>
        );
    }
}

// Customizable Area End

export default WebSubHeader;

  // Customizable Area Start
  // Customizable Area End