import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Modal, Box, Typography } from '@material-ui/core';
import { t } from 'i18next';

const MediaSlider = ({ item, onClose, allDownloadHandler }: any) => {
    const [currentIndex, setCurrentIndex] = useState(0)

    const mediaManagement = (element: any) => {
        return (
            <>
                {element.type === "image" && <img src={element.url} />}
                {element.type === "video" &&
                    <video controls className='postImage videoBox'>
                        <source src={element.url} type='video/mp4' />
                    </video>
                }
                {element.type === "audio" &&
                    <audio controls>
                        <source src={element.url} type="audio/mpeg" />
                    </audio>
                }
                {element.type === "application" &&
                    <Box
                        style={{
                            width: "max-content",
                            cursor: "pointer",
                            borderRadius: "8px",
                            minHeight: "200px",
                            maxHeight: "200px",
                        }}
                        className='postImageBox'
                    >
                        <div className='doc-box'>
                            <Box className='PostImageLeft'>
                                <svg
                                    width="30"
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="39"
                                    viewBox="0 0 90.48 122.88"
                                >
                                    <path d="M35.27.78a3,3,0,0,1,2-.78,1.54,1.54,0,0,1,.47.05h46.2A6.59,6.59,0,0,1,88.56,2a6.52,6.52,0,0,1,1.92,4.64v109.7a6.57,6.57,0,0,1-6.56,6.56H6.67a6.57,6.57,0,0,1-6.56-6.56v-78A3.07,3.07,0,0,1,0,37.56a3.19,3.19,0,0,1,1-2.24L34.9,1a1.5,1.5,0,0,1,.26-.21ZM84.65,6.62a.5.5,0,0,0-.21-.47A.67.67,0,0,0,84,5.94H40.22V31.62a8.89,8.89,0,0,1-8.91,8.91H6.1v75.79a.58.58,0,0,0,.2.47.69.69,0,0,0,.47.21H84a.58.58,0,0,0,.47-.21.73.73,0,0,0,.21-.47V6.62Zm-62,94.73a2.64,2.64,0,0,1,0-5.28h28a2.64,2.64,0,0,1,0,5.28Zm0-17.75a2.64,2.64,0,0,1,0-5.28H65.46a2.64,2.64,0,0,1,0,5.28Zm0-17.76a2.64,2.64,0,0,1,0-5.28H72a2.64,2.64,0,0,1,0,5.28Zm11.7-34.22V10.11L10.11,34.64h21.2a3.16,3.16,0,0,0,2.13-.88,3.06,3.06,0,0,0,.89-2.14Z" />
                                </svg>
                            </Box>
                            <Box className='PostImageRight'>
                                <Box>
                                    <Typography className='documentName'>
                                        {element.filename.substring(0, 25)}
                                    </Typography>
                                </Box>
                                <Box className='DownloadBox'>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        fill="#7b7b7b"
                                        viewBox="0 0 122.88 110.9"
                                    >
                                        <path d="M13.09,35.65h30.58V23.2l34.49,0v12.45l31.47,0L61.39,82.58L13.09,35.65L13.09,35.65z M61.44,97.88l47.51-0.14 l4.54-21.51l9.38,0.31v34.36L0,110.9V76.55l9.39-0.31l4.54,21.51L61.44,97.88L61.44,97.88L61.44,97.88z M43.67,0h34.49v4.62H43.67 V0L43.67,0z M43.67,9.32h34.49v9.44H43.67V9.32L43.67,9.32z" />
                                    </svg>
                                </Box>
                            </Box>
                        </div>
                    </Box>
                }
            </>
        )
    }

    const onNextHandler = (next: number, active: number) => {
        if (active === item.length - 1) {
            setCurrentIndex(0)
        } else {
            setCurrentIndex(active + 1)
        }
    }

    const onPrevHandler = (prev: number, active: number) => {
        setCurrentIndex(prev)
    }

    return (
        <Modal open={true}>
            <div>
                <Carousel
                    test-id="Carousel-id"
                    autoPlay={false}
                    navButtonsAlwaysVisible={true}
                    next={(next: number, active: number) => onNextHandler(next, active)}
                    prev={(prev: number, active: number) => onPrevHandler(prev, active)}
                    className='Carousel-root-31'
                >
                    {item.map((i: any) => (mediaManagement(i)))}
                </Carousel>
                <div className='carousel-btn'>
                    <div className='icon-modal' onClick={() => allDownloadHandler(item)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 122.88 110.9"
                            width="20"
                            height="20"
                            fill="#fff"
                        >
                            <path d="M13.09,35.65h30.58V23.2l34.49,0v12.45l31.47,0L61.39,82.58L13.09,35.65L13.09,35.65z M61.44,97.88l47.51-0.14 l4.54-21.51l9.38,0.31v34.36L0,110.9V76.55l9.39-0.31l4.54,21.51L61.44,97.88L61.44,97.88L61.44,97.88z M43.67,0h34.49v4.62H43.67 V0L43.67,0z M43.67,9.32h34.49v9.44H43.67V9.32L43.67,9.32z" />
                        </svg>
                        <Typography className='modal-btn-text'>
                            {t('Download')}
                        </Typography>
                    </div>
                    <div className='icon-modal' onClick={onClose}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 122.878 122.88"
                            width="15"
                            height="15"
                            fill="#fff"
                        >
                            <path d="M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313L1.426,8.313z" />
                        </svg>
                        <Typography className='modal-btn-text'>
                            {t('Close')}
                        </Typography>
                    </div>
                </div>
            </div >
        </Modal >
    );
}

export default MediaSlider