import React from "react";
import {
  Grid,
  Typography,
} from "@material-ui/core";
import {t} from "i18next";

const AddressMap = ({ state }: any) => {
  return (
    
    <Grid
        container
        className="address-form"
    >
        <Grid item xs={12}>
            <Typography className="form-title">{t('Address')}</Typography>
             <div className="underline4"></div>
            <Typography className="form-sub-title">{t('The Partnership Brokers Association operates as a distributed organization keeping its core costs as low as possible. We do not have a physical office.')}</Typography> 
          <div className="address-dummy-content">
            <div className="address-sub-content">
              <Typography className="comingsoon-content">{t('Coming soon!')}</Typography> 
              <Typography className="comingsoon-content">{t('Network member locator')}</Typography>
            </div>
          </div>
        </Grid>
    </Grid>
  );
};

export default AddressMap;
