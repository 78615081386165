Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"
exports.notificationApiCall = "bx_block_notifications/notifications";
exports.unreadNotificationApiCall = "bx_block_notifications/unread_the_notification";
exports.readNotificationApiCall = "bx_block_notifications/notifications";
exports.unreadCountApiEndPoint = "bx_block_notifications/unread_notifications_count";

// Customizable Area End