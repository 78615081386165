import React from "react";

// Customizable Area Start
import {
  Container,
  Grid,
  Typography,
  Snackbar,
  MenuItem,
  CircularProgress,
  Box,
  Divider
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Header from "../../../components/src/Header.web";
import { RecentGroups } from "./components/recentGroups.web";
import RightBar from "../../../components/src/RightBar.web";
import "../assets/index.scss";
import { groupIcons, groupIcon, moreHoriz, notification, permMedia, groupCover } from './assets';
import CreateNewGroup from "./components/createNewGroup.web";
import { AttachmentCard } from "./components/attachmentCard.web";
import { ResponsiveRecentGroups } from "./components/responsiveRecentGroups.web";
import { Posts } from "./components/posts.web"
import GroupMembers from "./components/groupMembers.web";
import GroupMedia from "./components/groupMedia.web";
import { Hashtags } from "./components/hashTags.web"
import ViewAllGroups from "./components/viewAllGroups.web"
import ConfirmationBox from "../../../components/src/ConfirmationBox.web";
import MediaSlider from "../../CommunityForum/src/components/MediaSlider.web";
import PostDetails from "../../CommunityForum/src/components/PostDetails.web";
import { CreatePostModal } from "./components/createPostModal.web";
import HashTagRelated from "../../CommunityForum/src/components/HashTagRelated.web";
import { t } from "i18next"
// Customizable Area End

import AccountGroupsController, {
  configJSON,
  Props,
} from "./AccountGroupsController";

export default class AccountGroups extends AccountGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  groupStatus3 = (item2: any) => {
    if (item2?.status !== "accepted" && item2?.request_type !== "requested") {
      return t("Join Group")
    } else if (item2.status === "pending" && item2.request_type === "requested") {
      return t("Requested")
    } else {
      return t("Joined")
    }
  }

  showIcons = (group: any, isMember: any, status: any) => {
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }} className="notif-info">
          {isMember ? (
            <>
              {/* <img src={notification} /> */}
              <img data-test-id="groupMembers" src={groupIcon} onClick={() => this.handleOpen("GroupMembers")} style={{ cursor: 'pointer' }} />
              <Typography className="memberCount">{`${group?.attributes?.group_members} ${group?.attributes?.group_members > 1 ? t("members") : t("member")}`}
              </Typography>
              <img data-test-id="groupMedia" src={permMedia} onClick={() => this.handleOpen("GroupMedia")} style={{ cursor: 'pointer' }} />
              <img data-test-id="handleMoreHoriz" src={moreHoriz} style={{ cursor: "pointer" }} onClick={() => this.handleMoreHoriz()} />
            </>
          ) : (
            <div data-test-id="join-group" className="joinGroupContainers"
              style={{ cursor: "pointer" }}
              onClick={() => group.status !== "accepted" && group.status !== "pending" && this.handleJoinGroup(group, "")}
            >
              {!status && group.status !== "accepted" && group.request_type !== "requested" && (
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M12.6668 8.66543H8.66683V12.6654H7.3335V8.66543H3.3335V7.33209H7.3335V3.33209H8.66683V7.33209H12.6668V8.66543Z"
                      fill="#475569" />
                  </svg>
                </div>
              )}
              <Typography className="textSeconds">
                {this.groupStatus3(group)}
              </Typography>
            </div>
          )}
        </div>
        {this.state.isMoreHorizOpen && (
          <Box className='report-modal' style={{right: '0%'}}>
            {this.state.adminIds.includes(this.state.userId) && (
              <>
                <Typography data-test-id="handleEditGroup" className='report-text' onClick={this.handleEditGroup}>{t('Edit Group')}</Typography>
              </>
            )}
            {!this.state.adminIds.includes(this.state.userId) && (
              <>
                <Typography data-test-id="handleReportGroup" className='report-text' onClick={this.handleReportGroup}>{t('Report Group')}</Typography>
              </>
            )}
            {this.state.adminIds.length !== 1 && (
              <>
                <Divider style={{ width: "100%" }} />
                <Typography data-test-id="handleLeaveGroup" className='report-text' onClick={this.handleLeaveGroup}>{t("Leave Group")}</Typography>
              </>
            )}
            {this.state.adminIds.includes(this.state.userId) && (
              <>
                <Divider style={{ width: "100%" }} />
                <Typography data-test-id="handleDeleteGroup" className='report-text' onClick={this.handleDeleteGroup}>{t('Delete Group')}</Typography>
              </>
            )}
          </Box>
        )}
      </>
    )
  }

  showPosts = (group: any, hashtags: any, isMember: any, recentGroups: any) => {
    return (
      <>
        {isMember && <AttachmentCard postModalHandlers={this.postModalHandlers} group={group} />}
        {/* <Hashtags hashtags={hashtags} handleHashTags={this.handleHashTags} /> */}
        <ResponsiveRecentGroups navigation={""} id={""} handleOpen={this.handleOpen} recentGroups={recentGroups}
          handleJoinGroup={this.handleJoinGroup} getGroupDetails={this.getGroupDetails} />
        <Posts posts={this.state.posts} mediaArr={this.mediaArr} commentInput={this.state.commentInput} authorId={this.state.userId}
          onCommentChange={this.onCommentChange} onCommentHandler={this.onCommentHandler} onLikeHandler={this.onLikeHandler} commentBoxVisibilityHandler={this.commentBoxVisibilityHandler}
          isCommentEnable={this.state.isCommentEnable} commentIndex={this.state.commentIndex} replyIndex={this.state.replyIndex} commentActiveIndex={this.state.commentActiveIndex} isReplied={this.state.isReplied} repiedIndex={this.state.repiedIndex}
          isCommentActive={this.state.isCommentActive} isReportModal={this.state.isReportModal} reportIndex={this.state.reportIndex}
          onRepliedHandler={this.onRepliedHandler} onReplyHandler={this.onReplyHandler} onClickReplyComment={this.onClickReplyComment}
          onClickCommentIndex={this.onClickCommentIndex}
          onReportModalClickHandler={this.onReportModalClickHandler} allDownloadHandler={this.allDownloadHandler}
          handleConfirmModalOpen={this.handleConfirmModalOpen}
          onMediaModalHandler={this.onMediaModalHandler} playVideo={this.playVideo} playAudio={this.playAudio} onShareInputHandler={this.onShareInputHandler}
          handlePostDetails={this.handlePostDetails} fetchData={this.fetchData} totalPages={this.state.totalPages} pageNumber={this.state.pageNumber}
          onDeleteHandler={this.onDeleteHandler} groupId={this.state.group.id} onEditHandler={this.onEditHandler} isMember={this.state.isMember}
          onCommentDeleteHandler={this.onCommentDeleteHandler}
        />
      </>
    )
  }

  showOtherComponents = (openOtherComponent: any, groups: any, group: any, isMember: any, hashtags: any, status: any, confirmModalOpen: any,
    showSnackbar: any, severity: any, message: any, recentGroups: any) => {
    if (this.state.hashtagFiltered.length > 0 || this.state?.postDetails?.attributes) {
      return(
      <>
      {
        this.state.hashtagFiltered.length > 0 &&
        <HashTagRelated
          state={this.state}
          onLikeHandler={this.onLikeHandler}
          onReplyHandler={this.onReplyHandler}
          onCommentChange={this.onCommentChange}
          onCommentHandler={this.onCommentHandler}
          onRepliedHandler={this.onRepliedHandler}
          allDownloadHandler={this.allDownloadHandler}
          onShareInputHandler={this.onShareInputHandler}
          onMediaModalHandler={this.onMediaModalHandler}
          onClickReplyComment={this.onClickReplyComment}
          onClickCommentIndex={this.onClickCommentIndex}
          handlePostDetailsBack={this.handlePostDetailsBack}
          handleConfirmModalOpen={this.handleConfirmModalOpen}
          onReportModalClickHandler={this.onReportModalClickHandler}
          commentBoxVisibilityHandler={this.commentBoxVisibilityHandler}
          authorId={this.state.userId}
          playVideo={this.playVideo}
          playAudio={this.playAudio}
        /> 
      }
      {this.state?.postDetails?.attributes && (
        <PostDetails state={this.state} authorId={this.state.userId}
        onClickReplyComment={this.onClickReplyComment} onClickCommentIndex={this.onClickCommentIndex} handlePostDetailsBack={this.handlePostDetailsBack}
        handleConfirmModalOpen={this.handleConfirmModalOpen} onReportModalClickHandler={this.onReportModalClickHandler} commentBoxVisibilityHandler={this.commentBoxVisibilityHandler}
        playVideo={this.playVideo} playAudio={this.playAudio} onLikeHandler={this.onLikeHandler} onReplyHandler={this.onReplyHandler}
        allDownloadHandler={this.allDownloadHandler} onShareInputHandler={this.onShareInputHandler} onMediaModalHandler={this.onMediaModalHandler}
        onCommentChange={this.onCommentChange} onCommentHandler={this.onCommentHandler} onRepliedHandler={this.onRepliedHandler} />
      )}
      </>
      )
    }
    else if (openOtherComponent === "GroupMedia") {
      return <GroupMedia navigation={""} id={""} handleOpen={this.handleOpen} groupMediaData={{}} group={group}
        groups={groups} handleJoinGroup={this.handleJoinGroup} getGroupDetails={this.getGroupDetails} recentGroups={recentGroups}
        handlePostDetails={this.handlePostDetails}
      />
    }
    else if (openOtherComponent === "GroupMembers") {
      return <GroupMembers navigation={""} id={""} handleOpen={this.handleOpen} group={group}
        groups={groups} handleJoinGroup={this.handleJoinGroup} getGroupDetails={this.getGroupDetails} recentGroups={recentGroups} />
    }
    else {
      return <Container className="postContainer" style={{ position: "relative" }}>
        <div style={{ position: "absolute", top: "-100px" }}>
          <img src={group?.attributes?.icon_image || groupIcons} style={styles.groupImage} className="group-image" />
        </div>
        <div style={styles.groupContainer} className="groupContainer">
          <div>
            <Typography className="groupName">
              {group?.attributes?.name}
            </Typography>
          </div>
          {this.showIcons(group, isMember, status)}
        </div>
        {(isMember || group?.attributes?.group_type === "public") ? (
          this.showPosts(group, hashtags, isMember, recentGroups)
        ) : (
          <div style={{ display: "flex" }}>
            <Typography>{t("This group is private. To access posts, please join the group.")}</Typography>
          </div>
        )}
      </Container>
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { openOtherComponent, group, groups, recentGroups, hashtags, isMember, status, confirmModalOpen, showSnackbar, severity, message,
      isLoadings, postInputs, postMedias, userInterests, userPostInterests, openInterests, isAttachOpens, isOpens
    } = this.state
    const currentUrl = window.location.pathname;
    if (openOtherComponent === "CreateNewGroup" || currentUrl === "/CreateNewGroup") {
      return <CreateNewGroup navigation={this.props.navigation} id={""} handleOpen={this.handleOpen} openOtherComponent={openOtherComponent}
        profileImage={this.state.userInfo.photo} />
    }
    else if ((openOtherComponent === "ViewAllGroups" || currentUrl === "/ViewAllGroups" ) && !this.state.postDetails.attributes) {
      return <ViewAllGroups navigation={this.props.navigation} id={""} handlePostDetails={this.handlePostDetails} getHandleBack={this.getHandleBack} />
    }
    return (
      <>
        {this.state.isModalMedia && <MediaSlider item={this.state.modalMediaData} allDownloadHandler={this.allDownloadHandler} onClose={this.onMediaModalCloseHandler} />}
        <Header type={openOtherComponent === "AccountGroups" ? "Groups" : ""} onSearch={this.handleSearchGroupPost}
          navigation={this.props.navigation} profileImage={this.state.userInfo.photo} notifyCount={Number(localStorage.getItem("notification_unread_count"))}
        />
        <div style={{ backgroundColor: '#f5f5ff' }} onClick={this.handleMenuClose}>
          <Container className="mainContainer" maxWidth="lg" style={{ maxWidth: '1600px', }} onClick={this.onReportModalCloseHandler}>
            {(openOtherComponent === "AccountGroups" && !this.state?.postDetails?.attributes) && (
              <div className="banner-img">
                <img src={group?.attributes?.cover_image || groupCover} style={{ width: "100%" }} />
              </div>
            )}

            <Grid container spacing={2} className={`gridContainers-${this.state?.postDetails?.attributes ? "postDetails" : openOtherComponent}`}>
              <Grid item xs={3} md={3} className="mobileMenuHide">
                <RecentGroups navigation={""} id='2' handleOpen={this.handleOpen} recentGroups={recentGroups} handleJoinGroup={this.handleJoinGroup}
                  getGroupDetails={this.getGroupDetails} />
              </Grid>
              <Grid item xs={12} md={6}>
                {this.showOtherComponents(openOtherComponent, groups, group, isMember, hashtags, status, confirmModalOpen, showSnackbar, severity, message, recentGroups)}
              </Grid>
              <Grid item xs={12} md={3} className="trendingPost"><RightBar topTrendingData={this.state.topTrendingData} handlePostDetails={this.handlePostDetails} /></Grid>
            </Grid>
          </Container>
          <CreatePostModal navigation={""} id={""} group={group} isOpens={isOpens} postModalHandlers={this.postModalHandlers} isEdit={this.state.isEdit}
            editPostId={this.state.editPostId} postInputHandlers={this.postInputHandlers} postInputs={postInputs} existedMedia={this.state.existedMedia}
            postMedias={postMedias} handleAttachModals={this.handleAttachModals} isAttachOpens={isAttachOpens} postMediaHandlers={this.postMediaHandlers}
            handleCloseAttachmentModals={this.handleCloseAttachmentModals} onFilteredHandlers={this.onFilteredHandlers}
            onExistedMediaHandlers={this.onExistedMediaHandlers} userPostInterests={userPostInterests} openInterests={openInterests}
            handleOpenInterests={this.handleOpenInterests} handleCloseInterests={this.handleCloseInterests} postInterestHandlers={this.postInterestHandlers}
            userInterests={userInterests} onEditApiHandlers={this.onEditApiHandlers} postApiHandlers={this.postApiHandlers}
            handleCloseSnackbars={this.handleCloseSnackbars} message={this.state.message} severity={this.state.severity} showSnackbar={this.state.showSnackbar}
            isLoadings={isLoadings} postInptHandlers={this.postInputHandlers} existedInterests={this.state.existedInterests}
            postInterestRemoves={this.postInterestRemoves} />
          <ConfirmationBox
            open={confirmModalOpen}
            handleClose={this.handleConfirmModalClose}
            handleSubmit={this.handleConfirmModalSubmit}
          />
          <Snackbar
            style={{ zIndex: 9999 }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={showSnackbar}
            autoHideDuration={5000}
            onClose={this.handleCloseSnackbar}
          >
            <MuiAlert
              onClose={this.handleCloseSnackbar}
              severity={severity}
            >
              {message}
            </MuiAlert>
          </Snackbar>
        </div>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  groupContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '55px',
    width: "100%",
  },
  groupImage: {
    borderRadius: "50%",
    border: "2px solid #fff",
    height: "120px",
    width: "120px"
  },
}
// Customizable Area End
