import React from "react";
import {
    Grid,
    FormControl,
    FormLabel,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    InputAdornment,
    Input,
} from "@material-ui/core";
import { VisibilityOffOutlined, VisibilityOutlined} from '@material-ui/icons';
import {t} from "i18next";

const SignInForm = ({state,  handleLoginShowPassword, handleLogin, handleLoginChange, goToForgotPage}: any) => {
    return (
        
            <form onSubmit={handleLogin} className="signup-form">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel className="form-labels">
                                {t('Email Address')}
                            </FormLabel>
                            <TextField
                                value={state.SignInFormField.email}
                                type={'text'}
                                placeholder={`Johndoe@gmail.com`}
                                className="text-box"
                                variant="outlined"
                                name={'email'}
                                onChange={handleLoginChange}
                            />
                        </FormControl>
                        {state.loginEmailError &&
                            <p className="error-msg">{state.loginEmailError }</p>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel className="form-labels signin-input-box">
                                {t('Password')}
                            </FormLabel>
                            <Input 
                                value={state.SignInFormField.password}
                                disableUnderline                                    
                                type={state.showLoginPassword ? 'text' : 'password'}
                                placeholder={`xxxxxxxxxx`}
                                className="text-box"
                                name={'password'}
                                onChange={handleLoginChange}
                                endAdornment={
                                    <InputAdornment className="btn-show-pass" onClick={handleLoginShowPassword} position="start">
                                        {state.showLoginPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        {state.loginPasswordError &&
                            <p className="error-msg">{state.loginPasswordError }</p>
                        }
                    </Grid>
                    <Grid item xs={1}>
                        <FormControlLabel
                            className="checkbox-input"
                            control={
                            <Checkbox 
                                onChange={handleLoginChange}
                                color="default" 
                                name="rememberMe"
                                checked={state.rememberMe}/>
                            }
                            label={null}
                          />
                    </Grid>
                    <Grid className="checkbox-label-signinbox" item xs={11}>
                    <span className="checkbox-label">
                        {t('Remember me')}{" "}
                    </span>
                    <span onClick={goToForgotPage} className="forgot-label">
                        {t('Forgot Password?')}
                    </span>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" className="reg-submit-btn" variant="contained" color="primary">{t('Login')}</Button>
                    </Grid>
                </Grid>
            </form>
       
    );
};

export default SignInForm;
