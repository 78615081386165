import React from "react";
import {
    Grid,FormControl,FormLabel,TextField,Button,Box,Typography
} from "@material-ui/core";
import {t} from "i18next";

const ForgotPasswordEmailForm = ({state, handleEmailSubmit, handleEmailChange, handleOTPChange, handleOtpSubmit}: any) => {
    return (
        <>
            <div className='forgot-password-text'>
                <Box>
                    <Typography className='forgot-text-one'>{t('Forgot Password?')}</Typography>
                </Box>
                <Box>
                    <Typography className='forgot-text-two'>{t("Have you forgotten the password of your Email address? Don't worry! Enter your email address and we will send a")}
                    <span className='forgot-text-three'> {t('Reset password')} </span> {t('link to your mailing address')}</Typography>
                </Box>
            </div>
            { state.newtoken === "" ?
            <form onSubmit={handleEmailSubmit} className="forgot-email-form">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel className="form-labels">
                                {t('Email Address')}
                            </FormLabel>
                            <TextField
                                value={state.email}
                                type={'text'}
                                placeholder={t(`Johndoe@gmail.com`)}
                                className="text-box"
                                variant="outlined"
                                name={'email'}
                                onChange={handleEmailChange}
                            />
                        </FormControl>
                        {state.emailError &&
                            <p className="error-msg">{t(state.emailError) }</p>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" className="forgot-submit-btn" variant="contained" color="primary">{t('RESET PASSWORD')}</Button>
                    </Grid>
                </Grid>
            </form>
            :
            <form onSubmit={handleOtpSubmit} className="forgot-otp-form">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel className="form-labels">
                            {t('OTP')}
                            </FormLabel>
                            <TextField
                                value={state.forgotOTP}
                                type={'text'}
                                placeholder={t(`Enter OTP`)}
                                className="text-box"
                                variant="outlined"
                                name={'forgotOTP'}
                                onChange={handleOTPChange}
                            />
                        </FormControl>
                        {state.otpError &&
                            <p className="error-msg">{t(state.otpError) }</p>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" className="forgot-submit-btn" variant="contained" color="primary">{t('RESET PASSWORD')}</Button>
                    </Grid>
                </Grid>
            </form>
            }
        </>

    );
};

export default ForgotPasswordEmailForm;
