import React from "react";
import {
    Grid,
    FormControl,
    FormLabel,
    Button,
    InputAdornment,
    Input,
    Typography
} from "@material-ui/core";
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import {t} from "i18next";



const AccountSetting = ({state, handleShowCurrentPassword, handleShowConfirmPassword, handleShowChangePassword, handleChange, handleSubmit}: any) => {
    
    return (

        <form className="account-form">
            <Grid container spacing={2}>
                <Grid className="account-box" item  xs={12} lg={6} sm={12} md={6}>
                    <Typography className="account-sub-heading">{t('Your older password')}</Typography>
                    <Grid item>
                        <FormControl fullWidth>
                            <FormLabel className="form-labels">
                                {t('Current Password')}
                            </FormLabel>
                            <Input
                                disableUnderline
                                value={state.PasswordFormField.current_password}
                                onChange={handleChange}
                                data-test-id="showpassInput"
                                type={state.showCurrentPassword ? 'text' : 'password'}
                                placeholder={`xxxxxxxxxx`}
                                className="text-box"
                                name={'current_password'}
                                endAdornment={
                                    <InputAdornment className="btn-show-pass" onClick={handleShowCurrentPassword} position="start">
                                        {state.showCurrentPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                    </InputAdornment>
                                }
                            />
                            {state.PasswordErrorField.currentPasswordError &&
                                <p data-test-id={`error-currentPasswordError`} className="error-msg">{state.PasswordErrorField.currentPasswordError}</p>}
                        </FormControl>
                    </Grid>
                    <Typography className="account-sub-heading">{t('Change your password')}</Typography>
                    <Grid item >
                        <FormControl fullWidth>
                            <FormLabel className="form-labels">
                                {t('New Password')}
                            </FormLabel>
                            <Input
                                disableUnderline
                                value={state.PasswordFormField.new_password}
                                onChange={handleChange}
                                data-test-id="showpassInput"
                                type={state.showChangePassword ? 'text' : 'password'}
                                placeholder={`xxxxxxxxxx`}
                                className="text-box"
                                name={'new_password'}
                                endAdornment={
                                    <InputAdornment className="btn-show-pass" onClick={handleShowChangePassword} position="start">
                                        {state.showChangePassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                    </InputAdornment>
                                }
                            />
                            {state.PasswordErrorField.newPasswordError &&
                                <p data-test-id={`error-newPasswordError`} className="error-msg">{state.PasswordErrorField.newPasswordError}</p>}
                        </FormControl>
                    </Grid>
                    <Grid item >
                        <FormControl fullWidth>
                            <FormLabel className="form-labels">
                                {t('Confirm Password')}
                            </FormLabel>
                            <Input
                                disableUnderline
                                value={state.PasswordFormField.confirm_password}
                                onChange={handleChange}
                                data-test-id="showpassInput"
                                type={state.showConfirmPassword ? 'text' : 'password'}
                                placeholder={`xxxxxxxxxx`}
                                className="text-box"
                                name={'confirm_password'}
                                endAdornment={
                                    <InputAdornment className="btn-show-pass" onClick={handleShowConfirmPassword} position="start">
                                        {state.showConfirmPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                    </InputAdornment>
                                }
                            />
                            {state.PasswordErrorField.confirmPasswordError &&
                                <p data-test-id={`error-confirmPasswordError`} className="error-msg">{state.PasswordErrorField.confirmPasswordError}</p>}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item className="btn-box" xs={12} lg={12} sm={12} md={12}>
                    <Button type="button" onClick={handleSubmit} className="setting-submit-btn" variant="contained" color="primary">{t('Save')}</Button>
                </Grid>
            </Grid>
        </form>

    );
};

export default AccountSetting;
