import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { t } from "i18next";


const ConfirmationBox = ({ open, handleClose, handleSubmit }: any) => {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"

    >
      <DialogContent style={{ textAlign: "center" }}>
        <DialogTitle style={{ color: "#44556c", fontSize: "16px" }}>
          {t('Are you sure you want to report this?')}
        </DialogTitle>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "center",
          margin: "0px 30px 30px 30px",
          padding: "22px"
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          {t('Yes')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
        >
          {t('No')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationBox;
