import React from "react";
import {
    Grid
} from "@material-ui/core";
import '../../assets/Community.scss';
import { GroupToFollow } from "../../../../components/src/GroupToFollow.web";
import RecentGroup from "./RecentGroup.web";


const SideGroupPage = ({ state, handleGroupJoin, navigation}: any) => {
    
    return (
        <Grid item xs={3} md={3} className="mobileMenuHide">
            {(!state?.postDetails?.attributes) &&
            <>
                {/* <FilterCard onClick={this.onFilterChangeHandler} /> */}
                <GroupToFollow handleGroupJoin={handleGroupJoin} navigation={navigation} groupsData={state.groupsData} />
            </>
            }
            <RecentGroup handleGroupJoin={handleGroupJoin} navigation={navigation} recentGroupsData={state.recentGroupsData} />
        </Grid>
    );
};

export default SideGroupPage;
