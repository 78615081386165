export const isUserloggedIn = () => {
    let x = localStorage.getItem("authToken");
    return !!x;
  };
  
  export const handleLogout = () => {
    localStorage.setItem("authToken", "");
    localStorage.setItem("userInfo", "");
  };

  export const isFormType = () => {
    return localStorage.getItem("formType") || 'signin';
  }

  export const getUserDetails = () => {
    return JSON.parse(localStorage.getItem("userInfo") || "{}");
  };
  
  export const getAuthToken = () => {
    return localStorage.getItem("authToken");
  };