import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getAuthToken, getUserDetails } from "../../../components/src/NativeWebRouteWrapper/Utils";
import { imgBell } from "./assets";
import i18next from "i18next";
const notify =[
  {
    id: 1,
    attributes: {
      headings: "comment",
      contents: "very nice",
      created_at: "24 min ago",
      created_by_account_details: {
        full_name: 'xyz',
      }
    }
  },
  {
    id: 2,
    attributes: {
      headings: "like",
      contents: "",
      created_at: "24 min ago",
      created_by_account_details: {
        full_name: 'john',
      }
    }
  }
]
type NotificationInterface = {
   id: number; 
   attributes: { 
    headings: string; 
    contents: string; 
    created_at: string; 
    created_by_account_details: { full_name: string; }; 
  }; 
}
type Color = "success" | "info" | "warning" | "error" | undefined;
export type userInfointerface = {
  id: number
  activated: boolean,
  country_code: number,
  email: string,
  first_name: string,
  full_phone_number: number,
  last_name: string,
  full_name: string,
  gender: null,
  phone_number: number,
  type: string,
  created_at: null,
  updated_at: null,
  device_id: null,
  unique_auth_id: string,
  photo: string,
  cover_picture: null,
  profession: string,
  city: string,
  state: string,
  country: string,
  partnership_practitioner: null
  accredited_partnership_broker: boolean
  organizational_affilation: null
  preferred_language: string
}
const userInfoInitialValue = {
  id: 0,
  country_code: 0,
  activated: false,
  email: '',
  full_phone_number: 0,
  first_name: '',
  last_name: '',
  gender: null,
  full_name: '',
  phone_number: 0,
  created_at: null,
  type: '',
  updated_at: null,
  unique_auth_id: '',
  device_id: null,
  cover_picture: null,
  photo: '',
  profession: '',
  city: '',
  state: '',
  partnership_practitioner: null,
  country: '',
  accredited_partnership_broker: false,
  preferred_language: '',
  organizational_affilation: null,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  notificationData: NotificationInterface[];
  currentPage: number;
  totalPage: number;
  moreOptionStatus: boolean;
  moreIndex: number;
  showSnackbar: boolean;
  severity: Color;
  message: string;
  userInfo: userInfointerface

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  apiGetNotification: string = "";
  apiUnreadNotification: string = "";
  apiReadNotification: string = "";
  token = getAuthToken()
  apiUnreadCountNotification: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: "",
      notificationData: [],
      currentPage: 1,
      totalPage: 0,
      moreOptionStatus: false,
      moreIndex: 0,
      showSnackbar: false,
      severity: undefined,
      message: "",
      userInfo: userInfoInitialValue,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    
    this.getNotificationDataList(this.state.currentPage);
    const localItems: any = getUserDetails();
    this.setState({ userInfo: localItems });
    const userLang = localItems.preferred_language;
    i18next.changeLanguage(userLang.toLowerCase());
    localStorage.setItem('notify_arr', JSON.stringify({}))
    
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getNotifications();
    } else if (
      this.getDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      apiResponse &&
        this.setState({
          data: apiResponse.data,
        });
    } else if (
      this.markAsReadCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getNotifications();
    } else if (
      this.deleteCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.showAlert("Message", configJSON.deleteMessage);
      }
      this.setState({ selectedData: null });
      this.getNotifications();
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.apiNotificationCaller(apiRequestCallId, responseJson)
      this.apiNotificationUnreadCaller(apiRequestCallId, responseJson)
      this.apiNotificationReadCaller(apiRequestCallId, responseJson)
      this.apiUnreadNotificationCountCaller(apiRequestCallId,responseJson)
    }

    // Customizable Area End
  }

  // Customizable Area Start

  apiNotificationCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiGetNotification) {
      if (responseJson != null) {
        if (responseJson.data) {
          this.setState({
            notificationData: responseJson.data,
            totalPage: responseJson.meta.pagination.total_pages
          });
        }
      }
      this.getUnreadCount();
    }
  }

  apiNotificationUnreadCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiUnreadNotification) {
      if (responseJson != null) {
        if (responseJson.message) {
          this.setState({
            showSnackbar: true,
            message: responseJson.message,
            severity: "success",
          });
          this.getNotificationDataList(this.state.currentPage);
        }else if(responseJson.errors){
          this.setState({
            showSnackbar: true,
            message: responseJson.errors[0],
            severity: "error",
          });
        }
      }
    }
  }

  apiNotificationReadCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiReadNotification) {
      if (responseJson != null) {
        if (responseJson.message) {
          const notifyLocalData =  JSON.parse(localStorage.getItem("notify_arr")!)
          if(Object.keys(notifyLocalData).length !== 0){
          this.redirectNotifyData(notifyLocalData?.type);
          }else{
            this.setState({
              showSnackbar: true,
              message: responseJson.message,
              severity: "success",
            });
            this.getNotificationDataList(this.state.currentPage);
          }
        }else if(responseJson.errors){
          this.setState({
            showSnackbar: true,
            message: responseJson.errors[0],
            severity: "error",
          });
        }
      }
    }
  }

  redirectNotifyData = (data: any) => {
    switch (data) {

      case "post":
        window.location.href = "/CommunityForumWeb";
        break;
      case "group":
        window.location.href = "/AccountGroups"
        break;
    }
  }

  apiUnreadNotificationCountCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiUnreadCountNotification) {
      if (responseJson != null) {
        if (responseJson?.unread_notifications_count) {
          localStorage.setItem("notification_unread_count",responseJson.unread_notifications_count);
        }else{
          localStorage.setItem("notification_unread_count",'');
        }
      }
    }
  }

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  handleCloseMore = () => {
    if(this.state.moreOptionStatus === true){
      this.setState({moreOptionStatus: false})
    }
  }

  handleNotifyRedirection = (id: number,notify_id: number,type:string) => {
    
    localStorage.setItem('notify_arr', JSON.stringify({
      type:type === "BxBlockPosts::Post" ? "post": "group",
      id: id
    }))
    this.notificationRead(notify_id);

  }

  onMoreModalClickHandler =(index: number) => {
      this.setState({ moreIndex: index })
      this.setState({ moreOptionStatus: !this.state.moreOptionStatus })
   
  }

  handleUnreadNotification =(id: number) => {
    this.notificationUnread(id);
  }

  handleReadNotification =(id: number) => {
    this.notificationRead(id);
  }

  handlePagination=(e: any, value: number) => {
    this.setState({currentPage: value});
    this.getNotificationDataList(value);
  }

  iconBellProps = {
    source: imgBell,
  };

  getNotifications() {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: number) {
    console.log(id);
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }
  
  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  getNotificationDataList = (page:number) => {

    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetNotification = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationApiCall}?page=${page}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  notificationUnread = (id:number) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    this.apiUnreadNotification = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unreadNotificationApiCall}?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData( 
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  notificationRead = (id:number) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    this.apiReadNotification = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.readNotificationApiCall}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData( 
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUnreadCount = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    this.apiUnreadCountNotification = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unreadCountApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData( 
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
