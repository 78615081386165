import React, { useEffect } from "react";
import {
    Chip, Container
} from '@material-ui/core';
import '../../assets/Community.scss';
import UserList from "./UserList.web";
import AllPostDetails from "./AllPostDetails.web";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {t} from "i18next";
const CheckSearch = ({ isSearchType, state, playVideo, playAudio, onEditHandler, onReplyHandler, onDeleteHandler, onCommentChange, onCommentHandler, onRepliedHandler, handlePostDetails, allDownloadHandler, onClickReplyComment, onClickCommentIndex, onMediaModalHandler, handleConfirmModalOpen, onReportModalClickHandler, commentBoxVisibilityHandler, onShareInputHandler, onLikeHandler, getUserProfile }: any) => {
    if (isSearchType == "user" && state.userListData.length > 0) {
        return <UserList getUserProfile={getUserProfile} state={state} />
    } else if (isSearchType == "post" && state.postsData.length > 0) {
        return <AllPostDetails
            state={state}
            authorId={state.userDetails.id}
            playVideo={playVideo}
            playAudio={playAudio}
            onEditHandler={onEditHandler}
            onReplyHandler={onReplyHandler}
            onDeleteHandler={onDeleteHandler}
            onCommentChange={onCommentChange}
            onCommentHandler={onCommentHandler}
            onRepliedHandler={onRepliedHandler}
            handlePostDetails={handlePostDetails}
            allDownloadHandler={allDownloadHandler}
            onClickReplyComment={onClickReplyComment}
            onClickCommentIndex={onClickCommentIndex}
            onMediaModalHandler={onMediaModalHandler}
            handleConfirmModalOpen={handleConfirmModalOpen}
            onReportModalClickHandler={onReportModalClickHandler}
            commentBoxVisibilityHandler={commentBoxVisibilityHandler}
            onShareInputHandler={onShareInputHandler}
            onLikeHandler={onLikeHandler}
        />
    } else {
        return <p>{t('Select Category.....')}</p>
    }
}

const SearchBlock = ({ handleSearchTabChange, isSearchType, state, playVideo, playAudio, onEditHandler, onReplyHandler, onDeleteHandler, onCommentChange, onCommentHandler, onRepliedHandler, handlePostDetails, allDownloadHandler, onClickReplyComment, onClickCommentIndex, onMediaModalHandler, handleConfirmModalOpen, onReportModalClickHandler, commentBoxVisibilityHandler, onShareInputHandler, onLikeHandler, getUserProfile }: any) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    return (
        <Container className="search-container">
            <div className="chip-box">
                <Chip
                    className={isSearchType == "user" ? "active-chip-content" : "chip-content"}
                    label={t("User")}
                    test-id="user-search-id"
                    onClick={() => handleSearchTabChange('user')}
                />
                <Chip
                    className={isSearchType == "post" ? "active-chip-content" : "chip-content"}
                    label={t("Post")}
                    test-id="post-search-id"
                    onClick={() => handleSearchTabChange('post')}
                />
            </div>
            <>
                {
                    <CheckSearch handleSearchTabChange={handleSearchTabChange} isSearchType={isSearchType} state={state} playVideo={playVideo} playAudio={playAudio} onEditHandler={onEditHandler} onReplyHandler={onReplyHandler} onDeleteHandler={onDeleteHandler} onCommentChange={onCommentChange} onCommentHandler={onCommentHandler} onRepliedHandler={onRepliedHandler} handlePostDetails={handlePostDetails} allDownloadHandler={allDownloadHandler} onClickReplyComment={onClickReplyComment} onClickCommentIndex={onClickCommentIndex} onMediaModalHandler={onMediaModalHandler} handleConfirmModalOpen={handleConfirmModalOpen} onReportModalClickHandler={onReportModalClickHandler} commentBoxVisibilityHandler={commentBoxVisibilityHandler} onShareInputHandler={onShareInputHandler} onLikeHandler={onLikeHandler} getUserProfile={getUserProfile} />
                }
            </>
            <Backdrop className="backdrop-loader" open={state.isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>

    );
};

export default SearchBlock;
