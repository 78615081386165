import { createTheme } from '@material-ui/core/styles';
import { colors } from './colors';

const defaultTheme = createTheme();

export const muiTheme = createTheme({
  palette: {
    ...colors
  },
  // typography: {
  //   ...typography
  // },
  shape: {
    borderRadius: 1
  },

  // Component style overrides

  overrides: {
    MuiChip : {
      root: {
          justifyContent: "flex-start"
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontSize: '0.875rem',
        lineHeight: 1.14,
        padding: defaultTheme.spacing(1.5, 2)
      },
      outlined: {
        border: `1px solid ${colors.primary}`,
        color: colors.common.white,
        padding: defaultTheme.spacing(1.5, 2)
      },
      containedSizeLarge: {
        padding: defaultTheme.spacing(2.5, 3),
        width: '100%'
      },
      containedPrimary: {
        background: colors.primary.main,
        color: defaultTheme.palette.common.white,
        borderRadius: '6px',
        '&:hover': {
          backgroundColor: colors.primary.dark,
          color: colors.common.white,
          borderRadius: '6px'
        }
      },
      outlinedPrimary: {
        background: colors.primary.main,
        color: defaultTheme.palette.common.white,
        '&:hover': {
          backgroundColor: colors.primary.dark,
          color: colors.common.white
        }
      },
      outlinedSecondary: {
        backgroundColor: colors.secondary.main,
        color: colors.common.white,
        '&:hover': {
          backgroundColor: colors.secondary.light,
          color: colors.common.white
        }
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: 0,
        height: '40px',
        width: defaultTheme.spacing(5)
      },
      colorPrimary: {
        background: colors.primary.main,
        color: defaultTheme.palette.common.white,

        '&:hover': {
          backgroundColor: colors.common.black,
          color: colors.common.white
        }
      },
      colorSecondary: {
        backgroundColor: colors.common.white,
        color: colors.grey[700]
      },
      sizeSmall: {
        height: '24px',
        width: '24px',
        color: colors.grey[400],
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiInputBase: {
      root: {
          fontFamily: "Nunito-Regular",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#64748B" ,
      }
    },
    MuiOutlinedInput: {
      root:{
      border: 'none',
      }
    },
    MuiList: {
      root: {
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
      }
    },
    MuiMenuItem: {
      // For ListItem, change this to MuiListItem
      root: {
        color: '#64748B',
        borderBottom : '1px solid #CBD5E1',
        padding:'7px',
        borderRadius: '4px',
        '&$selected': {
          backgroundColor: '#F1F5F9',
          margin: '4px',
          borderRadius: '4px',
          color: '#334155',
          borderBottom : '1px solid #CBD5E1',
          '&:hover': {
            backgroundColor: '#F1F5F9',
            color: '#334155',
            margin: '4px',
            borderRadius: '4px',
          }
        },
        '&:hover': {
          backgroundColor: '#F1F5F9',
          color: '#334155',
          margin: '4px',
          borderRadius: '4px',
        }
      }
    },
    MuiPaginationItem: {
      // For ListItem, change this to MuiListItem
      textPrimary: {
        '&$selected': {
          backgroundColor: '#5497FF',
          color: 'white',
          fontWeight: 600
        },
      }
    },
  }
});

export default muiTheme;
