import React from "react";
import { Typography, Avatar, Paper } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import {t} from 'i18next';

const RightBar = ({ handlePostDetails, topTrendingData }: any) => {
    return (
        <Paper elevation={3} style={{
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "8px",
            background: "var(--basic-white, #fff)",
            boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
            padding: "0 0 10px 0",
        }}>
            <Typography component="h4" variant="h6" style={{
                verticalAlign: 'middle',
                padding: '15px 16px', color: '#475569', fontFamily: 'Comfortaa-Bold',
                fontSize: '14px', fontWeight: 700, height: '22px', 
                // width: '100px'
            }}>
                {t('Now trending')}
            </Typography>
            <Divider style={{ padding: '0px 16px', margin: '0 15px' }} />

            {topTrendingData && topTrendingData.length > 0 && topTrendingData.slice(0, 5).map((item: any) => {
                return (
                    <div
                        style={{
                            height: 'auto',
                            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            position: 'relative',
                            margin: '15px 16px 40px',
                            borderRadius: '50px',
                        }}
                    >
                        <Card onClick={() => handlePostDetails(item.id)} style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            border: ' 1px solid #F1F5F9',
                            borderRadius: '8px', boxShadow: 'none',
                        }}>
                            <CardActionArea>
                                {item.attributes.images.length > 0 ?
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            maxHeight: '200px',
                                            objectFit: 'cover',
                                            margin: '0 auto',
                                            borderRadius: '5px'
                                        }}
                                        src={item.attributes.images[0].url}
                                    /> : item.attributes.videos.length > 0 ?
                                        <CardMedia
                                            component="video"
                                            height="140"
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                objectFit: 'cover',
                                                margin: '0 auto',
                                                borderRadius: '5px'
                                            }}
                                            src={item.attributes.videos[0].url}
                                        /> : item.attributes.documents.length > 0 ?
                                            <div style={{
                                                display: "flex",
                                                maxWidth: "100%",
                                                padding: "32px",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "8px",
                                                borderRadius: "8px",
                                                background: "#6BE795"
                                            }}>
                                                <div style={{
                                                    width: "40px",
                                                    height: "40px",
                                                }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="52" viewBox="0 0 44 52" fill="none">
                                                        <g filter="url(#filter0_d_618_45041)">
                                                            <path d="M34.5 21.2852V36.5C34.5 37.4946 34.1049 38.4484 33.4017 39.1517C32.6984 39.8549 31.7446 40.25 30.75 40.25H13.25C12.2554 40.25 11.3016 39.8549 10.5983 39.1517C9.89509 38.4484 9.5 37.4946 9.5 36.5V11.5C9.5 10.5054 9.89509 9.55161 10.5983 8.84835C11.3016 8.14509 12.2554 7.75 13.25 7.75H20.9648C21.6277 7.7501 22.2633 8.01341 22.732 8.48203L33.768 19.518C34.2366 19.9867 34.4999 20.6223 34.5 21.2852Z" stroke="white" stroke-width="2.5" stroke-linejoin="round" />
                                                            <path d="M22 8.375V17.75C22 18.413 22.2634 19.0489 22.7322 19.5178C23.2011 19.9866 23.837 20.25 24.5 20.25H33.875M15.75 26.5H28.25M15.75 32.75H28.25" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <filter id="filter0_d_618_45041" x="-6" y="-2" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                                <feOffset dy="2" />
                                                                <feGaussianBlur stdDeviation="4" />
                                                                <feComposite in2="hardAlpha" operator="out" />
                                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_618_45041" />
                                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_618_45041" result="shape" />
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                </div>
                                            </div> : null
                                }
                                <div
                                    style={{
                                        width: '100%',
                                        backgroundColor: 'background.paper',
                                    }}
                                >
                                    <List style={{ border: 'none' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar src={item.attributes.author_image} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <React.Fragment>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                style={{
                                                                    display: 'inline',
                                                                    fontFamily: 'Comfortaa-Bold',
                                                                    fontSize: '14px',
                                                                    fontWeight: 700,
                                                                    lineHeight: '22px',
                                                                    color: '#475569',
                                                                }}
                                                            >
                                                                {item.attributes.author_name}
                                                            </Typography>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography
                                                                    variant="body2"
                                                                    color="textSecondary"
                                                                    style={{
                                                                        fontFamily: 'Nunito-Regular',
                                                                        fontSize: '12px',
                                                                        fontWeight: 400,
                                                                        lineHeight: '18px',
                                                                        color: '#475569',
                                                                    }}
                                                                >
                                                                    {item.attributes.created_at}
                                                                </Typography>
                                                                {/* <Typography
                                                                    variant="body2"
                                                                    color="textSecondary"
                                                                    style={{
                                                                        display: 'inline',
                                                                        marginLeft: '5px',
                                                                        fontFamily: 'Nunito-Regular',
                                                                        fontSize: '12px',
                                                                        fontWeight: 400,
                                                                        lineHeight: '18px',
                                                                        color: '#475569',
                                                                    }}
                                                                >
                                                                    <span style={{ verticalAlign: 'middle' }}>
                                                                        <MoreHorizIcon />
                                                                    </span>
                                                                </Typography> */}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            className="trending-desc"
                                                        >
                                                            {item.attributes.description.substring(0, 350) + "..."}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </div>
                            </CardActionArea>
                        </Card>
                    </div>
                )
            })}
            {topTrendingData === undefined && (
                <Typography style={{
                    display:"flex",
                    justifyContent: "center",
                    color: "rgb(71, 85, 105)",
                    padding: "20px",
                }}>No posts found</Typography>
            )}
        </Paper >
    )
}

export default RightBar;