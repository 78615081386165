import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getAuthToken } from "../../../components/src/NativeWebRouteWrapper/Utils";
type Color = "success" | "info" | "warning" | "error" | undefined;
export const { SideBarScreenNames } = require("./constants/enum");
import i18next, {t} from "i18next";
const Language: any = { english: "en", french: "fr", bengali: "bn" }
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onSideBarNavigate?: any;
  posts?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;
  currentIndex: number | null;
  groupIdMembers: number | string;
  isMenu: boolean;
  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  endPoint: string;
  saveButtonDisable: boolean;
  profileSideBar: string;
  isVisibleModal: boolean;
  isGalleryModelVisible: boolean;
  coverModel: boolean;
  showSnackbar: boolean;
  severity: Color;
  message: string;
  userData: any;
  userMyPosts: any[];
  trendingPosts: any[];
  myGroups: any[];
  joinedGroups: any[];
  createGroup: Record<string, any>,
  usercommentInput: string,
  userSelectedImage: any;
  userPhotoGallery: any[];
  userMedia: any[];
  userRecentActivities: any[];

  groupId: number | string;
  groupMembers: any[];
  isMember: boolean;
  groupName: string;
  members: number;
  userId: number | string;
  isKabab: boolean;
  membersIndex: number | string;
  mId: string;

  isCommentEnable: boolean;
  commentIndex: number | null;
  replyIndex: number | null;
  commentActiveIndex: number | null;
  isReplied: boolean;
  repiedIndex: number | null;
  isCommentActive: boolean;
  isReportModal: boolean;
  reportIndex: number | null;
  reportPostId: number | string;
  isModalMedia: boolean;
  confirmModalOpen: boolean;
  modalMediaData: any;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userAttr: any;
  token = getAuthToken()
  userApiAddress: any;
  userInterestsApiAddress: any;
  userData: any;
  userInterests: any;
  usermyPostsApiAddress: string = "";
  trendingPostApiAdress: string = "";
  userMyGroupsApiAdress: string = "";
  joinedGroupsApiAddress: string = "";
  deleteApiAddress: string = "";
  likesApiAddress: string = "";
  commentApiAddress: string = "";
  updateUserApiId: string = "";
  userPhotoLibraryApiId: string = "";
  mediaApiAddress: string = "";
  userRecentActivitiesApiAddress: string = "";
  reportPostApiCallId = "";
  groupMemberApiAddress: string = "";
  reportApiAddress: string = "";
  makeMemberApiAddress: string = "";
  removeMemberApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",
      currentIndex: null,
      groupIdMembers: '',
      isMenu: false,

      isKabab: false,
      membersIndex: '',
      mId: '',
      groupId: 0,
      groupMembers: [],
      isMember: false,
      groupName: '',
      members: 0,
      userId: '',

      endPoint: "",
      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      profileSideBar: SideBarScreenNames.aboutMe,
      isVisibleModal: false,
      isGalleryModelVisible: false,
      coverModel: false,
      userData: {},
      userMyPosts: [],
      trendingPosts: [],
      myGroups: [],
      joinedGroups: [],
      createGroup: {},
      usercommentInput: "",
      userSelectedImage: {},
      userMedia: [],
      userPhotoGallery: [],
      userRecentActivities: [],

      showSnackbar: false,
      severity: undefined,
      message: "",

      isCommentEnable: false,
      commentIndex: null,
      replyIndex: null,
      commentActiveIndex: null,
      isReplied: false,
      repiedIndex: null,
      isCommentActive: false,
      isReportModal: false,
      reportIndex: null,
      reportPostId: "",
      isModalMedia: false,
      confirmModalOpen: false,
      modalMediaData: {},
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      this.userDataApiCaller(apiRequestCallId, responseJson)
      this.usermyPostsDataApiCaller(apiRequestCallId, responseJson)
      this.usetrendingPostsDataApiCaller(apiRequestCallId, responseJson)
      this.userPhotoLibraryApiCaller(apiRequestCallId, responseJson)
      this.userMyGroupsApiCaller(apiRequestCallId, responseJson)
      this.userJoinedGroupsApiCaller(apiRequestCallId, responseJson)
      this.commentApiResponseHandler(apiRequestCallId, responseJson)
      this.likeApiHandler(apiRequestCallId, responseJson)
      this.updateImageApiHandler(apiRequestCallId, responseJson)
      this.userRecentActivitiesApiCaller(apiRequestCallId, responseJson)
      this.leaveGroupApiHandler(apiRequestCallId, responseJson)
      this.reportApiHandler(apiRequestCallId, responseJson)
      this.mediaApiHandler(apiRequestCallId, responseJson)
      this.groupMembersApiHandler(apiRequestCallId, responseJson)
      this.reportMemberApiHandler(apiRequestCallId, responseJson)
      this.makeAdminApiHandler(apiRequestCallId, responseJson)
      this.removeMemberApiCallHandler(apiRequestCallId, responseJson)

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId && responseJson) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData) {
              if (regexData.password_validation_regexp) {
                this.passwordReg = new RegExp(
                  regexData.password_validation_regexp
                );
              }

              if (regexData.email_validation_regexp) {
                this.emailReg = new RegExp(regexData.email_validation_regexp);
              }

              if (regexData.email_validation_regexp) {
                this.setState({
                  passwordHelperText: regexData.password_validation_rules
                });
              }
            }
          }
        } else if (apiRequestCallId === this.userProfileGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data &&
            responseJson.data.attributes
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data.attributes;

            if (this.userAttr !== null && this.userAttr !== undefined) {
              let email = this.userAttr.email;
              let firstName = this.userAttr.first_name;
              let lastName = this.userAttr.last_name;
              let currentCountryCode = this.userAttr.country_code;
              let phoneNumber = this.userAttr.phone_number
                ? this.userAttr.phone_number
                : "";

              this.setState({
                email: email,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber
              });

              //@ts-ignore
              this.txtInputFirstNameProps.value = firstName;

              //@ts-ignore
              this.txtInputLastNameProps.value = lastName;

              //@ts-ignore
              this.txtInputEmailProps.value = email;

              //@ts-ignore
              this.txtInputPhoneNumberProps.value = phoneNumber;

              this.registrationAndLoginType = this.userAttr.type;

              if (this.userAttr.country_code) {
                this.setState({ currentCountryCode: currentCountryCode });
              }

              if (
                configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: true
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: false,
                  llDoChangePwdContainerVisible: false
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
              ) {
                this.setState({
                  llChangePwdDummyShowContainerVisible: true,
                  edtMobileNoEnabled: false,
                  countryCodeEnabled: false
                });
                this.txtInputPhoneNumberProps.editable = false;
              }
            }
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session Expired", "Please Log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiChangePhoneValidation) {
          if (responseJson != null && responseJson.errors === undefined) {
            //On Change Phone Validation Success
            this.validateAndUpdateProfile();
          } else {
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (
          apiRequestCallId === this.apiCallMessageUpdateProfileRequestId
        ) {
          if (responseJson != null && responseJson.errors === undefined) {
            //On Change Phone Validation Success

            this.showAlert("Success", "Profile updated successfully.");
            this.enableDisableEditPassword(false);
            this.getUserProfile();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );

      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        this.authToken = sessionToken;

        this.getUserProfile();
      }
    }

    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start

  onTrendingPostRedirection = (id: number) => {
    let url = window.location.origin + `${'/CommunityForumWeb/' + id}`
    window.location.href = url;
  }

  showModal = () => {
    this.setState({
      isVisibleModal: !this.state.isVisibleModal,
    });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };


  showGalleryModal = () => {
    this.setState({
      isVisibleModal: false,
      isGalleryModelVisible: !this.state.isGalleryModelVisible,
    });
  };

  hideGalleryModal = () => {
    this.setState({ isVisibleModal: false, isGalleryModelVisible: false });
  };

  handleCreateGroupRedirection = () => {
    window.location.href = "CreateNewGroup";
  }

  onMoreClickHandler = (index: number | null, grpId: number | string) => {
    this.setState({ isMenu: !this.state.isMenu })
    this.setState({ currentIndex: index })
    this.setState({ groupIdMembers: grpId })
  }

  onMemberViewHandler = (groupName: string, members: number, id: number) => {
    this.setState({ isMember: !this.state.isMember, groupName: groupName, isMenu: false, members: members, isKabab: false })
    this.setState({ groupId: id }, () => this.getGroupMembersDetail())
  }
  handleMoreMember = (index: number) => {
    this.setState({ isKabab: !this.state.isKabab })
    this.setState({ membersIndex: index })
  }

  onKababCloseHandler = () => {
    if (this.state.isMenu === true || this.state.isKabab === true) {
      this.setState({ isMenu: false, isKabab: false })
    }
  }

  handleMakeMember = (accountId: number | string) => {
    const header = {
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.makeMemberApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupMmberReportEndPoint}${'/' + this.state.groupIdMembers + '/make_group_admin/' + accountId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleMemberReport = (accountId: number) => {
    const header = {
      "token": this.token
    };

    let formData: any = new FormData();
    formData.append('data[attributes][account_id]', accountId)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reportApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupMmberReportEndPoint}${'/' + this.state.groupIdMembers + '/report_on_group_member'}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleRemoveMemberApi = (id: any) => {
    this.setState({ mId: id })
    const headerObj = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeMemberApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupMmberReportEndPoint}${'/' + this.state.groupIdMembers + '/remove_member/' + id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerObj)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getGroupMembersDetail = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.groupMemberApiAddress = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupMmberEndPoint + `${'/' + this.state.groupId + '/group_members'}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onLeaveHandler = (id: number | string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.leaveGroupApiEndPoint}${'/' + id + '/leave_group'}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getProfileDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchUser
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getUserRecentActivities = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userRecentActivitiesApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userRecentActivitiesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getUserInterstsDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userInterestsApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchInterests
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  onSideBarNavigate(screen: string) {
    this.setState({
      profileSideBar: screen
    })
  }

  onuserCommentChange = (e: any) => {
    this.setState({ usercommentInput: e.target.value })
  }

  onReplyHandler = (index: number, idx: number) => {
    this.setState({ usercommentInput: "", isCommentEnable: false, commentIndex: index, replyIndex: idx })
  }

  onRepliedHandler = (index: number, idx: number) => {
    this.setState({ commentIndex: index, repiedIndex: idx, isReplied: true })
  }

  onClickCommentIndex = (index: number) => {
    this.setState({ commentActiveIndex: index, replyIndex: null })
  }

  onClickReplyComment = (index: number) => {
    this.setState({ commentActiveIndex: index, isCommentEnable: false })
  }

  onCommentChange = (event: any) => {
    this.setState({ isCommentActive: true, usercommentInput: event.target.value })
  }

  onReportModalClickHandler = (index: number) => {
    this.setState({ reportIndex: index, isReportModal: !this.state.isReportModal })
  }

  onMediaModalHandler = (item: any) => {
    this.setState({ isModalMedia: true, modalMediaData: item })
  }

  onMediaModalCloseHandler = () => {
    
    this.setState({ isModalMedia: false })
  }

  handleConfirmModalOpen = (id: string) => {
    this.setState({ confirmModalOpen: true, reportPostId: id });
  }

  handleConfirmModalSubmit = () => {
    this.setState({ confirmModalOpen: false });
    this.reportPost(this.state.reportPostId);
  }

  handleConfirmModalClose = () => {
    this.setState({ confirmModalOpen: false, reportPostId: "" });
  }

  reportPost = (id: number | string) => {

    const data = {
      post_id: id
    };
    const headerObj = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reportPostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reportApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerObj)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  allDownloadHandler = (mediaArr: any) => {
    mediaArr.forEach((mdia: any, index: number) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', mdia.url, true);
      xhr.responseType = 'blob';

      xhr.onload = () => {
        const blob = new Blob([xhr.response], { type: mdia.type });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = mdia.filename || 'downloaded-file';
        link.click();
      };
      xhr.send();
    })
  }

  playVideo = () => {
    document.addEventListener('play', function (e) {
      const videoElement = document.getElementsByTagName('video');
      for (let item of videoElement) {
        if (item != e.target) {
          item.pause();
        }
      }
    }, true);
  };

  playAudio = () => {
    document.addEventListener('play', function (e) {
      const audioElement = document.getElementsByTagName('audio');
      for (let item of audioElement) {
        if (item != e.target) {
          item.pause();
        }
      }
    }, true);
  };

  commentBoxVisibilityHandler = (index: number) => {
    this.setState({ usercommentInput: "" })
    this.setState({ commentIndex: index })
    this.setState({ isCommentEnable: true })
  }

  onUserCommentHandler = (id: number, commentId: number | null) => {
    this.setState({ isCommentActive: false })
    const validId = commentId ? commentId : id
    const type = commentId ? "BxBlockComments::Comment" : "BxBlockPosts::Post"
    const body = {
      data: {
        attributes: {
          comment: this.state.usercommentInput,
          commentable_id: validId,
          commentable_type: type
        }
      }
    }
    const header = {
      "token": this.token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.commentApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.commentApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ replyIndex: null })
    return true;
  }

  handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files![0]
    if (file.type === "image/jpg" || file.type === "image/png" || file.type === "image/jpeg") {
      this.setState({
        userSelectedImage: file,
      })
      this.handleSubmitImage(file)
    }
  };

  handleGallerySelect = (id: string) => {
    this.setState({
      userSelectedImage: id,
    })
  }

  commentApiResponseHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.commentApiAddress) {
      this.setState({ usercommentInput: "" })
      if (this.state.profileSideBar === SideBarScreenNames.postAboutMe) {
        this.getUserMyPostsDetails()
      } else {
        this.getUserRecentActivities()
      }
    }
  }

  userDataApiCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.userApiAddress) {
      if (responseJson != null) {
        if (responseJson.data) {
          let postsData = responseJson.data;
          this.setState({
            userData: postsData
          });
          window.localStorage.setItem('userInfo', JSON.stringify({ ...postsData.attributes, id: postsData.id }));
        }
      }
    }
  }

  userRecentActivitiesApiCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.userRecentActivitiesApiAddress) {
      if (responseJson != null) {
        if (responseJson.data) {
          let postsData = responseJson.data;
          this.setState({
            userRecentActivities: postsData
          });
        }
      }
    }
  }

  usermyPostsDataApiCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.usermyPostsApiAddress) {
      if (responseJson != null) {
        if (responseJson.data) {
          let postsData = responseJson.data;
          this.setState({
            userMyPosts: postsData
          });
        }
      }
    }
  }

  usetrendingPostsDataApiCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.trendingPostApiAdress) {
      if (responseJson != null) {
        if (responseJson.data) {
          this.setState({
            trendingPosts: responseJson.data
          });
        }
      }
    }
  }

  userPhotoLibraryApiCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.userPhotoLibraryApiId) {
      if (responseJson != null) {
        if (responseJson.data) {
          let postsData = responseJson.data;
          this.setState({
            userPhotoGallery: postsData
          });
        }
      }
    }
  }

  userMyGroupsApiCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.userMyGroupsApiAdress) {
      if (responseJson != null) {
        if (responseJson.data) {
          let postsData = responseJson.data;
          this.setState({
            myGroups: postsData
          });
        } else {
          this.setState({ myGroups: [] })
        }
      }
    }
  }
  reportApiHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.reportPostApiCallId) {
      this.setState({ reportPostId: "" });
      if (responseJson?.message) {
        this.setState({
          showSnackbar: true,
          message: responseJson.message,
          severity: "success",
        });
      } else {
        this.setState({
          showSnackbar: true,
          message: responseJson?.errors[0] || t('Something went wrong'),
          severity: "error",
        });
      }
    }
  }

  userJoinedGroupsApiCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.joinedGroupsApiAddress) {
      if (responseJson != null) {
        if (responseJson.data) {
          let postsData = responseJson.data;
          this.setState({
            joinedGroups: postsData
          });
        } else {
          this.setState({ joinedGroups: [] })
        }
      }
    }
  }

  leaveGroupApiHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.deleteApiAddress) {
      if (responseJson) {
        if (responseJson.errors) {
          this.setState({
            showSnackbar: true,
            severity: "error",
            message: responseJson.errors[0],
            isMenu: false
          });
        } else {
          this.getMyGroupsDeatils()
          this.getJoinedGroupsDeatils()
          this.setState({
            showSnackbar: true,
            severity: "success",
            message: responseJson.message,
            isMenu: false
          });
        }
      }
    }
  }

  mediaApiHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.mediaApiAddress) {
      if (responseJson != null) {
        if (responseJson.data) {
          let userMediaData = responseJson.data;
          this.setState({
            userMedia: userMediaData
          });
        }
      }
    }
  }

  groupMembersApiHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.groupMemberApiAddress) {
      if (responseJson != null) {
        if (responseJson.data) {
          let data = responseJson.data;
          this.setState({
            groupMembers: data
          });
        }
      }
    }
  }

  reportMemberApiHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.reportApiAddress) {
      this.getGroupMembersDetail()
      if (responseJson != null) {
        if (responseJson.message) {
          this.setState({
            isKabab: false,
            showSnackbar: true,
            severity: 'success',
            message: responseJson.message
          });
        }
      }
    }
  }

  makeAdminApiHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.makeMemberApiAddress) {
      this.getGroupMembersDetail()
      if (responseJson != null) {
        if (responseJson.message) {
          this.setState({
            isKabab: false,
            showSnackbar: true,
            severity: 'success',
            message: responseJson.message
          });
        }
      }
    }
  }

  removeMemberApiCallHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.removeMemberApiCallId) {
      if (responseJson) {
        if (responseJson.message) {
          const groupMembersData2 = this.state.groupMembers.filter((item: any) => item.id !== this.state.mId)
          this.setState({
            groupMembers: groupMembersData2,
            showSnackbar: true,
            message: t('Member removed successfully'),
            severity: "success"
          })
        } else {
          this.setState({
            showSnackbar: true,
            message: responseJson?.errors[0] || t('Something went wrong'),
            severity: "error",
          });
        }
      }
    }
  }

  async componentDidMount() {
    super.componentDidMount()
    localStorage.removeItem("edit_group")
    this.getProfileDetails()
    this.getUserMyPostsDetails()
    this.getTrendingPostsDetails()
    this.getMyGroupsDeatils()
    this.getUserPhotoGallery()
    this.getUserRecentActivities()
    this.getJoinedGroupsDeatils()
    this.setState({ endPoint: `account/user_images` }, () => this.groupMedia())
    this.groupMedia()
    this.getGroupMembersDetail()
    const userInfo = JSON.parse(localStorage.getItem("userInfo")!)
    this.setState({ userId: userInfo.id })
    i18next.changeLanguage(Language[userInfo.preferred_language.toLowerCase()]);
  }

  setCoverModal = () => {
    this.setState({
      coverModel: true,
    });
  }

  handleCloseSnackbar = () => {
    this.setState({
      severity: undefined,
      showSnackbar: false,
      message: "",
    });
    return true;
  };

  onShareInputHandler = (text: string) => {
    console.log("� ~ file: UserProfileBasicController.tsx:1215 ~ text:", text)
    let url = window.location.origin + `${'/CommunityForumWeb/' + text}`
    navigator.clipboard && navigator.clipboard.writeText(url);
    this.setState({
      showSnackbar: true,
      message: t('Copied'),
      severity: "success",
    });
  }

  onLikeHandler = (id: number, isLike: boolean, likeId: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    if (isLike) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.likesApiAddress = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.likesApiEndPoint}${'/' + likeId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteAPiMethodType
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      const body = {
        data: {
          attributes: {
            likeable_id: id,
            likeable_type: "BxBlockPosts::Post"
          }
        }
      }
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": this.token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.likesApiAddress = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.likesApiEndPoint}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethodType
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleSubmitImage = async (file?: File) => {
    let formData = new FormData();
    if (file && !this.state.isGalleryModelVisible) {
      formData.append(`data[attributes][${this.state.coverModel ? "cover_picture" : "photo"}]`, file);
      formData.append('content_type', "image");
    } else {
      formData.append('data[type]', this.state.userData.attributes.email);
      formData.append(this.state.coverModel ? "gallery_cover_photo_id" : 'gallery_photo_id', this.state.userSelectedImage);
    }
    const header = {
      'ngrok-skip-browser-warning': 'asf',
      token: this.token

    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateUserApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileUpdate
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.hideModal()
    this.hideGalleryModal()
    this.setState({ coverModel: false })
    this.getProfileDetails()
  }

  getUserMyPostsDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.usermyPostsApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchMyPosts
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getTrendingPostsDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.trendingPostApiAdress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.trendingPosts
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  likeApiHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.likesApiAddress) {
      if (this.state.profileSideBar === SideBarScreenNames.postAboutMe) {
        this.getUserMyPostsDetails()
      } else {
        this.getUserRecentActivities()
      }
    }
  }


  updateImageApiHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.updateUserApiId) {
      this.getProfileDetails()
      this.getUserPhotoGallery()
    }
  }



  getUserPhotoGallery = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userPhotoLibraryApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.phtoLibrary,

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getMyGroupsDeatils = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userMyGroupsApiAdress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.myGroups
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getJoinedGroupsDeatils = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.joinedGroupsApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.joinedGroups
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  groupMedia = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.mediaApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleEndPoint = (value: string) => {
    this.setState({ endPoint: value }, () => this.groupMedia())
  }

  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    }

  };

  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    }
  };

  txtInputPhoneNumberlWebProps = {
    onChangeText: (text: string) => {
      if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })

        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
      }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputEmailWebProps = {
    value: "",
    editable: true,
    onChangeText: (text: string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        this.txtInputEmailProps.value = text
      }
    }
  }

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;




  // Customizable Area End

}
