import React from "react";
import {
    Typography,
    Avatar,
    Divider,
    Menu,
    MenuItem,
  } from "@material-ui/core";
import {moreHoriz} from '../assets';
import {ConfirmModalBox} from "./confirmModalBox.web"
import { t } from "i18next";

export const SuperAdminComponent = ({account_id, userId, index, handleMore, itemId}:any) => {
  if(account_id && account_id.toString() !== userId.toString()){
    return <img data-test-id={"isMoreOpen"+index} src={moreHoriz} style={{cursor:'pointer'}} 
    onClick={(event) => handleMore(event, itemId, "open", "close")} />
  }else{
    return <></>
  }
}

export const AdminComponent = ({account_id, userId, group_admin, created_by_id, index, handleMore, itemId}:any) => {
  if((account_id && account_id.toString() !== userId.toString()) && 
  (account_id && account_id.toString() !== (group_admin && created_by_id && created_by_id.toString()))){
 return <img data-test-id={"isMoreOpen"+index} src={moreHoriz} style={{cursor:'pointer'}} 
 onClick={(event) => handleMore(event, itemId, "open", "close")} />
} else{
 return <></>
 }
}

export const GroupAdminComponent = ({accountId, user_id, index, item_id, handleMore}:any) => {
  if(accountId && accountId.toString() !== user_id.toString()){
    return <img data-test-id={"isMoreOpen"+index} src={moreHoriz} style={{cursor:'pointer'}} 
    onClick={(event) => handleMore(event, item_id, "open", "close")} />
  } else{
    return <></>
  }
}

export const HandleMoreComponent = ({created_by_id, userId, account_id, index, handleMore, itemId, group_admin, admin, superAdmin}:any) => {
  if(superAdmin){
   return <SuperAdminComponent account_id={account_id} userId={userId} index={index} handleMore={handleMore} itemId={itemId} />
  } 
  else if(admin){
   return <AdminComponent account_id={account_id} userId={userId} group_admin={group_admin} created_by_id={created_by_id} index={index}
    handleMore={handleMore} itemId={itemId} />
  }
  else if(!group_admin){
    return <GroupAdminComponent accountId={account_id} user_id={userId} index={index} item_id={itemId} handleMore={handleMore} />
  } 
  else{
    return <></>
  }
}

export const GroupMembersData = ({groupMembersData, handleMore, handleRemoveMemberApi, handleReportApi, group, userId, admin,
  handleMakeOrRevokeGroupAdmin, superAdmin
}:any) => {
  return(
    <>
    {groupMembersData && groupMembersData.length > 0 ? (
       groupMembersData.map((item: any, index: number) => (
        <>
        <div key={item} style={{display:"flex", alignItems:"center", justifyContent:"space-between", position:"relative"}} >
            <div style={{display:"flex", alignItems:"center", gap:"16px"}}>
                           <Avatar
                            alt="Jack Sparrow"
                            src={item.photo}
                            style={{width:"56px", height:"56px"}}
                            />
                        <div>
                          <span style={{display:"flex", gap:"8px"}}>
                            <Typography style={styles.followlistitem}>
                              {item.full_name}
                            </Typography>
                            {superAdmin && (
                               <Typography style={styles.followlistitem1}>
                                {t("(SUPER ADMIN)")}
                               </Typography>
                            )}
                            {!superAdmin && (
                                <Typography style={styles.followlistitem1}>
                                {item.group_admin ? t("(ADMIN)") : ""}
                              </Typography>
                            )}
                            </span>
                            <Typography style={styles.textSecond}>
                               {item.profession}
                            </Typography>
                        </div>
                       </div>
                       <div>
                        <HandleMoreComponent created_by_id={group && group.attributes && group.attributes.created_by_id}
                         userId={userId} account_id={item && item.account_id} index={index} handleMore={handleMore} itemId={item.id} 
                         group_admin={item.group_admin} admin={admin} superAdmin={superAdmin}
                        />
                        <Menu
                          data-test-id={"simpleMenu"+index}
                          id="simple-menu"
                          anchorEl={item.isMoreOpen}
                          keepMounted
                          open={Boolean(item.isMoreOpen)}
                          onClose={(event) => handleMore(event, item.id, "close", "close")}
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                        >
                          <MenuItem data-test-id={"report"+index} onClick={(event) => handleMore(event, item.id, "close", "report")}
                            disabled={item.isReported ? true : false}>
                              {item.isReported ? t("Reported") : t("Report")}
                           </MenuItem>
                          {(superAdmin || admin) && (
                            <MenuItem data-test-id={"remove"+index} onClick={(event) => handleMore(event, item.id, "close", "remove")}>{t("Remove")}</MenuItem>
                          )}
                          {(superAdmin || admin) && (
                             <MenuItem data-test-id={"makeOrRemove"+index} onClick={() => handleMakeOrRevokeGroupAdmin(group.id, item.account_id, 
                               item.makeOrRevokeGroupAdminText)}>
                               {item.makeOrRevokeGroupAdminText}
                              </MenuItem>
                          )}
                        </Menu>
            </div>
        </div>
        <Divider style={{marginTop:"16px", marginBottom:"16px"}} /> 
        <ConfirmModalBox item={item} index={index} handleMore={handleMore} handleRemoveMemberApi={handleRemoveMemberApi}
         handleReportApi={handleReportApi} group={group} />
        </>
    ))
    ) : (
      <Typography style={{textAlign:'center'}}>
        {t("No members found")}
      </Typography>
    )}
   </>
  )
}

const styles = {
    followlistitem:{
        color: "var(--neutrals-cool-gray-600, #475569)",
        fontFamily: "Comfortaa-Bold",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
    },
    followlistitem1:{
      color: "var(--neutrals-cool-gray-600, #475569)",
      fontFamily: "Comfortaa-Regular",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
  },
    textSecond:{
        color: "var(--neutrals-cool-gray-600, #475569)",
        fontFamily: "Nunito-Regular",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22px",
    }
}