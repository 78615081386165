import React from "react";
import {
  Typography,
  Avatar,
  Divider,
} from "@material-ui/core";
import { t } from "i18next";
export const GroupsData = ({ groups, handleJoinGroup, getGroupDetails }: any) => {
  const groupStatus2 = (item1:any) => {
    if(item1.status !== "accepted" && item1.request_type !== "requested" ){
      return t("Join Group")
    } else if( item1.status === "pending" && item1.request_type === "requested"){
      return t("Requested")
    } else{
      return t("Joined")
    }
  }
  return (
    <>
      {groups && groups.length > 0 ? (
        groups.map((item: any, index: number) => (
          <>
            <div key={item} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", position: "relative" }} >
              <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <Avatar
                  alt="Jack Sparrow"
                  src={item.attributes?.icon_image}
                  style={{ width: "56px", height: "56px" }}
                />
                <div data-test-id={"group-details"+index} onClick={() => getGroupDetails(item, "viewAllGroups")}
                  style={{ cursor: "pointer" , wordBreak: "break-word"}}>
                  <Typography style={styles.followlistitem}>
                    {item.attributes.name}
                  </Typography>
                  <Typography style={styles.textSecond}>
                    {item.attributes.profession}
                  </Typography>
                </div>
              </div>
              <div>
                <div data-test-id={"joinGroups" + index} className="joinGroupContainers"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    item.status !== "accepted" && item.status !== "pending" &&
                      handleJoinGroup(item, "joinFromViewAllGroups")
                  }}
                >
                  {item.status !== "accepted" && item.request_type !== "requested" && (
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M12.6668 8.66543H8.66683V12.6654H7.3335V8.66543H3.3335V7.33209H7.3335V3.33209H8.66683V7.33209H12.6668V8.66543Z"
                          fill="#475569" />
                      </svg>
                    </div>
                  )}
                  <Typography className="textSeconds">
                    {groupStatus2(item)}
                  </Typography>
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
          </>
        ))
      ) : (
        <Typography style={{ textAlign: 'center' }}>
          {t("No groups found")}
        </Typography>
      )}
    </>
  )
}

const styles = {
  followlistitem: {
    color: "var(--neutrals-cool-gray-600, #475569)",
    fontFamily: "Comfortaa-Bold",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
  },
  textSecond: {
    color: "var(--neutrals-cool-gray-600, #475569)",
    fontFamily: "Nunito-Regular",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
  }
}