import React from "react";
import {
    Typography,
    Container,
    Box,
    Avatar,
    Divider
} from "@material-ui/core";
import { Add } from '@material-ui/icons';
import '../../assets/Community.scss';
import {t} from 'i18next';

const RecentGroup = ({recentGroupsData,navigation,handleGroupJoin}: any) => {
    
    const handleGroupDetails = (groupData:any) => {
        localStorage.setItem('new_group',JSON.stringify(groupData))
        window.location.href = "AccountGroups";
    }

    const handleGroupRedirection = (type: string) => {
        if(type === "create_group"){
            window.location.href = "CreateNewGroup";
        }else{
            window.location.href = "ViewAllGroups";
        }
    }
    const groupStatus1 = (item:any) => {
        if(item.status !== "accepted" && item.request_type !== "requested" ){
          return t("Join Group")
        } else if( item.status === "pending" && item.request_type === "requested"){
          return t("Requested")
        } else{
          return t("Joined")
        }
      }
    return (
        <Container className="groupFollowContainer">
            <div className="recent-title-box">
                <Typography className="RecentGroupText">
                    {t('Recent Groups')}
                </Typography>
                <Typography test-id="group-create-id" onClick={()=>handleGroupRedirection("create_group")} className="RecentGroupTextSecond">
                <Add className="icon-style" />{t('Create new group')}
                </Typography>
            </div>

        {recentGroupsData?.map((item: any) => (
            <><div key={item.id}>
                <Box className="Groupstofollow">
                    <div className="boxContainer">
                        <div className="followlistitem">
                            <Avatar src={item.attributes.icon_image} />
                            <div className="followitemdetail">
                                <Typography test-id="group-redirect-id" onClick={() => handleGroupDetails(item)} className="filterTextName">
                                    {item.attributes.name}
                                </Typography>
                            </div>
                        </div>
                        <Box className="joingroupbox">
                            <div test-id="join-group-testid" className="joinGroupContainer"
                            onClick={() => item.status !== "accepted" && item.status !== "pending" &&
                            handleGroupJoin(item)}>

                                {item.status !== "accepted" && item.request_type !== "requested" && (
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                        >
                                            <path
                                                d="M12.6668 8.66543H8.66683V12.6654H7.3335V8.66543H3.3335V7.33209H7.3335V3.33209H8.66683V7.33209H12.6668V8.66543Z"
                                                fill="#475569" />
                                        </svg>
                                    </div>
                                )}
                                <Typography className="textSecond">
                                 {groupStatus1(item)}
                                </Typography>
                            </div>
                        </Box>
                    </div>
                </Box>
            </div><Divider className="joingroupdivider" /></>
        ))}

        <Box className="viewAllGroupContainer">
            <Typography test-id="groupview-redirect-id" onClick={()=>handleGroupRedirection("view_group")} className="ViewAlltext">{t('View all Groups')}</Typography>
            <div test-id="groupview-arr-id" className="viewArrow" onClick={()=>handleGroupRedirection("view_group")}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <path
                        d="M10.0002 3.3335L8.82516 4.5085L13.4752 9.16683H3.3335V10.8335H13.4752L8.82516 15.4918L10.0002 16.6668L16.6668 10.0002L10.0002 3.3335Z"
                        fill="#64748B" />
                </svg>
            </div>
        </Box>
    </Container>
    );
};

export default RecentGroup;
