import React from "react";
import {
    Typography,
} from "@material-ui/core";
import '../../assets/Community.scss';

const HashTag = ({ hashtagsData, handleHashTags }: any) => {
    
    return (
        <div className="tagbox">
            {hashtagsData && hashtagsData.map((item: any) => (
            <div test-id="tag-id" className="tags" key={item.id} onClick={() => handleHashTags(item.attributes.name, item.id)}>
                <Typography className="tag">{"#" + item.attributes.name}</Typography>
            </div>
            ))}
        </div>
    );
};

export default HashTag;
