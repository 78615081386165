
import * as React from 'react';
import {Tabs, Tab, Typography, Box} from '@material-ui/core';
import {GroupMediaData} from "./groupMediaData.web" 
import { t } from 'i18next';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, px: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const GroupMediaTabs = ({onDownloadHandler, groupMediaData, handleEndPoint, groupId, handlePostDetails}:any) => {
  const [value, setValue] = React.useState(0);
  const handleChange: any = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', padding:"24px 0 0" }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="tabsContent">
          <Tab data-test-id="images" className="tabLabel" label={t("Images")} {...a11yProps(0)} onClick={() => handleEndPoint(`group_posts/${groupId}/group_images`)}  />
          <Tab data-test-id="videos" className="tabLabel" label={t("Videos")} {...a11yProps(1)} onClick={() => handleEndPoint(`group_posts/${groupId}/group_videos`)} />
          <Tab data-test-id="audios" className="tabLabel" label={t("Audios")} {...a11yProps(2)} onClick={() => handleEndPoint(`group_posts/${groupId}/group_audios`)}/>
          <Tab data-test-id="documents" className="tabLabel" label={t("Documents")} {...a11yProps(3)} onClick={() => handleEndPoint(`group_posts/${groupId}/group_documents`)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <GroupMediaData groupMediaData={groupMediaData} type={"images"} handlePostDetails={handlePostDetails} onDownloadHandler={onDownloadHandler} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <GroupMediaData groupMediaData={groupMediaData} type={"videos"} handlePostDetails={handlePostDetails} onDownloadHandler={onDownloadHandler} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <GroupMediaData groupMediaData={groupMediaData} type={"audios"} handlePostDetails={handlePostDetails} onDownloadHandler={onDownloadHandler} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
       <GroupMediaData groupMediaData={groupMediaData} type={"documents"} handlePostDetails={handlePostDetails} onDownloadHandler={onDownloadHandler} />
      </CustomTabPanel>
    </Box>
  );
}