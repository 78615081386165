import React from "react";
import {
    Grid,
    Button,
    Input,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { t } from "i18next";

const HandleAddMoreFields = ({ list, handleOtherFields, category, handleFormFields }: any) => {
    return (
        <>
            {list && list.map((item: any, index: any) => (
                <Grid key={index} container className="other-fields">
                    <Grid item xs={6} sm={8}>
                        <Input
                            data-test-id={"addMore"+index}
                            value={item.name}
                            type={'text'}
                            placeholder={t("Type your interest here")}
                            className="add-more-text-box"
                            disableUnderline
                            name={'name'}
                            onChange={(e)=> handleOtherFields(e,category,index)}
                        />
                        <Input
                            disabled={true}
                            value={category}
                            type={'hidden'}
                            name={'interest_category'}
                        />
                    </Grid>
                    {index === 0 ?
                    <Grid item xs={6} sm={4} className="add-more-btn-div">
                        <Button data-test-id="addAnother" onClick={() => handleFormFields(index,category,"add")} 
                        className="add-more-btn to-add-more-btns" variant="contained" color="secondary">{t("Add another interest")}</Button>
                    </Grid>:
                    <Grid item xs={6} sm={4} className="add-more-btn-div">
                    <CloseIcon data-test-id={"close"+index} onClick={() => handleFormFields(index,category,"remove")} className="cross-btn"/>
                    </Grid>
                    }
                </Grid>
            ))}
        </>
    );
}
export default HandleAddMoreFields;
