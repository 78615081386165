import React from "react";
// Customizable Area Start
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { Grid, Button, Container, Card, CardContent, Typography } from '@material-ui/core';
import { groupImg, logoImg, whysideImg, whysideImg2, aboutus1, aboutus2,bubbleImg2, bubbleImg3Responsive, aboutus3, bubbleGroup, bubbleImg,bubbleGroupSecond } from "./assets";
import "../assets/landing.scss";
import ContactUsForm from "./components/ContactUsForm.web";
import AddressMap from "./components/AddressMap.web";
import {t} from "i18next";
// Customizable Area End

import LandingPageController, {
    Props,
} from "./LandingPageController";


export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <main>
                <div className="header-box">
                    <Container className="logo-container">
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item >
                                <div className="logo-item">
                                    <img src={logoImg} alt="" className="logo-image" />
                                    <Typography className="logo-content">
                                        {t('Connecting Collaborative Changemakers')}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item >
                                <Button onClick={() => this.handleBtnRedirection('signin')} className="login-btn btn-bold" variant="contained" color="primary">{t('Login To Platform')}</Button>
                                <Button onClick={() => this.handleBtnRedirection('signin')} className="login-btn-responsive btn-bold" variant="contained" color="primary">{t('Login now')}</Button>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="logo-box">
                    <Container className="logo-container">
                        <Grid container >
                            <div className="ellipse-div"></div>
                            <Grid className="group-box-responsive" item xs={12} lg={6} sm={12} md={6}>
                                <img className="group-img" src={groupImg} alt="" />
                            </Grid>
                            <Grid className="welcomeTextBox" item xs={12} lg={6} sm={12} md={6}>
                                <Typography className="welcome-title">{t('Welcome!')}</Typography>
                                <div className="underline1"></div>
                                <Typography className="welcome-content">
                                    {t('We are a growing global network of people from all walks of life and all parts of the world who work as collaborative change makers in organizations, communities, partnerships or as independent practitioners. We cultivate collaborative projects and broker partnerships using a variety of languages, tools and approaches.    We have developed this app to amplify courageous, principled, strategic, efficient and context specific collaborative practice.')}
                                </Typography>
                                <Typography className="welcome-content1">
                                    {t('Connect collaborative changemakers.')}
                                </Typography>
                                <Typography className="welcome-content1">
                                    {t('Scale transformational collaborative results.')}
                                </Typography>
                                <div className="network-btnbox">
                                <Button onClick={() => this.handleBtnRedirection('signup')} type="button" className="network-btn btn-bold" variant="contained" color="primary">{t('JOIN THE NETWORK')}</Button>
                                </div>
                            </Grid>
                            <Grid className="group-box" item xs={12} lg={6} sm={12} md={6}>
                                <img className="group-img" src={groupImg} alt="" />
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="community-box">
                    <Container className="logo-container">
                        <Typography className="community-title">{t('How to engage in this community')}</Typography>
                        <div className="underline2"></div>
                        <Typography className="welcome-content">{t('As collaborative changemakers…')}</Typography>
                        <Grid container spacing={2} className="community-card-div">
                            <Grid item xs={12} lg={3} sm={12} md={3}>
                                <Card className="community-card">
                                    <CardContent>
                                        <Typography className="community-sub-title">{t('We can often feel lonely')}</Typography>
                                        <li className="community-content"><span className="community-words">{t('Join')}</span> {t('communities of practice and aligned interest groups.')}</li>
                                        <li className="community-content">{t('Find experienced partnership practitioners in your area or fighting for the same cause')}</li>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={3} sm={12} md={3}>
                                <Card className="community-card">
                                    <CardContent>
                                        <Typography className="community-sub-title">{t('Contextualizing our approach is critical')}</Typography>
                                        <li className="community-content"><span className="community-words">{t('Exchange')}</span> {t('videos, images, documents and audios on the art and science of partnering practice that has worked in your context, in your language.')}</li>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={3} sm={12} md={3}>
                                <Card className="community-second-card">
                                    <CardContent>
                                        <Typography className="community-second-sub-title">{t('We benefit from recognition and resourcing of our role')}</Typography>
                                        <li className="community-content"><span className="community-words">{t('Contribute')}</span>  {t('your stories to the evidence base that partnership process management /conscious collaboration achieves better results')}</li>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={3} sm={12} md={3}>
                                <Card className="community-card">
                                    <CardContent>
                                        <Typography className="community-sub-title">{t('We need to stay at the cutting edge of professional development')}</Typography>
                                        <li className="community-content">{t('Share and find')} <span className="community-words">{t('formal training, mentoring')}</span> {t('and')} <span className="communityWords">{t('accreditation pathways')}</span> {t('both online and in person')}</li>
                                        <li className="community-content">{t('Share and find')} <span className="community-words">{t('job opportunities')}</span></li>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="why-box">
                <img className="bubbleImg1-responsive" src={bubbleGroup} alt="" height="120px" width="120px"/>
                <img className="bubbleImg2-responsive" src={bubbleImg3Responsive} alt="" height="120px" width="120px"/>
                    <Container className="logo-container">
                        <Grid container className="why-responsive" >
                            <Grid item xs={12} lg={6} sm={12} md={6} >
                                <img src={whysideImg} className="why-content-img2-responsive" alt="" height="290px"  />
                                <Typography className="why-title">{t('Why?')}</Typography>
                                <div className="underline3"></div>
                                <Typography className="why-content">{t('If people operating in all contexts deepen their capacity for courageous, strategic, contextual and culturally appropriate collaborative action, then collectively we can achieve breakthrough results from the bottom up and at scale.')}</Typography>
                                <img src={whysideImg2} className="why-content-img1" alt="" height="290px" />
                                </Grid>
                                <Grid item xs={12} lg={6} sm={12} md={6}>
                                <img src={whysideImg2} className="why-content-img1-responsive" alt="" height="290px" />
                                <img src={whysideImg} className="why-content-img2" alt="" height="290px"  />
                                <Typography className="why-content-second">{t('We envision a world where skilled and courageous individuals from all walks of life and all parts of the world are accelerating transformative shifts in behaviours and systems and co-creating more just and sustainable societies, through high quality, principled and impactful partnering.')}</Typography>
                                <Typography className="why-content">
                                    {t('Together we are exploring, exchanging and promoting effective multi-stakeholder partnering practice and principled collaborative leadership')}</Typography>
                                    <Button className="pocket-btn btn-bold" variant="contained" color="primary">{t('Pocket Guide to cultivating collaborative change')}</Button>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="about-us-box">
                    <img className="bubbleImg1" src={bubbleGroup} alt="" height="120px" width="120px"/>
                    <img className="bubbleImg2" src={bubbleGroupSecond} alt="" height="120px" width="120px"/>
                    <img className="bubbleImg3" src={bubbleImg} alt="" height="120px" width="120px"/>
                    <Container className="aboutus-container">
                        <Grid container spacing={2} className="community-card-div">
                            <Grid item xs={12} lg={4} sm={12} md={4}>
                                <Card className="community-card">
                                    <CardContent>
                                        <div className="aboutus-div">
                                        <img  className="aboutus-img" src={aboutus1} alt="" /></div>
                                        <Typography className="aboutus-content">{t('Come feel at home, be seen, be understood, find solidarity')}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={4} sm={12} md={4}>
                                <Card className="community-card">
                                    <CardContent>
                                        <div className="aboutus-div">
                                        <img  className="aboutus-img" src={aboutus2} alt="" /></div>
                                        <Typography className="aboutus-content">{t('Share wisdom, resources and inspiring stories')}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={4} sm={12} md={4}>
                                <Card className="community-card">
                                    <CardContent>
                                        <div className="aboutus-div">
                                        <img className="aboutus-img" src={aboutus3} alt="" />
                                        </div>
                                        <Typography className="aboutus-content">{t('Participate in a movement for transformational collaborative impact')}</Typography> 
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid> 
                        <div className="aboutus-bg">
                        </div> 
                        <div className="aboutus-btn">
                        <a style={{textDecoration: "none"}} target="_blank" href="//www.partnershipbrokers.org"><Button className="find-btn btn-bold" variant="contained" color="primary">{t('Find out more about us')}</Button></a>
                        </div>
                    </Container>
                </div>
                <div className="contact-us-box">
                    <Container className="contactus-container">
                        <div className="ellipse-div-second"></div>
                        <img className="bubbleImg4" src={bubbleImg2} alt="" height="143px" width="145px"/>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={6} sm={12} md={6}>
                                <ContactUsForm 
                                    state={this.state}
                                    handleSubmit={this.handleSubmit}
                                    handleChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6} sm={12} md={6}>
                               <AddressMap state={this.state}/>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="footer-box">
                    <Container className="footer-container">
                    <span className="footer-content">{t('PBA 2023. All rights reserved.')}</span>
                    </Container>
                </div>
            
                <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={this.state.showSnackbar}
                autoHideDuration={5000}
                onClose={this.handleCloseSnackbar}
                >
                    <MuiAlert
                    onClose={this.handleCloseSnackbar}
                    severity={this.state.severity}
                    >
                    {this.state.message}
                    </MuiAlert>
                </Snackbar>
            </main >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// export default withStyles(useStyles)(LandingPage);
// Customizable Area End
