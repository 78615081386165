import React from "react";
import {
    Typography,
    Modal,
    Button
  } from "@material-ui/core";
import { t } from "i18next";
export const ConfirmModalBox = ({item, index, handleMore, handleRemoveMemberApi, handleReportApi, group}:any) => {
    return(
      <Modal
      data-test-id={"modal"+index}
      open={item.isRemoveModalOpen || item.isReportModalOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      onClose={(event) => handleMore(event, item.id, "close", "close")}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
          padding: '16px',
          textAlign: 'center',
          borderRadius:"5px",
          width:"100%",
          maxWidth:"500px"
        }}
        className="confirm-modal"
      >
        <Typography>Are you sure, you want to {item.isRemoveModalOpen ? "remove" : "report"} member <i>{item.full_name}</i> ? </Typography>
          <div style={{marginTop:"20px", float:"right"}}>
          <Button style={{borderRadius:"5px", color:"white"}} data-test-id={"cancel"+index} variant="contained" color="secondary"
            onClick={(event) => handleMore(event, item.id, "close", "close")}
          >
            {t("Cancel")}
          </Button>   
          <Button style={{marginLeft:"10px"}} data-test-id={"remove1"+index} variant="contained" color="primary"
            onClick={() => item.isRemoveModalOpen ? handleRemoveMemberApi(group.id, item.account_id) : handleReportApi(group && group.id, item.account_id, item.id)}>
            {item.isRemoveModalOpen ? t("Remove") : t("Report")}
          </Button> 
         </div> 
        </div>
  </Modal>
    )
  }