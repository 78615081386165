import React from "react";
import {Chip} from "@material-ui/core";
import { Add, Done } from '@material-ui/icons';
import HandleAddMoreFields from "./handleAddMoreFields.web"
import { t } from "i18next";

export const InterestCard = ({interests, interestIds, handleSelectInterest, showOtherImpactFields, showOtherSkillsFields, handleShowFields,
    otherInterestImpactFields, handleFormFields, handleOtherFields, impactInterestUniqueError, otherInterestSkillsFields, skillsInterestUniqueError,
    userInterestError
}:any) => {
    const other1 = t("Other")
    const other2 = t("Other")
    return(
        <div style={{ flex: '1' }}>
        <div className="form-labels-interest">
        <div className="form-labels">{t("Interested in")}</div>
        </div>
            {
              Object.keys(interests).map((type: string, i: number) =>  (
               <>
                      <div className="form-labels-interest">
                        <div className="form-labels">{type == "impact_area" ? t("Impact Area") : t("Skills and Approaches")}</div>
                      </div>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
  
                          {
                              interests[type].map((data: any, indx: number) => (
                                  <Chip
                                     data-test-id={`chip-${indx}`}
                                      key={indx}
                                      className={interestIds && interestIds.includes(data.id) ? "chip-label-selected":"chip-label"}
                                      icon={interestIds && interestIds.includes(data.id) ? <Done className="icon-style-selected"/>:<Add className="icon-style" />}
                                      label={data.attributes.name.charAt(0).toUpperCase() + data.attributes.name.slice(1)}
                                      onClick={() => handleSelectInterest(data.id)}
                                      style={{ margin: '5px' }}
                                  />
                              ))
                          }
      
                          {
                              ((type === "impact_area" && showOtherImpactFields === true) || (type === "skills_and_approaches" && showOtherSkillsFields === true)) ?
                              <Chip
                              className={"chip-label-selected"}
                              icon={<Done className="icon-style-selected"/> }
                              onClick={() => handleShowFields(type)}
                              label={other1}
                              data-test-id={"chipClick1_"+i.toString()}
                              />:
                              <Chip
                              className={"chip-label"}
                              icon={<Add className="icon-style"/>}
                              onClick={() => handleShowFields(type)}
                              label={other2}
                              data-test-id={"chipClick2_"+i.toString()}
                              />
                          }
                          
                           {
                              type == "impact_area" && showOtherImpactFields === true &&
                              (
                                  <>
                                  <HandleAddMoreFields handleFormFields={handleFormFields} list={otherInterestImpactFields}
                                   handleOtherFields={handleOtherFields} category={type} />
                                  {impactInterestUniqueError && <p className="error-msg">{impactInterestUniqueError}</p>}
                                  </>
                              )
                          }
                          {
                              type == "skills_and_approaches" && showOtherSkillsFields === true &&
                              (
                                  <>
                                  <HandleAddMoreFields handleFormFields={handleFormFields} list={otherInterestSkillsFields}
                                   handleOtherFields={handleOtherFields} category={type} />
                                  {skillsInterestUniqueError && <p className="error-msg">{skillsInterestUniqueError}</p>}
                                  </>
                              )
                          }  
                     </div>
               </>
              ))
            }
               {userInterestError &&
                  <p className="error-msg">{userInterestError}</p>
               }
        </div> 
    )
}