import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Card,
  CardContent,
  Modal,
  TextField
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import { PinImage, Spinner, Unpin } from "./assets";
import { Calendar } from 'react-calendar';
import JoditEditor from "jodit-react"

const config={
    buttons: ['bold', 'italic', 'underline', 'ol', 'ul', 'indent', 'outdent'],
    buttonsMD: ['bold', 'italic', 'underline', 'ol', 'ul', 'indent', 'outdent'],
    buttonsSM: ['bold', 'italic', 'underline', 'ol', 'ul', 'indent', 'outdent'],
    buttonsXS: ['bold', 'italic', 'underline', 'ol', 'ul', 'indent', 'outdent'],
}
// Customizable Area End

import BulletinBoard2Controller, {
  Item,
  Props,
  configJSON,
} from "./BulletinBoard2Controller.web";
import { Value } from "react-calendar/dist/cjs/shared/types";

export default class BulletinBoard2 extends BulletinBoard2Controller {
  constructor(props: Props) {
    super(props);

    
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
   showPinnedItems = (item: Item, index: number) => {
    if(item.content_type === "text"){
      return(
        <>                  
        <div style={{float: 'right'}}>
          <IconButton
            data-test-id="unpinToggle"
            onClick={() => this.handleUnpinToggle(item)}
            color={"default"}
          >
            <img src={Unpin} />
          </IconButton>
        </div>
        <div dangerouslySetInnerHTML={{ __html: item.item }} />
      </>
      )
    } else{
      return(
        <>
        <div style={{float: 'right'}}>
        <IconButton
          data-test-id="unpinToggleImage"
          onClick={() => this.handleUnpinToggle(item)}
          color={"default"}
        >
          <img src={Unpin} />
        </IconButton>
        </div>
        <img src={item.item} alt={`Image ${index}`} height={100} style={{marginLeft:'43.5%'}}  />
        </>
      )
    }
   }

   showItems = (item: Item, index: number) => {
     if(item.content_type === "text"){
       if(item.editing){
        return(
          <>
            <JoditEditor
                data-test-id="editorForEdit"
                config={config}
                value={item.item}
                onChange={(value: string) => this.handleEditChangeForUnpinned(value, item.itemId)}
              />
            {item.error && (
              <Typography style={{ color: "red" }}>
                {item.error}
              </Typography>
            )}
            <div style={{marginTop: 5}}>
            <Button
              data-test-id="cancelBtn"
              variant="contained"
              color="secondary"
              style={{borderRadius:5, color:'white'}}
              onClick={() => this.handleCancelEditForUnpinned(item.itemId)}
            >
              {configJSON.titleCancel}
            </Button>
            <Button
              data-test-id="editBtn"
              variant="contained"
              color="primary"
              style={{ color: "white", borderRadius:5, marginLeft:5 }}
              onClick={() => this.handleEditForUnpinned(item)}
            >
              {configJSON.titleEdit}
            </Button>
            </div>
          </>
        )
       } else{
         return(
          <>
          <div style={{float: 'right'}}>
            <IconButton
              data-test-id={`pinToggle-${index}`}
              onClick={() => this.handlePinToggle(item)}
              color={"default"}
            >
              <img src={PinImage} />
            </IconButton>
            <IconButton
              data-test-id={`editToggle-${index}`}
              onClick={() =>
                this.handleEditToggleForUnpinned(item.itemId)
              }
              color="default"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              data-test-id={`deleteItem-${index}`}
              onClick={() =>
                this.handleDeleteItemForUnpinned(item.itemId)
              }
              color="default"
            >
              <DeleteIcon />
            </IconButton>
          </div>
          <div dangerouslySetInnerHTML={{ __html: item.item }} />
        </>
         )
       }
     } else{
      return(
        <>
        <div style={{ float: 'right'}}>
        <IconButton
            data-test-id={`pinToggleImage-${index}`}
            onClick={() => this.handlePinToggle(item)}
            color={"default"}
          >
            <img src={PinImage} />
          </IconButton>
          <IconButton
            data-test-id={`deleteImage-${index}`}
            onClick={() => this.handleDeleteItemForUnpinned(item.itemId)}
          >
            <DeleteIcon />
          </IconButton>
          </div>
          <img src={item.item} alt={`Image ${index}`} height={100} style={{marginLeft:'43.5%'}}  /> 
          </>
      )
     }
   }
  // Customizable Area End

  render() {
    const {items, pinnedItems, loading, error, isOpen, item} = this.state
    const date = this.state.date as Date;
      return (
        // Customizable Area Start
        <Container>
         <Typography style={{ textAlign: "center"}} variant="h6">
            {configJSON.labelTitleText}
          </Typography>
          <Box p={2}>
            <TextField
              data-test-id="toggleCalendar"
              label="Select Date"
              value={date.toDateString()}
              onClick={this.toggleCalendar}
              onFocus={this.toggleCalendar}
              InputProps={{
                readOnly: true
              }}
            />
            {isOpen && (
              <Box mt={2}>
                <Calendar data-test-id="calendar" onChange={(date: Value) => this.onChange(date)}  value={this.state.date} />
              </Box>
            )}
          </Box>
           <JoditEditor
            data-test-id="editor"
            config={config}
            value={item}
            onChange={(value: string) => this.handleChange(value)}
          />
          {error === configJSON.errorTitle && (
              <Typography style={{color: "red"}}>{error}</Typography>
          )}
          <Button
            data-test-id="submitBtn"
            variant="contained"
            color="primary"
            style={{width:"100%",marginTop:10}}
            onClick={this.handleSubmitItem}
          >
            {configJSON.btnExampleTitle}
          </Button>
          <input
            style={{marginTop:10}}
            data-test-id="fileInput"
            type="file"
            accept="image/*"
            onChange={this.handleImageSelect}
          />
          <div style={{textAlign:'right', marginTop:-25}}>{configJSON.titleImageFormat}</div>
          {pinnedItems.length > 0 && (
            <Typography variant="h6" style={{marginTop:10}}>{configJSON.titlePinned}</Typography>
          )}
           {
            pinnedItems.length > 0 && pinnedItems?.map((item: Item, index: number) => (
              <>
                <Card key={item.itemId} style={{
                  border:'1px solid gray',
                  borderRadius:'10px 10px 0 0',
                  marginTop:10
                }}>
                  <CardContent>
                    {this.showPinnedItems(item, index)}
                 </CardContent>
                </Card>
              </>
            ))
           }
          {pinnedItems.length > 0 && items.length > 0 && (
            <Typography variant="h6" style={{marginTop:10}}>{configJSON.titleOthers}</Typography>
          )}
          {loading && <img style={{marginTop:10, marginLeft:'38%'}} src={Spinner} width={300} height={200}/>}
          {!loading && ((pinnedItems.length > 0 || items.length > 0) ? (
                   items?.map((item: Item, index: number) => (
                    <>
                      <Card key={item.itemId} style={{
                        border:'1px solid gray',
                        borderRadius:'10px 10px 0 0',
                        marginTop:10
                    }}>
                       <CardContent>
                        {this.showItems(item, index)}
                       </CardContent>
                     </Card>
                  <div style={{marginBottom: 5}}></div>
                  <Modal
                    open={item.itemToDelete !== null}
                    onClose={() => this.handleCancelDeleteModalForUnpinned(item.itemId)}
                    aria-labelledby="delete-modal-title"
                    aria-describedby="delete-modal-description"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100vh",
                      }}
                    >
                      <Box
                        sx={{
                          bgcolor: "background.paper",
                          boxShadow: 24,
                          p: 4,
                          width: "400px",
                        }}
                      >
                        <Typography
                          id="delete-modal-title"
                          variant="h6"
                          component="div"
                        >
                          {configJSON.confirmDeleteTitle}
                        </Typography>
                        <Typography id="delete-modal-description">
                          {configJSON.warningTitle}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "10px",
                          }}
                        >
                          <Button
                            data-test-id={`cancelDeleteItem-${index}`}
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              this.handleCancelDeleteModalForUnpinned(item.itemId)
                            }
                          >
                            {configJSON.titleCancel}
                          </Button>
                          <Button
                          data-test-id={`confirmDeleteItem-${index}`}
                          variant="contained"
                            color="secondary"
                            onClick={() =>
                              this.handleConfirmDeleteItemForUnpinned(item.itemId)
                            }
                            style={{ marginLeft: 10, color:'white'}}
                          >
                            {configJSON.deleteBtnTitle}
                          </Button>
                        </div>
                      </Box>
                    </Box>
                  </Modal>
                </>
              ))
          ) : (
            <Typography style={{ textAlign: "center", marginTop:50}} variant="h6">
               {configJSON.titleNoItemsFound}
            </Typography>
          ))}
        </Container>
        // Customizable Area End
      );
  
  }     
}

// Customizable Area Start
// Customizable Area End
