import React from "react";
import {
    Avatar,
    Box,
    Container,
    Divider,
    Typography
} from "@material-ui/core";
import { GroupsData } from "../../blocks/CommunityForum/src/CommunityForumController";
import {t} from 'i18next';

export const GroupToFollow = ({ groupsData, navigation, handleGroupJoin }: { groupsData: GroupsData, navigation: any, handleGroupJoin: any }) => {


    const handleGroupDetails = (groupData: any) => {
        localStorage.setItem('new_group', JSON.stringify(groupData))
        window.location.href = "AccountGroups";
    }

    const handleViewGroupRedirection = () => {
        window.location.href = "ViewAllGroups";
    }

    const groupStatus1 = (item: any) => {
        if (item.status !== "accepted" && item.request_type !== "requested") {
            return t('Join Group')
        } else if (item.status === "pending" && item.request_type === "requested") {
            return t("Requested")
        } else {
            return t("Joined")
        }
    }

    return (
        <Container className="groupFollowContainer" style={{ marginTop: 0 }}>
            <Typography className="filterText">
                {t('Groups to follow')}
            </Typography>

            {groupsData?.slice(0, 5).map((item: any) => (
                <><div key={item}>
                    <Box className="Groupstofollow">
                        <div className="boxContainer">
                            <div className="followlistitem">
                                <Avatar src={item.attributes.icon_image} />
                                <div className="followitemdetail">
                                    <Typography onClick={() => handleGroupDetails(item)} className="filterTextName">
                                        {item.attributes.name}
                                    </Typography>
                                    {/* <Typography className="textSecond">
                                    Climate Change
                                </Typography> */}
                                </div>
                            </div>
                            <Box className="joingroupbox">
                                <div className="joinGroupContainer" onClick={() => item.status !== "accepted" && item.status !== "pending" &&
                                    handleGroupJoin(item)} >
                                    {item.status !== "accepted" && item.request_type !== "requested" && (
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                            >
                                                <path
                                                    d="M12.6668 8.66543H8.66683V12.6654H7.3335V8.66543H3.3335V7.33209H7.3335V3.33209H8.66683V7.33209H12.6668V8.66543Z"
                                                    fill="#475569" />
                                            </svg>
                                        </div>
                                    )}
                                    <Typography className="textSecond">
                                        {groupStatus1(item)}
                                    </Typography>
                                </div>
                            </Box>
                        </div>
                    </Box>
                </div><Divider className="joingroupdivider" /></>
            ))}

            <Box className="viewAllGroupContainer">
                <Typography onClick={handleViewGroupRedirection} className="ViewAlltext">{t('View all Groups')}</Typography>
                <div className="viewArrow" onClick={handleViewGroupRedirection} >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                    >
                        <path
                            d="M10.0002 3.3335L8.82516 4.5085L13.4752 9.16683H3.3335V10.8335H13.4752L8.82516 15.4918L10.0002 16.6668L16.6668 10.0002L10.0002 3.3335Z"
                            fill="#64748B" />
                    </svg>
                </div>
            </Box>
        </Container>
    )
}