export const bgImg = require("../assets/bg.png");
export const groupImg = require("../assets/groupImg.svg");
export const logoImg = require("../assets/logo.svg");
export const whybgImg = require("../assets/whybg.svg");
export const whysideImg = require("../assets/whysideimg1.png");
export const whysideImg2 = require("../assets/whysideimg2.png");
export const aboutus1 = require("../assets/aboutus1.svg");
export const aboutus2 = require("../assets/aboutus2.svg");
export const aboutus3 = require("../assets/aboutus3.svg");
export const contactusbg = require("../assets/contactusbg.svg");
export const addressDummy = require("../assets/addressDummymap.svg");
export const bubbleGroup = require("../assets/bubbleGroup.svg");
export const bubbleGroupSecond = require("../assets/bubbleGroupSecond.svg");
export const bubbleImg = require("../assets/bubbleImg.svg");
export const dummyplaceholder =require("../assets/dummyplaceholder.png");
export const bubbleImg2 =require("../assets/bubbleImg2.svg");
export const bubbleImg3Responsive =require("../assets/bubbleImg3-responsive.svg");