import React from "react";
import {
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Typography,
  Button
} from "@material-ui/core";
import {t} from "i18next";

const ContactUsForm = ({ state, handleSubmit, handleChange }: any) => {
 
  return (
    <form onSubmit={handleSubmit} className="contactus-form">
        <Grid
            container
        >
            <Grid item xs={12}>
                <Typography className="form-title">{t('Contact us')}</Typography>
                <div className="underline5"></div>
                <Typography className="form-sub-title">{t('Feel free to contact us on our help support, share your query and we will contact you as soon as possible')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <FormLabel className="form-label">
                        {t('Full Name')}
                    </FormLabel>
                    <TextField
                        value={state.ContactUsFormField.name}
                        type={'text'}
                        placeholder={t(`John Doe`)}
                        className="text-box"
                        variant="outlined"
                        name={'name'}
                        data-test-id={'name-test-id'}
                        onChange={handleChange}
                    />
                    {state.NameError &&
                    <p className="errorMsg">{state.NameError }</p>
                }
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <FormLabel className="form-label">
                        {t('Email Address')}
                    </FormLabel>
                    <TextField
                        value={state.ContactUsFormField.email}
                        type={'text'}
                        placeholder={t(`Johndoe@gmail.com`)}
                        className="text-box"
                        variant="outlined"
                        name={'email'}
                        data-test-id={'email-test-id'}
                        onChange={handleChange}
                    />
                     {state.EmailError  &&
                    <p className="errorMsg">{state.EmailError }</p>
                }
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <FormLabel className="form-label">
                        {t('Subject')}
                    </FormLabel>
                    <TextField
                        multiline
                        className="text-box"
                        rows={3}
                        variant="outlined"
                        name={'description'}
                        onChange={handleChange}
                        placeholder={t('Mention your query here')}
                        data-test-id={'description-test-id'}
                        value={state.ContactUsFormField.description}
                    />
                    {state.DescriptionError  &&
                    <p className="errorMsg">{state.DescriptionError }</p>
                }
                </FormControl>
                
            </Grid>
            <Grid item xs={12}>
                <Button type="submit" data-test-id={'btnAddExample'} className="submit-btn btn-bold" variant="contained" color="primary">{t('Submit query')}</Button>
            </Grid>
        </Grid>
    </form>
  );
};

export default ContactUsForm;
