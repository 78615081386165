import React from "react";
import {
    Box,
    Typography
  } from "@material-ui/core";
import {arrowBack, permMedia} from '../assets';
import {GroupMediaTabs} from "./groupMediaTabs.web"

import GroupMediaController, {
    Props,
  } from "../GroupMediaController.web";
import { ResponsiveRecentGroups } from "./responsiveRecentGroups.web";
import { t } from "i18next";
  
  export default class GroupMedia extends GroupMediaController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }
  
    // Customizable Area Start
    // Customizable Area End
  
    render() {
      const {groupMediaData, groupId} = this.state
        return(
            <>
                        <Box className="mediaPostss">
                          <Box className="recentPostsCardss">
                            <div style={{display:"flex", justifyContent:"space-between", alignItems:'center'}}>
                              <div style={{display:'flex'}}>
                               <img data-test-id="backFromMedia" src={arrowBack} onClick={() => this.props.handleOpen("AccountGroups")} style={{cursor:"pointer"}} />
                               <Typography className="groupName" style={{marginLeft:'8px'}}>
                                {this.props.group && this.props.group.attributes && this.props.group.attributes.name}</Typography>
                              </div>
                              <div style={{display:'flex'}}>
                               <img src={permMedia} />
                               <Typography className="media" style={{marginLeft:'8px'}}>{t("Media")}</Typography>
                              </div>
                            </div>
                            <GroupMediaTabs onDownloadHandler={this.onDownloadHandler} groupMediaData={groupMediaData} handleEndPoint={this.handleEndPoint}
                             groupId={groupId} handlePostDetails={this.props.handlePostDetails} />
                          </Box>
                        </Box>
                        <div style={{marginTop:"16px"}}>
                        <ResponsiveRecentGroups navigation={""} id={""} handleOpen={this.props.handleOpen} groups={this.props.groups}
                       handleJoinGroup={this.props.handleJoinGroup} getGroupDetails={this.props.getGroupDetails} recentGroups={this.props.recentGroups} />    
                        </div>
            </>
          )
    }
}

const styles = {
    groupContainer:{
      display:'flex',
      alignItems: 'center',
      justifyContent:'space-between',
      marginTop: '55px',
      width: "100%",
    },
  }