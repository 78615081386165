// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const Ellipseo1 = require("../assets/Ellipseo1.png");
export const moreHoriz = require("../assets/ic_baseline-more-horiz.png");
export const permMedia = require("../assets/ic_baseline-perm-media.png");
export const notification = require("../assets/mingcute_notification-fill.png");
export const groupIcon = require("../assets/material-symbols_group.png");
export const Ellipse = require("../assets/Ellipse.png");
export const Rectangle15 = require("../assets/Rectangle15.png");
export const Rectangle7 = require("../assets/Rectangle7.png");
export const galleryImage = require("../assets/galleryImage.svg");
export const uploadPhoto = require("../assets/uploadPhoto.svg");
export const fileUploadIcon = require("../assets/fileUploadIcon.svg");
export const edit = require("../assets/edit.svg");
export const arrowBack = require("../assets/arrowBack.svg");
export const addGroup = require("../assets/addGroup.svg");
export const search = require("../assets/search.svg");
export const groupIcons = require("../assets/joinGroup.png")
export const groupCover = require("../assets/groupCoverImage.png")
// Customizable Area End