import React from 'react'
import {
    Avatar,
    Typography
} from "@material-ui/core";

export const ResponsiveGroups = ({ groupsData, handleGroupDetails, handleGroupJoin, groupStatus1 }: any) => {
    return (
        <>
        {
            groupsData?.slice(0, 2).map((item: any) => (
                <div className="cards" key={item.id}>
                    <div className="infoContainer">
                        <Avatar src={item.attributes.icon_image} />
                        <div className="userData">
                            <Typography test-id="group-id" className="titleT" onClick={() => handleGroupDetails(item)}>
                                {item.attributes.name}
                            </Typography>
                        </div>
                    </div>
                    <div test-id="join-btn-id" className="joinButton" onClick={() => item.status !== "accepted" && item.status !== "pending" && handleGroupJoin(item)}>
                        {item.status !== "accepted" && item.request_type !== "requested" && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                            >
                                <path
                                    d="M13.1668 8.66536H9.16683V12.6654H7.8335V8.66536H3.8335V7.33203H7.8335V3.33203H9.16683V7.33203H13.1668V8.66536Z"
                                    fill="#475569"
                                />
                            </svg>
                        )
                        }
                        <Typography className="titleTSmall">
                            {groupStatus1(item)}
                        </Typography>
                    </div>
                </div>
            ))
        }
        </>
    )
}