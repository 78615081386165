import React, { useMemo } from "react";
// Customizable Area Start
import { Typography, Avatar, Button, Paper, Box, Divider } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AddIcon from '@material-ui/icons/Add';
import { GroupDetail } from './GroupDetail.web'
import {t} from "i18next";

interface UserGroupsProps {
    myGroups: any;
    user: any;
    joinedGroups: any;
    currentIndex: number | null;
    navigation: any;
    isMenu: boolean;
    onMoreClickHandler: any;
    onLeaveHandler: any;
    getGroupMembersDetail: any;
    groupMembers: any;
    isMember: boolean;
    members: number;
    groupName: string;
    userId: string | number;
    onMemberViewHandler: any;
    handleCreateGroupRedirection: any;
    handleMoreMember: any;
    handleMakeMember: any;
    handleMemberReport: any;
    membersIndex: number | string;
    handleRemoveMemberApi: any;
    isKabab: boolean;
}

const UserGroups = (props: UserGroupsProps) => {
    const { membersIndex, myGroups, joinedGroups, user, currentIndex, groupMembers, groupName, members, isKabab, userId, isMenu, isMember, handleRemoveMemberApi, handleMakeMember, onMemberViewHandler, handleMoreMember, onMoreClickHandler, onLeaveHandler, handleCreateGroupRedirection, handleMemberReport } = props
    const [valueTab, setValueTab] = React.useState(0);
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        const updatedValueTab = newValue;
        setValueTab(updatedValueTab);
    };
    const groupList = useMemo(() => {
        if (myGroups.length || joinedGroups.length) {
            if (valueTab === 0) {
                return myGroups;
            } else {
                return joinedGroups;

            }
        } else {
            return []
        }
    }, [myGroups, user, valueTab, joinedGroups])

    const handleGroupDetails = (groupData: any) => {
        localStorage.setItem('new_group', JSON.stringify(groupData))
        window.location.href = "AccountGroups";
    }

    return (
        <>
            {
                isMember ?
                    <Paper elevation={1} style={{ border: 'none', borderRadius: '8px' }}>
                        <GroupDetail
                            userId={userId}
                            isKabab={isKabab}
                            members={members}
                            groupName={groupName}
                            membersIndex={membersIndex}
                            groupMembers={groupMembers}
                            handleMakeMember={handleMakeMember}
                            handleMoreMember={handleMoreMember}
                            handleMemberReport={handleMemberReport}
                            onMemberViewHandler={onMemberViewHandler}
                            handleRemoveMemberApi={handleRemoveMemberApi}
                        />
                    </Paper>
                    :
                    <Paper elevation={1} style={{ border: 'none', borderRadius: '8px' }} >
                        <Box sx={{ width: '100%', border: 'none' }}>
                            <Box className='top-box-container'>
                                <Tabs test-id="tabs-id" value={valueTab} onChange={handleChangeTab}>
                                    <Tab label={t('My groups')} className='text_tab_first' />
                                    <Tab label={t('Joined Groups')} className="text_tab_second" />
                                </Tabs>
                                <Button startIcon={<AddIcon />} data-test-id="text_btn_create" className="text_btn_create" onClick={handleCreateGroupRedirection}>
                                    {t('Create new group')}
                                </Button>
                            </Box>
                        </Box>

                        <Box style={{ border: 'none' }}>
                            {
                                groupList.length > 0 ?
                                    groupList.map((ele: any, index: number) => {
                                        return (
                                            <List style={{ width: '100%', border: 'none' }} key={index}>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemAvatar>
                                                        <Avatar style={{ cursor: "pointer" }} test-id="group-icon" alt="Remy Sharp" src={ele.attributes.icon_image} onClick={() => handleGroupDetails(ele)} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        test-id="group-name"
                                                        onClick={() => handleGroupDetails(ele)}
                                                        primary={
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                className="text_first_group"
                                                            >
                                                                {ele.attributes.name}
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    className="text_second_group"

                                                                >
                                                                    {ele.attributes.group_type}
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                    <Box style={{ display: 'flex', gap: '5px' }}>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            style={{ marginTop: '0.5rem', color: '#475569' }}
                                                        >
                                                            <PeopleAltIcon />
                                                        </Typography>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            className="text_member"
                                                            style={{ marginTop: '0.5rem' }}
                                                        >
                                                            {ele.attributes.accounts.length} {ele.attributes.accounts.length > 1 ? t('Members') : t('Member')}
                                                        </Typography>
                                                        <Typography
                                                            test-id="more-id"
                                                            component="span"
                                                            variant="body2"
                                                            className="Horizicon_cls"
                                                            style={{ marginTop: '0.5rem' }}
                                                            onClick={() => onMoreClickHandler(index, ele.id)}
                                                        >
                                                            <MoreHorizIcon />
                                                        </Typography>
                                                        {
                                                            isMenu && currentIndex === index &&
                                                            <Box className='report-modal grp-kabab'>
                                                                {
                                                                    valueTab === 0 ?
                                                                        <Typography test-id="down-click-id" className='report-text' onClick={() => onMemberViewHandler(ele.attributes.name, ele.attributes.group_members, ele.id)}>{t('Add/Edit admin')}</Typography>
                                                                        :
                                                                        <Typography test-id="down-click-id-2" className='report-text' onClick={() => handleGroupDetails(ele)}>{t('View group')}</Typography>
                                                                }
                                                                <Divider style={{ width: "100%" }} />
                                                                <Typography test-id="delete-id" className='report-text' onClick={() => onLeaveHandler(ele.id)}>{t('Leave group')}</Typography>
                                                            </Box>
                                                        }
                                                    </Box>
                                                </ListItem>
                                                {index < (groupList.length - 1) && <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <hr style={{ margin: '8px 0', color: 'rgba(203, 213, 225, 0.28)', width: '95%' }} />
                                                </div>}
                                            </List>
                                        )
                                    }) :
                                    (
                                        <Typography style={{ textAlign: 'center' }}>
                                            {t('No members found')}
                                        </Typography>
                                    )
                            }
                        </Box>
                    </Paper>
            }
        </>

    );
};


// Customizable Area End

export default UserGroups;
