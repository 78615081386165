import React from "react";
import {Typography, Box, CardMedia, Grid, List, ListItem, ListItemAvatar, Avatar, ListItemText} from '@material-ui/core';
import { t } from "i18next";

export const GroupMediaData = ({groupMediaData, type, handlePostDetails, onDownloadHandler}:any) => {
    return(
        <Grid container spacing={3}>
        {groupMediaData && groupMediaData.length > 0 ? (
         groupMediaData.map((data:any) => (
           data.attributes[type].length > 0 && (
            data.attributes[type].map((item:any, index:any)=>(
             <Grid item xs={12} sm={6} >
               <div>
                   {type === "images" && (
                      <CardMedia
                      component="img"
                      height="140"
                      style={{
                        width: '100%',
                        height: 'auto',
                        maxHeight: '200px',
                        objectFit: 'contain',
                        margin: '0 auto',
                        borderRadius: '5px'
                    }}
                      src={item.url}
                      />
                   )}  
                   {type === "videos" && (
                         <video controls style={{ width: '100%', height: 'auto' }}>
                         <source src={item.url} type="video/mp4" />
                       </video>
                   )}
                   {type === "audios" && (
                      <audio controls style={{ width: '100%' }}>
                      <source src={item.url} type="audio/mpeg" />
                    </audio>
                   )}
                   {type === "documents" && (
                       <a href={item.url} target='_blank' style={{ display: "flex", textDecoration: "none" }}>
                       <div className="doc-box" style={{width:"100%"}}>
                       <Box className='PostImageLefts'>
                         <svg
                           width="30"
                           height="39"
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 90.48 122.88"
                         >
                           <path
                             d="M35.27.78a3,3,0,0,1,2-.78,1.54,1.54,0,0,1,.47.05h46.2A6.59,6.59,0,0,1,88.56,2a6.52,6.52,0,0,1,1.92,4.64v109.7a6.57,6.57,0,0,1-6.56,6.56H6.67a6.57,6.57,0,0,1-6.56-6.56v-78A3.07,3.07,0,0,1,0,37.56a3.19,3.19,0,0,1,1-2.24L34.9,1a1.5,1.5,0,0,1,.26-.21ZM84.65,6.62a.5.5,0,0,0-.21-.47A.67.67,0,0,0,84,5.94H40.22V31.62a8.89,8.89,0,0,1-8.91,8.91H6.1v75.79a.58.58,0,0,0,.2.47.69.69,0,0,0,.47.21H84a.58.58,0,0,0,.47-.21.73.73,0,0,0,.21-.47V6.62Zm-62,94.73a2.64,2.64,0,0,1,0-5.28h28a2.64,2.64,0,0,1,0,5.28Zm0-17.75a2.64,2.64,0,0,1,0-5.28H65.46a2.64,2.64,0,0,1,0,5.28Zm0-17.76a2.64,2.64,0,0,1,0-5.28H72a2.64,2.64,0,0,1,0,5.28Zm11.7-34.22V10.11L10.11,34.64h21.2a3.16,3.16,0,0,0,2.13-.88,3.06,3.06,0,0,0,.89-2.14Z" />
                         </svg>
                       </Box>
                       </div>
                     </a>  
                   )}
                     <div
                        style={{
                            width: '100%',
                            backgroundColor: 'background.paper',
                        }}
                    >
                        <List style={{ border: 'none' }}>
                            <ListItem alignItems="flex-start" style={{padding: '8px 0'}}>
                                <ListItemAvatar>
                                    <Avatar src={data.attributes.author_image} />
                                </ListItemAvatar>
                                <ListItemText>
                                 
                                        <React.Fragment>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    data-test-id="mediaAuthor"
                                                    style={{
                                                        display: 'inline',
                                                        fontFamily: 'Comfortaa-Bold',
                                                        fontSize: '14px',
                                                        fontWeight: 700,
                                                        lineHeight: '22px',
                                                        color: '#475569',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => handlePostDetails(data.id)}
                                                >
                                                    {data.attributes.author_name || "User"+index}
                                                </Typography>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        style={{
                                                            fontFamily: 'Nunito-Regular',
                                                            fontSize: '12px',
                                                            fontWeight: 400,
                                                            lineHeight: '18px',
                                                            color: '#475569',
                                                        }}
                                                    >
                                                     {data.attributes.created_at}
                                                    </Typography>
                                                </div>
                                                <svg data-test-id="onDownloadHandler" style={{cursor:"pointer"}} onClick={() => onDownloadHandler(item)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 110.9" width="16" height="16" fill="grey"><path d="M13.09,35.65h30.58V23.2l34.49,0v12.45l31.47,0L61.39,82.58L13.09,35.65L13.09,35.65z M61.44,97.88l47.51-0.14 l4.54-21.51l9.38,0.31v34.36L0,110.9V76.55l9.39-0.31l4.54,21.51L61.44,97.88L61.44,97.88L61.44,97.88z M43.67,0h34.49v4.62H43.67 V0L43.67,0z M43.67,9.32h34.49v9.44H43.67V9.32L43.67,9.32z"></path></svg>
                                            </div>
                                        </React.Fragment>
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                data-test-id="mediaDescription"
                                                style={{
                                                 cursor: 'pointer'
                                                }}
                                             //    style={{
                                             //        fontFamily: 'Nunito-Regular',
                                             //        fontSize: '12px',
                                             //        fontWeight: 400,
                                             //        overflow: "hidden",
                                             //        color: "var(--neutrals-cool-gray-600, #475569)",
                                             //        textOverflow: "ellipsis",
                                             //        whiteSpace: "nowrap",
                                             //        fontStyle: "normal",
                                             //        lineHeight: "18px"
                                             //      }}
                                             className="trending-desc"
                                             onClick={() => handlePostDetails(data.id)}
                                            >
                                                {data.attributes.description}
                                            </Typography>
                                        </React.Fragment>
                                    
                                </ListItemText>
                            </ListItem>
                        </List>
                       </div>
               </div>
             </Grid>
           )))
        ))) : (
            <Grid item xs={12} >
               <Typography className="not-found-text">{t(`No ${type} found`)}</Typography>
            </Grid>
        )
    }
        </Grid>
    )
}