import React from "react";
import {
    Paper,
    TextareaAutosize,
    TextField,
    FormControl,
    Select,
    MenuItem,
    FormLabel,
} from "@material-ui/core";
import Buttons from "./buttons.web";
import { InterestCard } from "./interestCard.web";
import { t } from "i18next";

export const RestCreateNewGroupForm = ({
      groupName, groupNameError, description, groupTypeError, value, interests, interestIds,
      showOtherImpactFields, showOtherSkillsFields, otherInterestImpactFields, impactInterestUniqueError, otherInterestSkillsFields,
      skillsInterestUniqueError, userInterestError, handleSetState, handleSelectInterest, handleShowFields, handleFormFields, handleOtherFields,
      handleOpen, selected_interest, uploadCoverPhoto, uploadProfilePhoto, handleOpenGroupType, handleCloseGroupType, openOtherComponent, iconPhotoKey,
      coverPhotoKey, removedInterestIds, selectedInterestIds
}:any) => {
    const data = {
      groupName, description, value, uploadCoverPhoto, uploadProfilePhoto, otherInterestImpactFields, interests, otherInterestSkillsFields,
      selected_interest, showOtherImpactFields, showOtherSkillsFields, interestIds, iconPhotoKey, coverPhotoKey, removedInterestIds, selectedInterestIds}
    return(
      <Paper elevation={0} style={{ padding: '20px'}} className="profile-desc"> 
      <div className="profile-form-sec" style={{ display: 'flex' }}>
      <div className="form-group-sec" style={{ marginRight: '20px', flex: '1' }}>
                <FormControl fullWidth>
                    <FormLabel className="form-labels">
                        {t("Group Name")}
                    </FormLabel>
                    <TextField
                        value={groupName}
                        type={'text'}
                        placeholder={t("Enter group name")}
                        variant="outlined"
                        name='group_name'
                        className="text-box"
                        onChange={(event) => handleSetState(event, "groupName", "text")}
                    />
                </FormControl>
                {!groupName &&
                  <p className="error-msg">{groupNameError}</p>
                }
               <FormControl fullWidth style={{marginTop:'20px'}}>
                    <FormLabel className="form-labels">
                      {t("Description")}
                    </FormLabel>
                    <TextareaAutosize
                        value={description}
                        style={styles.descriptionBox}
                        placeholder={t("Write group description here")}
                        name={'description'}
                        className="descriptionBox"
                        onChange={(event) => handleSetState(event, "description", "text")}
                    />
                </FormControl>
                  <FormControl variant="outlined" fullWidth style={{marginTop:'20px'}}>
                <FormLabel className="form-labels">
                    {t("Group Settings")}
                </FormLabel>
                <Select
                    value={value}
                    displayEmpty
                    label="group settings"
                    name="group_settings"
                    onOpen={handleOpenGroupType}
                    onClose={handleCloseGroupType}
                    onChange={(event) => handleSetState(event, "value", "text")}
                    className="dropdown-box group-dropdown-box"
                >    
                    <MenuItem className="dropdown-links" value="" disabled>{t("Anyone can join or invite only")}</MenuItem>
                    <MenuItem className="dropdown-links" value={"public"}><div>{t("Anyone can join")}</div></MenuItem>
                    <MenuItem className="dropdown-links" value={"private"}><div>{t("Invited only")}</div></MenuItem>
                </Select>
                </FormControl>
            {!value &&
              <p className="error-msg">{groupTypeError}</p>
             }
        </div> 
        <InterestCard interests={interests} interestIds={interestIds} handleSelectInterest={handleSelectInterest} showOtherImpactFields={showOtherImpactFields}
         showOtherSkillsFields={showOtherSkillsFields} handleShowFields={handleShowFields}
         otherInterestImpactFields={otherInterestImpactFields} handleFormFields={handleFormFields} handleOtherFields={handleOtherFields}
         impactInterestUniqueError={impactInterestUniqueError} otherInterestSkillsFields={otherInterestSkillsFields}
         skillsInterestUniqueError={skillsInterestUniqueError} userInterestError={userInterestError} />
      </div>
       <Buttons navigation={""} id={""} handleOpen={handleOpen} data={data} openOtherComponent={openOtherComponent} />
     </Paper> 
    ) 
}

const styles={
    descriptionBox:{
      height:'120px',
      display: 'flex',
      padding: '10px 16px',
      gap: '8px',
      background: '#FFFFFF',
      border: '1px solid #CBD5E1',
      borderRadius: '8px',
      width:'100%'
    }
  }