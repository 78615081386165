import React from 'react';
import {
    Avatar,
    Box,
    Container,
    Typography,
    Divider
} from "@material-ui/core";
import { t } from 'i18next';

export const RecentGroups = ({recentGroups, handleOpen, handleJoinGroup, getGroupDetails}:any) => {
    const groupStatus1 = (item:any) => {
        if(item.status !== "accepted" && item.request_type !== "requested" ){
          return t('Join Group')
        } else if( item.status === "pending" && item.request_type === "requested"){
          return t('Requested')
        } else{
          return t('Joined')
        }
      }
        return (
            <Container className="groupFollowContainer mediaContainer">
                <div style={{ display:'flex', alignItems:'center', justifyContent: 'space-between', width: '100%'}}>
                    <Typography className="filterText">
                        {t("Recent Groups")}
                    </Typography>
                        <div style={{  display:'flex', alignItems:'center', gap:'4px', cursor:'pointer'}}>
                                <svg
                                   xmlns="http://www.w3.org/2000/svg"
                                   width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                 fill="none"
                                 >
                                     <path
                                       d="M12.6668 8.66543H8.66683V12.6654H7.3335V8.66543H3.3335V7.33209H7.3335V3.33209H8.66683V7.33209H12.6668V8.66543Z"
                                      fill="#475569" />
                                </svg>
                             
                            <Typography data-test-id="handleOpen" className="textSecond" onClick={() => handleOpen("CreateNewGroup")}>
                               {t("Create new group")}
                             </Typography>
                         </div> 
                </div>
                {recentGroups && recentGroups.slice(0,5).map((item: any, index: number) => (
                    <>
                    <div key={item}>
                        <Box className="Groupstofollow">
                            <div className="boxContainer">
                                <div className="followlistitem">
                                    <Avatar
                                        alt="Jack Sparrow"
                                        src={item.attributes.icon_image} />
                                    <div className="followitemdetail">
                                        <Typography data-test-id={"groupDetails"+index} className="followlistitem" onClick={() => getGroupDetails(item, "recentGroups")}
                                            style={{cursor:"pointer"}}>
                                            {item.attributes.name}
                                        </Typography>
                                    </div>
                                </div>
                                <Box className="joingroupboxs">
                            <div data-test-id={"joinGroupss"+index} className="joinGroupContainerss" style={{cursor:"pointer"}}
                             onClick={() => item.status !== "accepted" && item.status !== "pending" &&
                              handleJoinGroup(item, "joinFromRecentGroups")}>
                                {item.status !== "accepted" && item.request_type !== "requested" && (
                                    <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            d="M12.6668 8.66543H8.66683V12.6654H7.3335V8.66543H3.3335V7.33209H7.3335V3.33209H8.66683V7.33209H12.6668V8.66543Z"
                                            fill="#475569" />
                                    </svg>
                                </div>
                                )}
                               <Typography className="textSecondss">
                                 {groupStatus1(item)}
                                </Typography>
                            </div>
                        </Box>
                            </div>
                        </Box>
                    </div>
                    <Divider className="joingroupdivider" />
                    </>
                ))}

                <Box data-test-id="handleOpen1" className="viewAllGroupContainer" style={{cursor:"pointer"}} onClick={() => handleOpen("ViewAllGroups")}>
                    <Typography className="textSecond">{t("View all Groups")}</Typography>
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M10.0002 3.3335L8.82516 4.5085L13.4752 9.16683H3.3335V10.8335H13.4752L8.82516 15.4918L10.0002 16.6668L16.6668 10.0002L10.0002 3.3335Z"
                                fill="#64748B" />
                        </svg>
                    </div>
                </Box>
            </Container>
        )
}