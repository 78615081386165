import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const { baseURL } = require("../../../framework/src/config");
import {t} from "i18next";

interface ContactUsInitialValues {
  name: string;
  email: string;
  phone_number: string;
  description: string
}
interface AddressFormInitialValues {
  address: string;
  location_link: string;
  map_image: string;
}
type Color = "success" | "info" | "warning" | "error" | undefined;

const ContactUsFormField: ContactUsInitialValues = { name: "", email: "", phone_number: "", description: "" };
const AddressMapFormField: AddressFormInitialValues = { address: "", location_link: "", map_image: "" };

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  ContactUsFormField: ContactUsInitialValues
  NameError: string;
  EmailError: string;
  DescriptionError: string;
  showSnackbar: boolean;
  severity: Color;
  message: string;
  loading: boolean;
  AddressFormField: AddressFormInitialValues
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetAddress: any;
  apiContactUs: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
   
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {

      ContactUsFormField: ContactUsFormField,
      NameError: "",
      EmailError: "",
      DescriptionError: "",
      showSnackbar: false,
      severity: undefined,
      message: "",
      loading: false,
      AddressFormField: AddressMapFormField,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.apiContactUs) {
        if (responseJson != null) {
          if (responseJson.data) {
            this.setState({
              showSnackbar: true,
              severity: "success",
              message: "Request sent successfully",
              ContactUsFormField: ContactUsFormField
            });
          } else {
              this.setState({
                showSnackbar: true,
                message: "Something went wrong",
                severity: "error",
                ContactUsFormField: ContactUsFormField
              }); 
          }
        }
      }
      if (apiRequestCallId === this.apiGetAddress) {
        if (responseJson != null) {
          if (responseJson.data) {
            let addressData: AddressFormInitialValues = responseJson.data.attributes;
            this.setState({
              AddressFormField: {
                address: addressData.address,
                location_link: addressData.location_link,
                map_image: baseURL+''+addressData.map_image
              }
              });
          } else {
            this.setState({
              AddressFormField: AddressMapFormField
            });
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getAddress();
  }

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  handleChange = (e: any) => {
    let value = e.target.value;
    this.setState({ ContactUsFormField: { ...this.state.ContactUsFormField, [e.target.name]: value.trimStart() } });
  };

  handleValidation = (formState: ContactUsInitialValues) => {
    let isValid = true;
    const regex = /^[^-\s][a-zA-Z0-9_\s-]{2,40}[^-\s]$/;
    if (formState.name === "") {
      this.setState({ NameError: (t('Name is required')) });
      isValid = false;
    } else if (regex.test(formState.name) === false) {
      this.setState({
        NameError: (t('Name is invalid'))
      });
      isValid = false;
    } else {
      this.setState({ NameError: '' });
    }
    const emailregex =  /^[A-Za-z0-9.+-]+@[A-Za-z.-]+\.[A-Za-z]{2,3}$/i;
    if (formState.email === "") {
      this.setState({
        EmailError: (
          t('Email is required')
        )
      });
      isValid = false;
    } else if (emailregex.test(formState.email) === false) {
      this.setState({
        EmailError: (t('Email is invalid')
        )
      });
      isValid = false;
    } else {
      this.setState({ EmailError: '' });
    }

    if (formState.description === "") {
      this.setState({
        DescriptionError: (
          t('Description is required')
        )
      });
      isValid = false;
    } else {
      this.setState({ DescriptionError: '' });
    }
    return isValid;
  };

  handleBtnRedirection = (type: string) => {
    localStorage.setItem("formType", type);
    this.props.navigation.navigate("EmailAccountRegistration");
  }

  handleSubmit = (e: any) => {
    e.preventDefault();
    if (this.handleValidation(this.state.ContactUsFormField)) {
      this.contactUs(this.state.ContactUsFormField);
      return true;
    }
  };

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  contactUs = (data: any) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiContactUs = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.contactusAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAddress = () => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addressMapAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
