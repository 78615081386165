import i18next from "i18next";
import english from "./english.json";
import bengali from "./bengali.json";
import french from "./french.json";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-xhr-backend';

export default i18next.use(initReactI18next).use(Backend).init({
  compatibilityJSON: 'v3',
  lng: "en",
  fallbackLng: 'en',
  resources: {
    English: english,
    en: english,
    'en-US': english,
    en_US: english,
    fr_US: french,
    fr: french,
    French: french,
    french: french,
    bengali: bengali,
    bn: bengali,
    Bengali: bengali,
    bn_US: bengali,
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});