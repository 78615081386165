import React from "react";
// Customizable Area Start
import { Button, TableBody, Table, TableCell, TableRow } from "@material-ui/core";
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import {t} from "i18next";

interface UserProfileInterface {
    user: any
}
const UserAboutMe = (props: UserProfileInterface) => {
    const { user } = props
    return (
        <Table aria-label="simple table about-me-container" >
            <TableBody>
                <TableRow>
                    <TableCell className="text_first_tb">{t('Full Name')} </TableCell>
                    <TableCell align="right" className="text_second_tb">{!!user.full_name && user.full_name}</TableCell>
                </TableRow>
                <TableRow >
                    <TableCell align="left" className="text_first_tb" >{t('Lives in')}</TableCell>
                    <TableCell align="right" className="text_second_tb" >{!!user.city && user.city} {!!user.city && ","}{!!user.state && user.state}{!!user.state && ","}{!!user.country && user.country}</TableCell>
                </TableRow>
                <TableRow >
                    <TableCell align="left" className="text_first_tb">{t('Preferred Language')}</TableCell>
                    <TableCell align="right" className="text_second_tb">{!!user.preferred_language && t(user.preferred_language)}</TableCell>
                </TableRow>
                <TableRow >
                    <TableCell align="left" className="text_first_tb" >{t('Gender')}</TableCell>
                    <TableCell align="right" className="text_second_tb">{!!user.gender && t(user.gender)}</TableCell>
                </TableRow>
                <TableRow >
                    <TableCell align="left" className="text_first_tb" >{t('Email Address')}</TableCell>
                    <TableCell align="right" className="text_second_tb">{!!user.email && user.email}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                        align="left"
                        className="text_first_tb"
                    >
                        <div>{t('Interests')}</div>
                    </TableCell>
                    <TableCell
                        align="right"
                        className="multiple-item text_second_tb"
                    >  
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "flex-end",
                        }}>
                    {user.interest_name && user.interest_name.length ? user.interest_name.map((item:any) => {
                        return (<div style={{ marginInline:"5px",width:"max-content" }}><Button className="text_button_main"
                        variant="contained"
                        startIcon={<CheckOutlinedIcon />}
                    >
                        <div className="tag-interest">{item.name}</div>
                    </Button></div>)
                    }) : <Button>
                        &nbsp;
                </Button>}
                </div>
                    </TableCell>
                </TableRow>
                {user.partnership_practitioner && <TableRow >
                    <TableCell align="left" className="text_first_tb" >{t('What kind of partnership practitioner am i?')}</TableCell>
                    <TableCell align="right" className="text_second_tb">{user.partnership_practitioner}</TableCell>
                </TableRow>}
                {user.accredited_partnership_broker && <TableRow >
                    <TableCell align="left" className="text_first_tb">{t('Accredited partnership broker')}</TableCell>
                    <TableCell align="right" className="text_second_tb">{user.accredited_partnership_broker ? t('Yes'): t('No')}</TableCell>
                </TableRow>}
                {user.organizational_affilation && <TableRow >
                    <TableCell align="left" className="text_first_tb">{t('Organizational affiliations')}</TableCell>
                    <TableCell align="right" className="text_second_tb">{user.organizational_affilation}</TableCell>
                </TableRow>}
            </TableBody>
        </Table>
    );
};
// Customizable Area End

export default UserAboutMe;
