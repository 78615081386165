import React from "react";
import {
    Typography,
    Avatar,
    Divider,
  } from "@material-ui/core";
import { t } from "i18next";

export const OtherMembers = ({otherMembersData, handleSendInvite}:any) => {
  return(
    <>
    {otherMembersData && otherMembersData.length > 0 ? (
       otherMembersData.map((item1: any, index: number) => (
        <>
        <div key={item1} style={{display:"flex", alignItems:"center", justifyContent:"space-between", position:"relative"}} >
            <div style={{display:"flex", alignItems:"center", gap:"16px"}}>
                           <Avatar
                            alt="Jack Sparrow"
                            src={item1.photo}
                            style={{width:"56px", height:"56px"}}
                            />
                        <div>
                          <span style={{display:"flex", gap:"8px"}}>
                            <Typography style={styles1.followlistitem2}>
                              {item1.full_name}
                            </Typography>
                            </span>
                            <Typography style={styles1.textSecond1}>
                               {item1.profession}
                            </Typography>
                        </div>
                       </div>
                       <div>
                       <div>
                <div className="joinGroupContainers" data-test-id={"sendInvite"+index}
                  style={{ cursor: "pointer" }} onClick={() => handleSendInvite(item1.id)}
                >
                  <Typography className="textSeconds">
                    {item1.is_invited !== null ? t("Invited"):t("Invite")}
                  </Typography>
                </div>
              </div>
                   
            </div>
        </div>
        <Divider style={{marginTop:"16px", marginBottom:"16px"}} /> 
        </>
    ))
    ) : (
      <Typography style={{textAlign:'center'}}>
        {t("No members found")}
      </Typography>
    )}
   </>
  )
}

const styles1 = {
    followlistitem2:{
        color: "var(--neutrals-cool-gray-600, #475569)",
        fontFamily: "Comfortaa-Bold",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
    },
    textSecond1:{
        color: "var(--neutrals-cool-gray-600, #475569)",
        fontFamily: "Nunito-Regular",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22px",
    },
    container:{
      display:"flex", alignItems:"center", justifyContent:"space-between", position:"relative"
    }
}