import React from "react";
import { configJSON } from "../UserProfileBasicController";
import { download } from "../assets";
import { IconButton, Typography } from "@material-ui/core";
import {t} from "i18next";
interface ImageSelectionProps {
    showModal: () => void
}
export const ImageSelectInputWithIcon = (props: ImageSelectionProps) => (<><div
    className="hover-edit-profile "
>
    <IconButton
        style={{ marginRight: '0px', width: "16px", height: "16px", padding: "0px" }}
        aria-label={configJSON.uploadProfilePhoto}
        onClick={props.showModal}
    >
        <img className="profile-photo" src={download} />
    </IconButton>
    <Typography variant="body2"
        style={{
            fontFamily: 'Comfortaa-Bold',
            color: '#475569',
            fontSize: '10px',
            fontWeight: 400,
            lineHeight: '11.15px',
        }}>
        {t('Upload Profile Photo')}
    </Typography>
</div></>)