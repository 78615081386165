import React from "react";
import {
    Button
} from "@material-ui/core";
import '../../assets/Community.scss';
import { t } from "i18next";

export const Edit = ({ state, onEditApiHandler }: any) => {
    return (
        state.existedMedia.length === 0 && state.postInput == '' && state.postMedia.length === 0 ?
            <Button className="disable-post-btn" variant="contained" color="primary">{t('Post')}</Button> :
            <Button variant="contained" color="primary" onClick={onEditApiHandler}>{t('Post')}</Button>
    )
}

export const Post = ({ state, postApiHandler }: any) => {
    return (
        state.postMedia.length === 0 && state.postInput == '' ?
            <Button className="disable-post-btn" variant="contained" color="primary">{t('Post')}</Button> :
            <Button variant="contained" color="primary" onClick={postApiHandler}>{t('Post')}</Button>
    )
}

export const PostButton = ({ state, postApiHandler, onEditApiHandler }: any) => {
    return (
        <>
            {
                state.isEdit ? <Edit state={state} onEditApiHandler={onEditApiHandler}/> : <Post state={state} postApiHandler={postApiHandler} />
            }
        </>
    );
};
