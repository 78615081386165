import React from "react";
import {
    Grid,
    Typography,
    Container, Button,Avatar,TableBody, Table, TableCell, TableRow,TableContainer, Paper
} from "@material-ui/core";
import RightBar from '../../../../components/src/RightBar.web';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { configJSON } from "../CommunityForumController";
import { Rectangle5 } from "../../../user-profile-basic/src/assets"
import "../../../user-profile-basic/assets/userprofilebasic.scss";
import {t} from "i18next";

const UserProfileDetails = ({ state,handlePostDetails }: any) => {
    const userData = state.userSearchProfile;
    return (
        <main>
            <div
                className="user-profile-conatiner"
            >

                <Container className="continer-profile-padd" maxWidth="lg" style={{
                    padding: '10px 35px !important',
                    maxWidth: '1630px',
                }}>

                    <Grid container >
                        <Grid item xs={12} lg={12} sm={12} md={12}>
                            <div className="banner-section"
                            >
                                <img
                                    src={(userData.attributes.cover_picture) || Rectangle5}
                                    alt="Banner"
                                    className="banner-image"
                                />

                                <div className="hover-edit-cover hide">
                                    <Typography
                                        className="upload-text-profile edit-button-text " variant="body2"
                                    // style={{ marginRight: '0px',width:"16px",height:"16px",padding:"0px" }}
                                    >
                                        {configJSON.uploadCoverPhoto}
                                    </Typography>
                                </div>
                            </div>
                            <div className="profile-details">
                                <div className="mobile-view">
                                    <Avatar
                                        src={userData.attributes.photo}
                                        alt="Profile Picture"
                                        style={{
                                            marginTop: "20px",
                                            width: 120,
                                            height: 120,
                                        }}
                                    />
                                </div>
                                <div className="header-section">
                                    <Avatar
                                        src={userData.attributes.photo}
                                        alt="Profile Picture"
                                        style={{
                                            width: 120,
                                            height: 120,
                                        }}
                                    />
                                </div>
                                <Typography variant="h1" align="center"
                                    style={{

                                        fontFamily: 'Comfortaa-Bold', fontWeight: 700, marginTop: '20px',
                                        fontSize: '20px', lineHeight: '28px', color: '#475569',
                                    }}>
                                    {userData.attributes.full_name}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: '20px' }}>
                        <Grid item xs={12} md={3} lg={3} xl={3} sm={12} >
                            <Paper elevation={3}  >
                            <Typography className="active-sidebar" style={{paddingTop:'6px', lineHeight: '24px', height: '24px', color: '#475569', fontFamily: 'Comfortaa-Bold', fontSize: '16px', fontWeight: 400 }}>
                            {t('About me')}
                            </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6} xl={6}  >
                            <TableContainer component={Paper} style={{ borderRadius: '8px' }}>
                                <Paper elevation={3} className="main_section_about_me" >
                                    <Table aria-label="simple table about-me-container" >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className="text_first_tb">{t('Full Name')} </TableCell>
                                                <TableCell align="right" className="text_second_tb">{!!userData.attributes.full_name && userData.attributes.full_name}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="left" className="text_first_tb" >{t('Lives in')}</TableCell>
                                                <TableCell align="right" className="text_second_tb" >{!!userData.attributes.city && userData.attributes.city} {!!userData.attributes.city && ","}{!!userData.attributes.state && userData.attributes.state}{!!userData.attributes.state && ","}{!!userData.attributes.country && userData.attributes.country}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="left" className="text_first_tb">{t('Preferred language')}</TableCell>
                                                <TableCell align="right" className="text_second_tb">{!!userData.attributes.preferred_language && userData.attributes.preferred_language}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="left" className="text_first_tb" >{t('Gender')}</TableCell>
                                                <TableCell align="right" className="text_second_tb">{!!userData.attributes.gender && userData.attributes.gender}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="left" className="text_first_tb" >{t('Email Address')}</TableCell>
                                                <TableCell align="right" className="text_second_tb">{!!userData.attributes.email && userData.attributes.email}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    align="left"
                                                    className="text_first_tb"
                                                >
                                                    <div>{t('Interests')}</div>
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    className="multiple-item text_second_tb"
                                                >
                                                    <div style={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        justifyContent: "flex-end",
                                                    }}>
                                                        {userData.attributes.interest_name && userData.attributes.interest_name.length ? userData.attributes.interest_name.map((item: any) => {
                                                            return (<div style={{ marginInline: "5px", width: "max-content" }}><Button className="text_button_main"
                                                                variant="contained"
                                                                startIcon={<CheckOutlinedIcon />}
                                                            >
                                                                <div className="tag-interest">{item.name}</div>
                                                            </Button></div>)
                                                        }) : <Button>
                                                            &nbsp;
                                                        </Button>}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="left" className="text_first_tb" >{t('What kind of partnership practitioner am i?')}</TableCell>
                                                <TableCell align="right" className="text_second_tb">{!!userData.attributes.partnership_practitioner && userData.attributes.partnership_practitioner}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="left" className="text_first_tb">{t('Accredited partnership broker')}</TableCell>
                                                <TableCell align="right" className="text_second_tb">{userData.attributes.accredited_partnership_broker ? "True" : "False"}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="left" className="text_first_tb">{t('Organizational affiliations')}</TableCell>
                                                <TableCell align="right" className="text_second_tb">{!!userData.attributes.organizational_affilation && userData?.attributes.organizational_affilation}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </TableContainer>

                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <RightBar handlePostDetails={handlePostDetails} topTrendingData={state.topTrendingData} />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </main>
    );
}
export default UserProfileDetails;