import React from "react";
import {
    Chip
} from "@material-ui/core";
import { Add, Done } from '@material-ui/icons';
import HandleAddMoreView from "../../../email-account-registration/src/Components/AddmoreFields";
import {t} from "i18next";


const SkillsArea = ({state, handleShowFields,indx, type, handleAddFormFields,handleOtherFields, handleRemoveAddMoreFields}: any) => {
    
    return (
        <>
            <Chip
            className={ state.showOtherSkillsFields === true ? "chip-label-selected" : "chip-label"}
            icon={state.showOtherSkillsFields === true ? <Done className="icon-style-selected" />:<Add className="icon-style" /> }
            onClick={() => handleShowFields(type)}
            label={t('Other')}
            data-test-id={"chipClick1_" + indx.toString()}
            /> 
            {state.showOtherSkillsFields === true && <HandleAddMoreView handleRemoveAddMoreFields={handleRemoveAddMoreFields} list={state.otherInterestSkillsFields} handleOtherFields={handleOtherFields} handleAddFormFields={handleAddFormFields} category={type} />}
        
        </>
    );
};

export default SkillsArea;
