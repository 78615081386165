import React from "react";

// Customizable Area Start


import TermsConditionsController, {
    Props,

} from "./TermsConditionsController";
import "../assets/terms.scss";
import TermsCondition from "./components/TermCondition.web";
import PrivacyPolicy from "./components/PrivacyPolicy.web";
import WebSubHeader from "../../../components/src/WebSubHeader.web";
// Customizable Area End




export default class TermsConditions extends TermsConditionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const currentUrl = window.location.pathname;
        // Customizable Area End
        return (
            // Customizable Area Start
            <main>
                <WebSubHeader />

                {currentUrl === "/TermsConditionWeb" ? (
                    <TermsCondition state={this.state} />
                ) : currentUrl === "/PrivacyPolicyWeb" ? (
                    <PrivacyPolicy state={this.state} />
                ) : null}

                {/* You can remove the line below since it's already covered by the condition */}
                {/* <TermsCondition state={this.state} /> */}
            </main>
            // Customizable Area End
        );
        // Customizable Area End
    }
}

// Customizable Area Start

// Customizable Area End
