import React from "react";
import {
    Typography,
} from "@material-ui/core";
import '../../assets/Community.scss';

const TranslationCore = ({ item, filter, handleTranslation, handleTransBackToOriginal }: any) => {
    return (
        <>
            {
                filter?.length ? filter[0] &&
                    <div test-id="translation-back-id" className="recentTags reply-btn" onClick={() => handleTransBackToOriginal(item.id)}>
                        <div className="rtag">
                            <Typography className="rtagText">
                                See original
                            </Typography>
                        </div>
                    </div>
                    :
                    <div test-id="translation-id" className="recentTags reply-btn" onClick={() => handleTranslation(item.attributes.description, item.id)}>
                        <div className="rtag">
                            <Typography className="rtagText">
                                See translation
                            </Typography>
                        </div>
                    </div>
            }
        </>
    );
};

export default TranslationCore;