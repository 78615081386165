import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getAuthToken } from "../../../components/src/NativeWebRouteWrapper/Utils";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  getHandleBack: any;
  handlePostDetails: any;
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  viewAllgroupsData: any
  groupSearchInput: string
  userInfo: any
  recentGroups: any
  showSnackbar: boolean
  severity: any
  message: string
  userId: number | string | null
  topTrendingData: any
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewAllGroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  searchGroupsApiCallId = "";
  viewAllGroupsApiCallId = "";
  joinGroupApiCallId = "";
  getRecentGroupsApiCallId = "";
  topTrendingApiCallId = "";
  token = getAuthToken();
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "",
      viewAllgroupsData: [],
      groupSearchInput: '',
      userInfo: {},
      recentGroups: [],
      showSnackbar: false,
      severity: undefined,
      message: '',
      userId: null,
      topTrendingData: []
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }


  // Customizable Area Start

  viewAllGroupsApiCaller(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.viewAllGroupsApiCallId) {
      if (responseJson?.data) {
        let data = responseJson.data;
        const groupsData = data?.map((item: any) => {
          for (let account of item.attributes.accounts) {
            if (account.account_id.toString() === this.state.userId?.toString()) {
              return { ...item, status: account.status, request_type: account.request_type }
            }
          }
          return item
        })
        this.setState({ viewAllgroupsData: groupsData });
      }
    }
  }
  recentGroupsApiCaller(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getRecentGroupsApiCallId) {
      if (responseJson) {
        if (responseJson.data) {
          this.setState({ recentGroups: responseJson.data })
        }
      }
    }
  }
  joinGroupsApiCaller(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.joinGroupApiCallId) {
      if (responseJson) {
        this.getViewAllGroups()
        this.setState({ showSnackbar: true, severity: "success", message: responseJson.message })
      }
    }
  }
  searchGroupsApiCaller(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.searchGroupsApiCallId) {
      if (responseJson?.data) {
        let data = responseJson.data
        const groupsData = data.map((item: any) => {
          for (let account of item.attributes.accounts) {
            if (account.account_id.toString() === this.state.userId?.toString()) {
              return { ...item, status: account.status, request_type: account.request_type }
            }
          }
          return item
        })
        this.setState({ viewAllgroupsData: groupsData });
      }
    }
  }
  topTrendingApiCaller(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.topTrendingApiCallId) {
      if (responseJson) {
        if (responseJson.data) {
          this.setState({ topTrendingData: responseJson.data })
        }
      }
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.viewAllGroupsApiCaller(apiRequestCallId, responseJson)
      this.recentGroupsApiCaller(apiRequestCallId, responseJson)
      this.joinGroupsApiCaller(apiRequestCallId, responseJson)
      this.searchGroupsApiCaller(apiRequestCallId, responseJson)
      this.topTrendingApiCaller(apiRequestCallId, responseJson)
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getViewAllGroups()
    this.getRecentGroups()
    this.getNowTrending()
    window.scroll(0, 0)
    const userInfo = JSON.parse(localStorage.getItem("userInfo")!)
    this.setState({ userInfo: userInfo, userId: userInfo.id })
  }

  getGroupDetails = (group: any, joinFrom: any) => {
    localStorage.setItem("new_group", JSON.stringify(group))
    window.location.href = "AccountGroups"
  }

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  handleBack = () => {
    if (window.location.pathname === "/AccountGroups") {
      this.props.getHandleBack()
    } else {
      window.location.href = "/CommunityForumWeb"
    }
  }

  onSearchGroupChangeHandler = (event: any) => {
    this.setState({ groupSearchInput: event.target.value }, () => this.getSearchGroups())
  }

  handleOpen = (value: string) => {
    localStorage.removeItem("edit_group")
    window.location.href = value
  }

  getRecentGroups = () => {
    const header = {
      "token": this.token,
      "Content-Type": configJSON.apiContentType
    };
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRecentGroupsApiCallId = requestMessages.messageId;

    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.recentGroupsApiEndPoint}`
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return true;
  }

  getSearchGroups = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchGroupsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchGroupEndPoint}?data[attributes][name]=${this.state.groupSearchInput}`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getViewAllGroups = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };
    const requestMessagess = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.viewAllGroupsApiCallId = requestMessagess.messageId;
    requestMessagess.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getApiEndPoint}?[data][attributes][page]=1&[data][attributes][per_page]=200`
    );
    requestMessagess.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessagess.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessagess.id, requestMessagess);
    return true;
  }

  getNowTrending = () => {
    const group = JSON.parse(localStorage.getItem("new_group")!) || {}

    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };
    
    const requestMessag = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.topTrendingApiCallId = requestMessag.messageId;
    requestMessag.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `group_posts/${group?.id}/group_trending_post?[data][attributes][page]=1&[data][attributes][per_page]=5`
    );

    requestMessag.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessag.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessag.id, requestMessag);
    return true;
  }

  handleJoinGroup = (groupp: any, joinFr: any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.joinGroupApiCallId = requestMessages.messageId;

    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      groupp.attributes && groupp.attributes.group_type === 'public' ? `account_groups/join_public_group/${groupp.id}` : `account_groups/send_request/${groupp.id}`
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return true;
  }

  // Customizable Area End
}
