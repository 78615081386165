import React from "react";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  Input,
  Divider,
} from "@material-ui/core";
import { LikeButton } from "../../../../components/src/LikeButton";
import { MediaGrid, MediaWithGrid, MediaWithoutGrid } from "./media.web";
import SendIcon from '@material-ui/icons/Send';
import { t } from "i18next";

export const Media = ({ length, arr, onMediaModalHandler, playVideo, playAudio }: any) => {
  if (length >= 5) {
    return arr.slice(0, 4).map((item: any, idx: number) => (
      <MediaGrid mediaArr={arr} item={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
    ))
  } else if (length === 1) {
    return arr.map((item: any, idx: number) => (
      <MediaWithoutGrid mediaArr={arr} item={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
    ))
  } else {
    return arr.map((item: any, idx: number) => (
      <MediaWithGrid mediaArr={arr} item={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
    ))
  }
}

export const Posts = ({ posts, mediaArr, commentInput, onCommentChange, onCommentHandler, onLikeHandler, commentBoxVisibilityHandler,
  isCommentEnable, commentIndex, replyIndex, commentActiveIndex, isReplied, repiedIndex, isCommentActive, isReportModal, reportIndex, authorId,
  onRepliedHandler, onReplyHandler, onClickReplyComment, onClickCommentIndex, onReportModalClickHandler, allDownloadHandler, handleConfirmModalOpen,
  onMediaModalHandler, playVideo, playAudio, onShareInputHandler, handlePostDetails, onDeleteHandler, groupId, onEditHandler, isMember, fetchData,
  pageNumber, totalPages, onCommentDeleteHandler
}: any) => {

  const renderPosts = (item: any, index: number) => {
    let commentData = item.attributes?.recent_comments?.data || item.attributes?.all_comments?.data
    return commentData?.slice(0, 2).map((items: any, idx: number) => (
      <>
        <Box className="recentPostBox">
          <Avatar data-test-id="autherImage" src={items.attributes.author_image} />
          <div className="commentDetail">
            <div className="userNameComment">
              <Typography className="userText" data-test-id="autherName">{items.attributes.author_name}</Typography>
              <Typography className="tag">
                -
              </Typography>
              <Typography className="time" data-test-id="createdAt">{items.attributes.created_at}</Typography>
            </div>
            <Typography className="time break" data-test-id="commentText">
              {items.attributes.comment}
            </Typography>
            <div className='replied-box'>
              {items.attributes.reply_counts && <Typography data-test-id="repiled-click-id" className="time reply-btn"
                onClick={() => onRepliedHandler(index, idx)}>{items.attributes.reply_counts} {t("Replied")}</Typography>}
              <Typography data-test-id="reply-btn" className={`time reply-btn ${index} ${idx}`}
                onClick={() => onReplyHandler(index, idx)}>{t("Reply")}</Typography>
              {
                (items.attributes.account_id == authorId || item.attributes.author_id == authorId) &&
                <Typography data-test-id="comment-dlt-btn" className="time reply-btn" 
                onClick={() => onCommentDeleteHandler(items.attributes.id)}>{t('Delete')}</Typography>
              }
            </div>
          </div>
        </Box>

        {renderReplyCommentInput(item, index, items, idx)}

        {
          commentIndex === index && isReplied && repiedIndex === idx &&
          items.attributes.comments.data.length > 0 && items.attributes.comments.data.map((ele: any) => (
            <Box className="recentPostBox reply-loop-pd">
              <Avatar data-test-id="autherImage" src={ele.attributes.author_image} />
              <div className="commentDetail">
                <div className="userNameComment">
                  <Typography className="userText" data-test-id="autherName">{ele.attributes.author_name}</Typography>
                  <Typography className="tag">
                    -
                  </Typography>
                  <Typography className="time" data-test-id="createdAt">{ele.attributes.created_at}</Typography>
                </div>
                <Typography className="time break" data-test-id="commentText">
                  {ele.attributes.comment}
                </Typography>
                {
                  (ele.attributes.account_id == authorId || item.attributes.author_id == authorId) &&
                  <Typography data-test-id="comment-id-dlt" className="time reply-btn break word-break" 
                  onClick={() => onCommentDeleteHandler(ele.attributes.id)}>
                    {t('Delete')}
                  </Typography>
                }
              </div>
            </Box>
          ))
        }
      </>
    ))
  }

  const renderCommentInput = (item: any, index: number) => {
    if ((isCommentEnable && index === commentIndex)) {
      return <Box className="doComment">
        <Input
          className="inputField"
          data-test-id="comment-2"
          id="input-with-icon-adornment"
          placeholder={t("Comment here")}
          value={commentActiveIndex === index ? commentInput : ""}
          onClick={() => onClickCommentIndex(index)}
          onChange={commentActiveIndex === index ? onCommentChange : undefined}
        />
        {commentActiveIndex === index ?
          <SendIcon
            style={{ cursor: "pointer", margin: "0 10px", fill: commentInput.length > 0 && isCommentActive ? '#5497FF' : "#94A3B8" }}
            onClick={() => onCommentHandler(item.attributes.id, null)}
            data-test-id="send-id-2"
          /> :
          <SendIcon data-test-id="send-id-2" style={{ cursor: "pointer", margin: "0 10px", fill: "#94A3B8" }} />
        }
      </Box>
    }
  }

  const renderReplyCommentInput = (item: any, index: number, items: any, idx: number) => {
    if (!isCommentEnable && commentIndex === index && replyIndex === idx) {
      return <Box className="doComment">
        <Input
          className="inputField"
          data-test-id="comment"
          id="input-with-icon-adornment"
          placeholder={t("Comment here")}
          value={commentActiveIndex === index ? commentInput : ""}
          onClick={() => onClickReplyComment(index)}
          onChange={commentActiveIndex === index ? onCommentChange : undefined} />
        {commentActiveIndex === index ?
          <SendIcon
            data-test-id="send-id"
            style={{ cursor: "pointer", margin: "0 10px", fill: commentInput.length > 0 && isCommentActive ? '#5497FF' : "#94A3B8" }}
            onClick={() => onCommentHandler(item.attributes.id, items?.attributes?.id)} /> :
          <SendIcon
            data-test-id="send-id"
            style={{ cursor: "pointer", margin: "0 10px", fill: "#94A3B8" }}
          />}
      </Box>
    }
  }

  return (
    <>
      {posts && posts.length > 0 && posts.map((item: any, index: number) => {
        let mediaArray = [...item.attributes.images, ...item.attributes.videos, ...item.attributes.audios, ...item.attributes.documents]
        let arr = mediaArray
        return (
          <>
            <Box className="recentPostCards" key={item.id}>
              <div className="recentPostBox">
                <Avatar src={item.attributes.author_image} />
                <div className="descriptionBox">
                  <div className="desc">
                    <Typography className="descTitle" onClick={(e) => handlePostDetails(item.id)}>
                      {item.attributes.author_name}
                    </Typography>
                    <div className="tag">{item.attributes.created_at}</div>
                  </div>
                  <Typography data-test-id="test-post-id-two" onClick={(e) => handlePostDetails(item.id)} className="descText">
                    {item.attributes.description.length < 350 ? item.attributes.description
                      : <>{item.attributes.description.substring(0, 350) + "... "}
                        <a data-test-id="more-id" onClick={(e) => handlePostDetails(item.id)} className='seeMoreText'>{t("See more")}</a></>
                    }</Typography>
                  <div className="recentTags">
                    {/* <div className="rtag">
                        <Typography className="rtagText">
                          Social Integration
                        </Typography>
                      </div>  */}
                  </div>
                </div>
                <div data-test-id="report-btn" className={`report-btn ${typeof reportIndex} ${typeof item.id} ${isReportModal}`} onClick={() => onReportModalClickHandler(item.id)} style={{ position: "relative" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                      fill="#64748B" />
                  </svg>
                  {isReportModal && reportIndex == item.id &&
                    <Box test-id="reportbox01" className='report-modal'>
                      <Typography data-test-id="download-id" className='report-text' onClick={() => allDownloadHandler(arr)}>{t("Download")}</Typography>
                      {
                        item.attributes.author_id == authorId && isMember &&
                        <>
                          <Divider style={{ width: "100%" }} />
                          <Typography data-test-id="edit-id" className='report-text' onClick={() => onEditHandler(item)}>{t("Edit")}</Typography>
                          <Divider style={{ width: "100%" }} />
                          <Typography data-test-id="delete-id" className='report-text'
                            onClick={() => onDeleteHandler(groupId, item.attributes.id)}>{t("Delete")}</Typography>
                        </>
                      }

                      {item.attributes.author_id != authorId &&
                      <>
                      <Divider style={{ width: "100%" }} />
                      <Typography data-test-id="report-id" onClick={() => handleConfirmModalOpen(item.id)} className='report-text'>{t("Report")}</Typography>
                      </>
                      }
                    </Box>}
                </div>
              </div>
              <div className='postImageContainer'>
                <Grid container spacing={1} style={{ justifyContent: "center", padding: "0 20px" }}>
                  <Media length={mediaArray.length} arr={mediaArray} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
                </Grid>
              </div>

              <div className="likesCard">
                <div className="likesCommentsContainer">
                  <Typography className="tag" data-test-id="likesCount">
                    {item.attributes.post_likes}{item.attributes.post_likes < 2 ? t(" Like") : t(" Likes")}
                  </Typography>
                  <Typography className="tag">-</Typography>
                  <Typography className="tag" data-test-id="commentsCount">
                    {item.attributes.post_comments}{item.attributes.post_comments < 2 ? t(" Comment") : t(" Comments")}
                  </Typography>
                </div>
                <LikeButton isLike={item.attributes.current_user_liked} data-test-id="likeBtn"
                  onClick={() => onLikeHandler(item.id, item.attributes.current_user_liked, item.attributes.current_user_liked_id, index)}
                  commentOnClick={() => commentBoxVisibilityHandler(index)} shareHandler={() => onShareInputHandler(item.attributes.post_url)} />
                <Divider className="lineDivider" />
              </div>

              {renderPosts(item, index)}

              {renderCommentInput(item, index)}
            </Box>
          </>
        )
      })}
      {posts === undefined && (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Typography>{t("No posts found")}</Typography>
        </div>
      )}
    </>
  )
}