import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { GroupMembersData } from './groupMembersData.web';

export const GroupDetail = ({ groupMembers, groupName, members, userId, isKabab, membersIndex, onMemberViewHandler, handleRemoveMemberApi, handleMakeMember, handleMoreMember, handleMemberReport }: any) => {
    return (
        <>
            <Box className="mediaPost">
                <Box style={{ padding: '20px' }}>
                    <div style={{ display: 'flex', gap: "8px" }}>
                        <img data-test-id="handleOpen" style={{ cursor: "pointer" }} src={require('../../assets/arrow.svg')}
                            onClick={() => onMemberViewHandler("", 0, "")}
                        />
                        <Typography style={styles.groupName} className="">{groupName}</Typography>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "space-between", marginTop: "8px" }}>
                        <div style={{ display: 'flex', marginLeft: "30px", gap: "8px" }}>
                            <img src={require('../../assets/members.png')} />
                            <Typography style={styles.membersCount}>
                                {`${members} ${members > 1 ? "members" : "member"}`}
                            </Typography>
                        </div>
                        <div>
                            <img data-test-id="modalOpen" src={require('../../assets/addGroup.svg')} style={{ cursor: "pointer" }}
                            />
                            <img data-test-id="searchToggle" src={require('../../assets/search.svg')} style={{ marginLeft: "16px", cursor: "pointer" }}
                            />
                        </div>
                    </div>
                    <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
                    <GroupMembersData
                        userId={userId}
                        isKabab={isKabab}
                        membersIndex={membersIndex}
                        groupMembersData={groupMembers}
                        handleMakeMember={handleMakeMember}
                        handleMoreMember={handleMoreMember}
                        handleMemberReport={handleMemberReport}
                        handleRemoveMemberApi={handleRemoveMemberApi}
                    />
                </Box>
            </Box>
        </>
    )
}

const styles = {
    groupName: {
        color: "var(--neutrals-cool-gray-600, #475569)",
        fontFamily: "Comfortaa-Bold",
        fontWeight: 700,
        fontStyle: "normal",
        fontSize: "18px",
        lineHeight: "26px",
    },
    groupContainer: {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        marginTop: '55px',
        width: "100%",
    },
    followlistitem: {
        color: "var(--neutrals-cool-gray-600, #475569)",
        fontSize: "16px",
        fontFamily: "Comfortaa-Bold",
        fontWeight: 700,
        fontStyle: "normal",
        lineHeight: "24px",
    },
    membersCount: {
        color: "var(--neutrals-cool-gray-600, #475569)",
        fontFamily: "Nunito-Regular",
        fontSize: "12px",
        fontStyle: "normal",
        marginTop: "6px",
        fontWeight: 400,
        lineHeight: "18px",
    },
    textSecond: {
        color: "var(--neutrals-cool-gray-600, #475569)",
        fontFamily: "Nunito-Regular",
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    }
}